import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';

import 'primevue/resources/themes/mdc-light-indigo/theme.css'  //theme
import 'primevue/resources/primevue.min.css'  //core css
import 'primeicons/primeicons.css'  //icons
import 'primeflex/primeflex.css'

import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete';
import InputNumber from 'primevue/inputnumber';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import Dialog from 'primevue/dialog';
import ProgressBar from 'primevue/progressbar';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import OverlayPanel from 'primevue/overlaypanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Sidebar from 'primevue/sidebar';
import Menubar from 'primevue/menubar';
import Toolbar from 'primevue/toolbar';
import Row from 'primevue/row';

Vue.config.productionTip = false
Vue.component('InputText', InputText)
Vue.component('Dropdown', Dropdown)
Vue.component('InputMask', InputMask)
Vue.component('Calendar', Calendar)
Vue.component('AutoComplete', AutoComplete)
Vue.component('InputNumber', InputNumber)
Vue.component('Card', Card)
Vue.component('Button', Button)
Vue.component('Divider', Divider)
Vue.component('Dialog', Dialog)
Vue.component('ProgressBar', ProgressBar)
Vue.component('Toast', Toast)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('ColumnGroup', ColumnGroup)
Vue.component('Sidebar', Sidebar)
Vue.component('Menubar', Menubar)
Vue.component('Toolbar', Toolbar)
Vue.use(PrimeVue, {ripple: true});
Vue.use(PrimeVue, {inputStyle: 'filled'})
Vue.use(ToastService)
Vue.component('Row', Row)

//  axios.defaults.baseURL="https://localhost:44339/api/" 
axios.defaults.baseURL="https://api-demo-auditor.contago.com.mx/api/"
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
