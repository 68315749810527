<template>

    <div class="app pt-5">
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Nómina</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <!-- MENU DE NOMINA -->
        <v-card class="justify-center pb-5" flat color="transparent">
            <v-item-group mandatory>
                <v-container>
                    <v-row dense>
                        <!-- <v-hover v-slot="{ hover }" open-delay="10">
                    <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=1"
                        class="mx-auto justify-center mr-5" width="180" height="160" link>
                        <v-card-text class="text-center">
                            <v-icon size="80" color="primary">mdi-file-document-multiple-outline</v-icon>
                            <h3 class="mt-3 mb-1">Personal Registrado</h3>
                        </v-card-text>
                    </v-card>
                </v-hover> -->
                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=1, tipoConsulta = 'General'"
                                    class="rounded-lg mx-auto justify-center mr-5" width="180" height="160" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Datos Generales</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Datos Generales</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=2, tipoConsulta = 'Trabajadores'"
                                    class=" rounded-lg mx-auto justify-center ml-5 mr-5" width="180" height="160" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Nómina Trabajadores</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Nómina Trabajadores
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=3, tipoConsulta = 'Sat'"
                                    class="rounded-lg mx-auto justify-center ml-5 mr-5" width="180" height="160" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Nómina por Conceptos SAT</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Nómina por Conceptos
                                            SAT</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <!-- <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=4, tipoConsulta = 'Sncf'"
                                    class="rounded-lg mx-auto justify-center mr-5 ml-5" width="180" height="160" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Nómina SNCF</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Nómina SNCF</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item> -->

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=5, tipoConsulta = 'detallado'"
                                    class="rounded-lg mx-auto justify-center ml-5" width="180" height="160" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Nómina Detallado</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Nómina Detallado</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>
                    </v-row>
                </v-container>
            </v-item-group>
        </v-card>
        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="6" class="mt-5 pb-10 ma-5 pa-5">
            <v-row class="justify-center">
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaI = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- FECHA FINAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaF = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- BOTON DE AYUDA  -->
                <v-col class="text-right" cols="10" xl="6" md="6" sm="10" xs="10">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                @click="ConsultarNomina()">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <span>Consultar Comprobantes</span>
                    </v-tooltip>

                </v-col>
            </v-row>
        </v-card>



        <!-- VENTANAS DINAMICAS -->
        <v-window v-model="step" class="pt-0 mt-0">
            <!-- DATOS GENERALES -->
            <v-window-item class="pt-0 mt-0" :value="1">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Datos Generales</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>

                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row>

                        <v-col cols="12" xl="12" md="12" sm="12" xs="12" class="text-right">
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                                @click="GeneraExcelGeneral()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </v-col>
                    </v-row>
                    <!-- <v-data-table :headers="headers" :items="itemsNomina" class="elevation-0">
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalOtrosPagos`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalOtrosPagos) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalDeducciones`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalDeducciones) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalPercepciones`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalPercepciones) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.netoPagado`]="{ item }">
                            <span>{{ functionFormatPrice(item.netoPagado) }}</span>
                        </template>

                    </v-data-table>
                    <v-row>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Percepciones</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalPercepciones) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Deducciones</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalDeducciones) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Otros Pagos</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalOtrosPagos) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Neto</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalNeto) }}</span>
                            </v-chip>
                        </v-col>
                    </v-row> -->

                    <!-- PRIMEVUE -->
                    <v-card elevation="6" class="mt-5 pb-10 ma-5 pa-5">
                        <DataTable :value="datosGen" :paginator="true" class="p-datatable-datosGen" showGridlines
                            :rows="10" dataKey="folio" :filters.sync="filters" filterDisplay="menu" :loading="loading"
                            responsiveLayout="scroll" :globalFilterFields="['version', 'tipoNomina', 'totalPercepciones', 'totalDeducciones', 'totalOtrosPagos', 'netoPagado']">
                            <template #header>
                                <div class="flex justify-content-between">
                                    <!-- <Button type="button" icon="pi pi-filter-slash" label="Clear"
                                        class="p-button-outlined" @click="clearFilter()" /> -->
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" />
                                        <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                    </span>
                                </div>
                            </template>
                            <template #empty>
                                No se encontraron datos.
                            </template>
                            <template #loading>
                                Cargando datos.
                            </template>
                            <Column field="version" header="Versión" :sortable="true" :styles="{'min-width':'12rem'} ">
                                <template #body="{data}">
                                    {{data.version}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Buscar por version" />
                                </template>
                            </Column>
                            <Column field="tipoNomina" header="Tipo de Nómina" :sortable="true" :styles="{'min-width':'12rem'}">
                                <template #body="{data}">
                                    {{data.tipoNomina}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Buscar por tipo Nomina" />
                                </template>
                            </Column>
                            <!-- <Column header="Fecha Inicial de Pago" filterField="fechaIP" dataType="date"
                    :styles="{'min-width':'10rem'}">
                    <template #body="{data}">
                        {{formatDate(data.fechaIP)}}
                    </template>
                    <template #filter="{filterModel}">
                        <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                    </template>
                </Column>
                <Column header="Fecha Final de Pago" filterField="fechaFP" dataType="date"
                    :styles="{'min-width':'10rem'}">
                    <template #body="{data}">
                        {{data.fechaFP}}
                    </template>
                    <template #filter="{filterModel}">
                        <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                    </template>
                </Column>
                <Column header="Fecha Pago" filterField="fechaP" dataType="date" :styles="{'min-width':'10rem'}">
                    <template #body="{data}">
                        {{data.fechaP}}
                    </template>
                    <template #filter="{filterModel}">
                        <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
                    </template>
                </Column> -->
                            <Column header="Total de Percepciones" filterField="totalPercepciones" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.totalPercepciones)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>
                            <Column header="Deducciones" filterField="totalDeducciones" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.totalDeducciones)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>
                            <Column header="Otros Pagos" filterField="totalOtrosPagos" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.totalOtrosPagos)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>
                            <Column header="Neto" filterField="netoPagado" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.netoPagado)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>
                            <ColumnGroup type="footer">
                                <Row>
                                    <Column footer="Total:" :colspan="2" :footerStyle="{'text-align':'right'}" />
                                    <Column :footer="totalPer" />
                                    <Column :footer="totalDed" />
                                    <Column :footer="totalOtrosP" />
                                    <Column :footer="totalNto" />

                                    <!-- <Column :footer="thisYearTotal" /> -->
                                </Row>
                            </ColumnGroup>
                        </DataTable>
                    </v-card>
                </v-card>

            </v-window-item>
            <!--NOMINA TRABAJADORES -->
            <v-window-item class="pt-0 mt-0" :value="2">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Nómina Trabajadores</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Neto Pagado</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalNetoPagado) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-right">
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                                @click="GeneraExcelTrabajadores()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </v-col>
                    </v-row>
                    <v-text-field label="Filtrar trabajador" v-model="searchTrabajadores"></v-text-field>
                    <v-data-table :headers="headersTrabajadores" :items="itemsNominaTrabajadores" class="elevation-0"
                        :search="searchTrabajadores">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.netoPagado`]="{ item }">
                            <span>{{ functionFormatPrice(item.netoPagado) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
            <!--NOMINA POR CONCEPTOS SAT -->
            <v-window-item class="pt-0 mt-0" :value="3">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Nómina por Conceptos SAT</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row>
                        <v-col class="text-right">
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                                @click="GeneraExcelConceptos()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headersConceptoSat" :items="itemsConceptoSat" class="elevation-0">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.exento`]="{ item }">
                            <span>{{ functionFormatPrice(item.exento) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.otrosPagos`]="{ item }">
                            <span>{{ functionFormatPrice(item.otrosPagos) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.deduccion`]="{ item }">
                            <span>{{ functionFormatPrice(item.deduccion) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.gravado`]="{ item }">
                            <span>{{ functionFormatPrice(item.gravado) }}</span>
                        </template>
                    </v-data-table>
                    <v-row>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Gravado</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalGravado) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Exento</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalExento) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Dedcucciones</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalSATDeduccion) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Otros Pagos</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalSATOtrosPagos) }}</span>
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-card>
            </v-window-item>
            <!-- NOMINA SNCF -->
            <v-window-item class="pt-0 mt-0" :value="4">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Nómina SNCF</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row>
                        <v-col class="text-right">
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                                @click="GeneraExcelSNCF()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </v-col>
                    </v-row>
                    <v-text-field label="Filtrar trabajador" v-model="searchSncf"></v-text-field>
                    <v-data-table :headers="headersNominaSNCF" :items="itemsNominaSNCF" class="elevation-0"
                        :search="searchSncf">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.netoPagado`]="{ item }">
                            <span>{{ functionFormatPrice(item.netoPagado) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.isr`]="{ item }">
                            <span>{{ functionFormatPrice(item.isr) }}</span>
                        </template>
                    </v-data-table>
                    <v-row>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total ISR</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(totalIsr) }}</span>
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-card>
            </v-window-item>
            <!-- NOMINA DETALLADO -->
            <v-window-item class="pt-0 mt-0" :value="5">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Nómina Detallado</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row>
                        <v-col class="text-right">
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                                @click="GeneraExcelDetallado()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </v-col>
                    </v-row>
                    <v-text-field label="Filtrar trabajador" v-model="searchSncf"></v-text-field>
                    <v-data-table :headers="headersNominaDetallado" :items="itemsNominaDetallado" class="elevation-0"
                        :search="searchDetallado">

                    </v-data-table>

                </v-card>
            </v-window-item>

        </v-window>

    </div>
</template>
<script>
    import axios from 'axios';
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"

    // // PRIMEVUE

    // import CustomerService from './CustomService/CustomerService';
    import { FilterMatchMode, FilterOperator } from 'primevue/api/';

    export default {
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,

        },
        data: () => ({
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',
            step: 1,
            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,

            headers: [
                { text: 'Version', value: 'version', },
                { text: 'Tipo de Nómina', value: 'tipoNomina', },
                { text: 'Fecha Inicial de Pago', value: 'fechaFinal', },
                { text: 'Fecha Final de Pago', value: 'fechaInicial', },
                { text: 'Fecha de Pago', value: 'fechaPago', },
                { text: 'Total Percepciones', value: 'totalPercepciones', align: 'right' },
                { text: 'Deducciones', value: 'totalDeducciones', align: 'right' },
                { text: 'Otros Pagos', value: 'totalOtrosPagos', align: 'right' },
                { text: 'Neto', value: 'netoPagado', align: 'right' },
            ],
            itemsNomina: [],

            //NÓMINA TRABAJADORES
            headersTrabajadores: [
                { text: 'RFC', value: 'rfc' },
                { text: 'CURP', value: 'curp' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Edad', value: 'edad' },
                { text: 'Género', value: 'genero' },
                { text: 'Nóminas Pagadas', value: 'numNominas' },
                { text: 'Peridiocidad de Pago', value: 'periodicidadPago' },
                { text: 'Tipo de Contrato', value: 'tipoContrato' },
                { text: 'Tipo de Jornada', value: 'tipoJornada' },
                { text: 'Tipo de Regimen', value: 'tipoRegimen' },
                { text: 'Neto Pagado', value: 'netoPagado', align: 'right' },
                { text: 'Antigüedad', value: 'antiguedad' },
            ],
            itemsNominaTrabajadores: [],
            searchTrabajadores: '',

            //NÓMINA CONCEPTOS SAT
            headersConceptoSat: [
                { text: 'Clave', value: 'clave' },
                { text: 'Descripcion', value: 'descripcion' },
                { text: 'Gravado', value: 'gravado' },
                { text: 'Exento', value: 'exento', align: 'right' },
                { text: 'Deduccion', value: 'deduccion', align: 'right' },
                { text: 'Otros Pagos', value: 'otrosPagos', align: 'right' },

            ],
            itemsConceptoSat: [],

            //NOMINA SNCF
            headersNominaSNCF: [
                { text: 'Versión', value: 'version' },
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Tipo', value: 'tipoNomina' },
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Fecha Inicial', value: 'fechaInicial' },
                { text: 'Fecha Final', value: 'fechaFinal' },
                { text: 'Fecha Pago', value: 'fechaPago' },
                { text: 'Origen Recurso', value: 'origenRecurso' },
                { text: 'ISR', value: 'isr' },
                // { text: 'Recurso Propio', value: 'recursoPropio' },
                { text: 'Neto Pagado', value: 'netoPagado' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
            ],
            itemsNominaSNCF: [],
            searchSncf: '',
            totalIsr: 0,

            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            totalDeducciones: 0,
            totalOtrosPagos: 0,
            totalPercepciones: 0,
            totalNeto: 0,

            totalNetoPagado: 0,

            totalSATOtrosPagos: 0,
            totalSATDeduccion: 0,
            totalExento: 0,
            totalGravado: 0,

            tipoConsulta: 'General', //Trabajadores, Sat, Sncf

            itemsNominaDetallado: [],
            searchDetallado: '',
            headersNominaDetallado: [
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha Comprobante', value: 'fechaComprobante' },
                { text: 'Fecha Inicial', value: 'fechaInicial' },
                { text: 'Fecha Final', value: 'fechaFinal' },
                { text: 'Fecha Pago', value: 'fechaPago' },
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Origen Recurso', value: 'origenRecurso' },
                { text: 'Puesto', value: 'puesto' },
                { text: 'Departamento', value: 'departamento' },
                { text: 'Num. Empleado', value: 'numEmpleado' },
                { text: 'Tipo de Regimen', value: 'tipoRegimen' },
                { text: 'Tipo de Contrato', value: 'tipoContrato' },
                { text: 'Periocidad de Pago', value: 'periodicidadPago' },
                { text: 'Tipo Nomina', value: 'tipoNomina' },
                { text: 'Total Percepciones', value: 'totalPercepciones' },
                { text: 'Total Deducciones', value: 'totalDeducciones' },
                { text: 'Total Otros Pagos', value: 'totalOtrosPagos' },
                { text: 'Total ISR', value: 'totalIsr' },
                { text: 'Num. Dias Pagados', value: 'numDiasPagados' },
            ],

            // PRIMEVUE
            // customers: null,
            filters: {
                'version': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'tipoNomina': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                // 'fechaIP': {
                //     operator: FilterOperator.AND, constraints: [
                //         { value: '', matchMode: FilterMatchMode.DATE_IS },]
                // },
                // 'fechaFP': {
                //     operator: FilterOperator.AND, constraints: [
                //         { value: '', matchMode: FilterMatchMode.DATE_IS },]
                // },
                // 'fechaP': {
                //     operator: FilterOperator.AND, constraints: [
                //         { value: '', matchMode: FilterMatchMode.DATE_IS },]
                // },
                'totalPercepciones': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalDeducciones': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalOtrosPagos': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'neto': {
                    operator: FilterOperator.AND, constraints: [
                        { neto: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'global': {
                    value: null, matchMode: FilterMatchMode.CONTAINS
                },
            },
            loading: true,

            datosGen: [],

        }),

        mounted() {
            this.loading = false;
        },

        computed: {
            // METODOS COMPUTADOS PARA LAS FECHAS
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },

            // PRIMEVUE
            totalNto() {

                let totalN = 0;
                for (let sumaN of this.datosGen) {
                    totalN += sumaN.netoPagado;
                }

                return this.formatCurrency(totalN);
            },
            totalPer() {
                let totalPer = 0;
                for (let sumaPer of this.datosGen) {
                    totalPer += sumaPer.totalPercepciones;
                }

                return this.formatCurrency(totalPer);
            },
            totalDed() {
                let totalDed = 0;
                for (let sumaPer of this.datosGen) {
                    totalDed += sumaPer.totalDeducciones;
                }

                return this.formatCurrency(totalDed);
            },
            totalOtrosP() {
                let totalOtrosP = 0;
                for (let sumaPer of this.datosGen) {
                    totalOtrosP += sumaPer.totalOtrosPagos;
                }

                return this.formatCurrency(totalOtrosP);
            },

        },
        methods: {
            async ConsultarNomina() {
                switch (this.tipoConsulta) {
                    case 'General':
                        this.ConsultaNominaGeneral();
                        break;
                    case 'Trabajadores':
                        this.ConsultaNominaTrabajadores();
                        break;
                    case 'Sat':
                        this.ConsultaNominaSat();
                        break;
                    case 'Sncf':
                        this.ConsultarNominaSNCF();
                        break;
                    case 'detallado':
                        this.ConsultarNominaDetallado();
                        break;
                }
            },

            async ConsultaNominaGeneral() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                // console.log(this.dateBuscaF)
                // console.log(this.dateBuscaI)

                try {
                    let response = await axios.get('Nomina/GetNominaGeneral/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    this.itemsNomina = response.data.nominaGeneral
                    this.datosGen = response.data.nominaGeneral

                    console.log(this.itemsNomina)
                    // CALCULAR TOTALES NOMINA GENERAL
                    this.totalNeto = this.sum(this.itemsNomina, 'netoPagado')
                    this.totalPercepciones = this.sum(this.itemsNomina, 'totalPercepciones')
                    this.totalOtrosPagos = this.sum(this.itemsNomina, 'totalOtrosPagos')
                    this.totalDeducciones = this.sum(this.itemsNomina, 'totalDeducciones')
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async ConsultaNominaTrabajadores() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                console.log(this.dateBuscaF)
                console.log(this.dateBuscaI)
                this.itemsNominaTrabajadores = [];
                try {
                    let response = await axios.get('Nomina/GetNominaTrabajadores/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    this.itemsNominaTrabajadores = response.data.nominaTrabajadores
                    // CALCULAR TOTALES NOMINA GENERAL
                    this.totalNetoPagado = this.sum(this.itemsNominaTrabajadores, 'netoPagado')

                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async ConsultaNominaSat() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                console.log(this.dateBuscaF)
                console.log(this.dateBuscaI)

                try {
                    let response = await axios.get('Nomina/GetNominaConceptosSat/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    this.itemsConceptoSat = response.data.nominaConceptosSat
                    // CALCULAR TOTALES NOMINA CLAVE SAT
                    this.totalSATOtrosPagos = this.sum(this.itemsConceptoSat, 'otrosPagos')
                    this.totalSATDeduccion = this.sum(this.itemsConceptoSat, 'deduccion')
                    this.totalExento = this.sum(this.itemsConceptoSat, 'exento')
                    this.totalGravado = this.sum(this.itemsConceptoSat, 'gravado')

                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async ConsultarNominaSNCF() {
                this.dialog = true;
                this.dialogMessage = 'Consultando SNCF...'
                try {
                    let response = await axios.get('Nomina/GetNominaSNCF/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    console.log(response.data)
                    this.itemsNominaSNCF = response.data
                    this.totalIsr = this.sum(this.itemsNominaSNCF, 'isr')
                    this.dialog = false;
                } catch (error) {
                    console.log(error)
                    this.dialog = false;
                }
            },
            async ConsultarNominaDetallado() {
                this.dialog = true;
                this.dialogMessage = 'Consultando Detalles...'
                try {
                    let response = await axios.get('Nomina/GetRecibos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    console.log(response.data)
                    this.itemsNominaDetallado = response.data

                    this.dialog = false;
                } catch (error) {
                    console.log(error)
                    this.dialog = false;
                }
            },
            // GENERAR EXCEL
            GeneraExcelConceptos() {
                this.nombreReporte = 'NominaConceptosSAT.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsConceptoSat
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelSNCF() {
                this.nombreReporte = 'NominaSNCF.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsNominaSNCF
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelDetallado() {
                this.nombreReporte = 'NominaDetallado.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsNominaDetallado
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelGeneral() {
                this.nombreReporte = 'NominaGeneral.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsNomina
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelTrabajadores() {
                this.nombreReporte = 'NominaTrabajador.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsNominaTrabajadores
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },

            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },

            //PRIMEVUE
            filterDate(value, filter) {
                if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                    return true;
                }

                if (value === undefined || value === null) {
                    return false;
                }

                return value === this.formatDate(filter);
            },
            formatDate(date) {
                let month = date.getMonth() + 1;
                let day = date.getDate();

                if (month < 10) {
                    month = '0' + month;
                }

                if (day < 10) {
                    day = '0' + day;
                }

                return date.getFullYear() + '-' + month + '-' + day;
            },

            formatCurrency(value) {
                return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
            }

        },

    }


</script>