<template>
  <div>
    <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
        {{ dialogMessage }}
        <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG PARA VER DETALLES -->
    <v-dialog v-model="dialogDetalle" max-width="1600">
      <v-card>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <xlsx-workbook>
            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" />
            <xlsx-download>
            </xlsx-download>
            <xlsx-download :filename="nombreReporte">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="GeneraExcel()">
                    <v-icon>mdi-file-table-outline</v-icon>
                  </v-btn>
                </template>
                <span>Generar Excel</span>
              </v-tooltip>
            </xlsx-download>
          </xlsx-workbook>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <br>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="filtrar" v-model="searchConsulta"></v-text-field>
            </v-col>
          </v-row>
          <v-data-table :headers="headerConsulta" :items="itesmDestalles" :search="searchConsulta" fixed-header height="65vh">
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.descuento`]="{ item }">
              <span>{{ functionFormatPrice(item.descuento) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.total`]="{ item }">
              <span>{{ functionFormatPrice(item.total) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotal`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotal) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotalPesos`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotalPesos) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotalPesosCobrados`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotalPesosCobrados) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG PERDIDAS FISCALES DE EJERCICIOS ANTERIORES -->
    <v-dialog v-model="dialogPFEA" persistent max-width="500px">
      <v-card>
        <v-toolbar>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary darken-1" dark fab small @click="dialogPFEA = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-toolbar-title>Perdidas Fiscales de Ejercicios Anteriores</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="PostPFEA()">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Guardar</span>
          </v-tooltip>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <br>
        <v-card-text>
          <v-row class="justify-center">
            <v-col cols="12">
              <v-text-field label="Importe ENE-FEB" outlined v-model="itemsPerdida[0].importe"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Importe MAR-DIC" outlined v-model="itemsPerdida[2].importe"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG Coeficiente de Utilidad -->
    <v-dialog v-model="dialogCU" persistent max-width="500px">
      <v-card>
        <v-toolbar>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary darken-1" dark fab small @click="dialogCU = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-toolbar-title>Coeficiente de Utilidad</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="PostCU()">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Guardar</span>
          </v-tooltip>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <br>
        <v-card-text>
          <v-row class="justify-center">
            <v-col cols="12">
              <v-text-field label="Importe ENE-FEB" outlined v-model="itemsCoeficiente[0].importe"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Importe MAR-DIC" outlined v-model="itemsCoeficiente[2].importe"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG COMPARATIVA -->
    <v-dialog v-model="dialogComparativa" persistent max-width="500px">
      <v-card>
        <v-toolbar>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary darken-1" dark fab small @click="dialogComparativa = false" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-toolbar-title>Importes Registrados {{ fechaHome.anio }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="PostComparativa()">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Guardar</span>
          </v-tooltip>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <br>
        <v-card-text>
              <v-data-table :headers="headersComparativa" :items="itemsComparativa" :items-per-page="-1" hide-default-footer>
                <!-- PARA EDITAR -->
                <template v-slot:item.importe="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.importe"
                  >
                    {{ props.item.importe }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.importe"
                        label="Editar"
                        single-line
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- REPORTE -->
    <v-card width="1400" elevation="0" class="mx-auto pb-10 ma-5"  color="transparent">
      <v-card elevation="3" class="justify-center">
        <v-card-title class="dark justify-center">
          <!-- GENERA EXCEL GENERAL -->
          <xlsx-workbook>
            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
              :sheet-name="sheet.name" />
            <xlsx-download>
            </xlsx-download>
            <xlsx-download :filename="nombreReporte">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-5 mr-5" color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                    @click="GeneraExcelPagosProvisionales()">
                    <v-icon>mdi-file-table-outline</v-icon>
                  </v-btn>
                </template>
                <span>Generar Excel</span>
              </v-tooltip>
            </xlsx-download>
          </xlsx-workbook>
          <h3 class="justify-center">PAGOS PROVISIONALES DE ISR</h3>
          <br>
          <!-- CONSULTAR REPORTES -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-5 mr-5" color="primary darken-1 " dark fab small v-bind="attrs" v-on="on" @click="GetReporte()">
                <v-icon>mdi-autorenew</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>

        </v-card-title>
        <small>{{ regimenEmpresa }}</small>
        <v-system-bar color="primary" height="6"></v-system-bar>
      </v-card>

      <v-card width="1400" elevation="3" class="mx-auto pt-10 pb-10 ma-5">
        <v-row class="justify-center mb-4">
          <!-- COEFICINETE DE UTILIDAD -->
          <div class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <h2 class="text-center" font-family="Arial">CU</h2>
                <v-chip v-bind="attrs" v-on="on" class="white--text mr-10 ml-10" style="width:250px"
                  @click="openDialogCu()" color="blue darken-4" outlined>
                  <span> ENE-FEB: {{ itemsCoeficiente[0].importe }} | MAR-DIC: {{ itemsCoeficiente[2].importe }}</span>
                </v-chip>
              </template>
              <span>COEFICIENTE DE UTILIDAD</span>
            </v-tooltip>
          </div>
          <!-- PERDIDAS FISCALES -->
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <h2 class="text-center" font-family="Arial">PFEA</h2>
                <v-chip type="number" v-bind="attrs" v-on="on" class="white--text mr-10 ml-10" style="width:300px"
                  @click="openDialogPFEA()" color="blue darken-4" outlined>
                  <v-icon>mdi-currency-usd</v-icon>
                  <span>ENE-FEB: {{ itemsPerdida[0].importe }} | MAR-DIC: {{ itemsPerdida[2].importe }}</span>
                </v-chip>
              </template>
              <span>PERDIDAS FISCALES DE EJERCICIOS ANTERIORES</span>
            </v-tooltip>
          </div>
          <div class="pt-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" class="white--text mt-6 mr-10 ml-10" color="blue darken-4"
                  @click="openDialogComparativa()" outlined>
                  <v-icon>mdi-table-large</v-icon>
                  COMPARATIVA
                </v-chip>
              </template>
              <span>COMPARATIVA</span>
            </v-tooltip>
          </div>
          <div class="text-right">
          </div>
        </v-row>
        <v-data-table  :item-class="row_classes"  :headers="headerIG" :items="itemsPPIsr" class="elevation-0" :items-per-page="-1"
          hide-default-footer>
          <template v-slot:[`item.actions`]="{ item }">
            <template v-if="item.mes != 'TOTAL:' && item.mes != ''">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4" @click="detalles(item)">
                    mdi-file-document
                  </v-icon>
                </template>
                <span>Comprobaciones</span>
              </v-tooltip>
            </template>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.ingresoMes`]="{ item }">
            <span>{{ functionFormatPrice(item.ingresoMes) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.ingresoAcumulado`]="{ item }">
            <span>{{ functionFormatPrice(item.ingresoAcumulado) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.baseImpuestoPpIsr`]="{ item }">
            <span>{{ functionFormatPrice(item.baseImpuestoPpIsr) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.basePpIsr`]="{ item }">
            <span>{{ functionFormatPrice(item.basePpIsr) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.ppIsr`]="{ item }">
            <span>{{ functionFormatPrice(item.ppIsr) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.impuestoCargo`]="{ item }">
            <span>{{ functionFormatPrice(item.impuestoCargo) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.impuestoRegistrado`]="{ item }">
            <span>{{ functionFormatPrice(item.impuestoRegistrado) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.comparativa`]="{ item }">
            <span>{{ functionFormatPrice(item.comparativa) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.ingresos`]="{ item }">
            <span>{{ functionFormatPrice(item.ingresos) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.gastos`]="{ item }">
            <span>{{ functionFormatPrice(item.gastos) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.nomina`]="{ item }">
            <span>{{ functionFormatPrice(item.nomina) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.baseGravable`]="{ item }">
            <span>{{ functionFormatPrice(item.baseGravable) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.gastosAcumulados`]="{ item }">
            <span>{{ functionFormatPrice(item.gastosAcumulados) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.ingresosAcumulados`]="{ item }">
            <span>{{ functionFormatPrice(item.ingresosAcumulados) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.excedente`]="{ item }">
            <span>{{ functionFormatPrice(item.excedente) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.limiteInferior`]="{ item }">
            <span>{{ functionFormatPrice(item.limiteInferior) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.baseGravable`]="{ item }">
            <span>{{ functionFormatPrice(item.baseGravable) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.tasa`]="{ item }">
            <span>{{ functionFormatPrice(item.tasa) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.impuestoMarginal`]="{ item }">
            <span>{{ functionFormatPrice(item.impuestoMarginal) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.cuotaFija`]="{ item }">
            <span>{{ functionFormatPrice(item.cuotaFija) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.isrCausado`]="{ item }">
            <span>{{ functionFormatPrice(item.isrCausado) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.pagosAnterioresIsr`]="{ item }">
            <span>{{ functionFormatPrice(item.pagosAnterioresIsr) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.isrCargo`]="{ item }">
            <span>{{ functionFormatPrice(item.isrCargo) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.pfea`]="{ item }">
            <span>{{ functionFormatPrice(item.pfea) }}</span>
          </template>
          <!-- FORMATO CON DECIMALES -->
          <template v-slot:[`item.anticipoCuentas`]="{ item }">
            <span>{{ functionFormatPrice(item.anticipoCuentas) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </div>
</template>
<script>
  import HelloWorld from '@/components/HelloWorld.vue'
  import axios from 'axios'
  import VuetifyMoney from "vuetify-money";
  import Vue from 'vue'
  import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
  // import XLSX from 'xlsx';

  Vue.use(VuetifyMoney);

  export default {
    name: 'Home',
    components: {
      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
      HelloWorld,
    },
    data: () => ({
      //DATATABLE
      itemsPPIsr: [],

      //LISTA PARA EXPORTAR
      listaExcel: [],
      sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
      nombreReporte: '',
      // SNACKBAR
      snackbar: false,
      snackbarMessage: '',
      //DIALOG
      dialog: false,
      dialogMessage: '',

      // VENTANA 2
      dialogPFEA: false,
      dialogCU: false,

      final:[],
      headerIG: [],
      itemsIG: [],
      headerConsulta: [],
      itesmDestalles: [],
      searchConsulta: '',
      itemsComparativa:[],

      // DIALOGOS
      dialogDetalle: false,
      titulo: '',
      dialogMessage: '',
      dialog: false,

      //COMPARATIVA
      dialogComparativa: false,
      headersComparativa: [
        { text: 'Mes', value: 'mes' },
        { text: 'Importe', value: 'importe' },
      ],
      itemsComparativaBd: [],
      itemsComparativa: [
        { anio: '', mes: 'enero', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'febrero', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'marzo', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'abril', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'mayo', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'junio', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'julio', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'agosto', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'septiembre', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'octubre', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'noviembre', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'diciembre', tipo: 'PPISR', importe: 0, cargo: 0, abono: 0 },
      ],
    
      //COEFICINETE DE UTILIDAD
      itemsCoeficienteBd:[],
      itemsCoeficiente: [
        { anio: '', mes: 'enero', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'febrero', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'marzo', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'abril', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'mayo', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'junio', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'julio', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'agosto', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'septiembre', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'octubre', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'noviembre', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'diciembre', tipo: 'CU', importe: 0, cargo: 0, abono: 0 },
      ],
      itemsPerdidaBd: [],
      itemsPerdida: [
        { anio: '', mes: 'enero', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'febrero', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'marzo', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'abril', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'mayo', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'junio', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'julio', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'agosto', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'septiembre', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'octubre', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'noviembre', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
        { anio: '', mes: 'diciembre', tipo: 'PERDIDA', importe: 0, cargo: 0, abono: 0 },
      ],
      
      //ANTICIPO A CUENTA DE SOCIOS
      itemsAnticipoCuentaSocios:[],
      regimenEmpresa: '',
    
    }),
    computed: {
      itemConfig() {
        return this.$store.state.configuracion;
      },
      
      fechaHome(){
        return this.$store.state.fechaHome;
      },
      datosEmpresa() {
        return this.$store.state.usuario;
      },
    
    },

    methods: {
      async GetReporte(){
        this.dialog = true;
        this.dialogMessage = 'Consultando régimen...';
        let configRegimen = await this.GetConfiguracionRegimen();
        let comparativa = await this.GetComparativa();
        this.regimenEmpresa = configRegimen.tipo
        //EVALUAMOS LOS CASOS
        switch(configRegimen.tipo){
          case 'RESICO_MORAL':
            this.GetMoralesResico()
          break;
          case 'GENERAL_MORAL':
            this.GetMoralGeneral();
          break;
          case 'FISICA_ACTIVIDAD_EMPRESARIAL':
            this.GetFisicasActividadEmpresarial();
          break;
          case 'GENERAL_MORAL_SC':
            this.GetMoralSC();
          break;
        }

      },
      
      async GetMoralGeneral(){
        let headers = [
          { text: 'Mes', value: 'mes' },
          { text: 'Ingreso por Mes', value: 'ingresoMes', align: 'right' },
          { text: 'Ingreso Acumulado', value: 'ingresoAcumulado', sortable: false, align: 'right' },
          { text: 'Utilidad Fiscal', value: 'baseImpuestoPpIsr', sortable: false, align: 'right' },
          { text: 'Base Pago Provisional para ISR', value: 'basePpIsr', sortable: false, align: 'right' },
          { text: 'Pago Provisional por ISR', value: 'ppIsr', sortable: false, align: 'right' },
          { text: 'Impuesto Cargo', value: 'impuestoCargo', sortable: false, align: 'right' },
          { text: 'Impuesto Registrado', value: 'impuestoRegistrado', color: '', sortable: false, align: 'right' },
          { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
          { text: 'Actions', value: 'actions', sortable: false },
        ];
        let headersDetalles = [
          { text: 'Version', value: 'version' },
          { text: 'Serie', value: 'serie' },
          { text: 'Folio', value: 'folio' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Descuento', value: 'descuento' },
          { text: 'Total', value: 'total' },
          { text: 'SubTotal', value: 'subTotal' },
          { text: 'SubTotalPesos', value: 'subTotalPesos' },
          { text: 'Tipo', value: 'tipoComprobante' },
          { text: 'Metodo Pago', value: 'metodoPago' },
          { text: 'RFC', value: 'rfc' },
          { text: 'Nombre Receptor', value: 'nombre' },
          { text: 'FolioFiscal', value: 'folioFiscal' },
          { text: 'Tipo', value: 'tipo' },
        ]
        this.headerIG = headers;
        this.headerConsulta = headersDetalles;
        let comparativa = await this.GetComparativa();
        this.dialog = true;
        this.dialogMessage = 'Consultando espere...';
        let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
        try{
          let response = await axios.get('Ingresos/GetPPIsr/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha)
          this.final =  response.data;
          this.recalcula();
          this.dialog = false;
        }catch(error){
          console.log(error)
          this.dialog= false
        }
      },

      async GetMoralSC(){
        let headers = [
          { text: 'Mes', value: 'mes' },
          { text: 'Ingreso por Mes', value: 'ingresoMes', align: 'right' },
          { text: 'Anticipo a Cuentas', value: 'anticipoCuentas', align: 'right' },
          { text: 'Ingreso Acumulado', value: 'ingresoAcumulado', sortable: false, align: 'right' },
          { text: 'Utilidad Fiscal', value: 'baseImpuestoPpIsr', sortable: false, align: 'right' },
          { text: 'Base Pago Provisional para ISR', value: 'basePpIsr', sortable: false, align: 'right' },
          { text: 'Pago Provisional por ISR', value: 'ppIsr', sortable: false, align: 'right' },
          { text: 'Impuesto Cargo', value: 'impuestoCargo', sortable: false, align: 'right' },
          { text: 'Impuesto Registrado', value: 'impuestoRegistrado', color: '', sortable: false, align: 'right' },
          { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
          { text: 'Actions', value: 'actions', sortable: false },
        ];
        let headersDetalles = [
          { text: 'Version', value: 'version' },
          { text: 'Serie', value: 'serie' },
          { text: 'Folio', value: 'folio' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Descuento', value: 'descuento' },
          { text: 'Total', value: 'total' },
          { text: 'SubTotal', value: 'subTotal' },
          { text: 'SubTotalPesos', value: 'subTotalPesos' },
          { text: 'Tipo', value: 'tipoComprobante' },
          { text: 'Metodo Pago', value: 'metodoPago' },
          { text: 'RFC', value: 'rfc' },
          { text: 'Nombre Receptor', value: 'nombre' },
          { text: 'FolioFiscal', value: 'folioFiscal' },
          { text: 'Tipo', value: 'tipo' },
        ]
        this.headerIG = headers;
        this.headerConsulta = headersDetalles;
        let comparativa = await this.GetComparativa();
        this.dialog = true;
        this.dialogMessage = 'Consultando espere...';
        let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
        try{
          let response = await axios.get('Ingresos/GetPPIsr/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha)
          this.final =  response.data;

          let resposneAnticipos = await axios.get('Nomina/GetAnticipoCuentasSocios/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha)
          this.itemsAnticipoCuentaSocios = resposneAnticipos.data;
          // console.log(this.itemsAnticipoCuentaSocios)

          this.recalcula();
          this.dialog = false;
        }catch(error){
          console.log(error)
          this.dialog= false
        }
      },
      
      async GetMoralesResico(){
        await this.GetComparativa();
        this.dialog = true;
        this.dialogMessage = 'Consultando espere...';
        let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
        let headers = [
          { text: 'Mes', value: 'mes' },
          { text: 'Ingresos', value: 'ingresos', align: 'right' },
          { text: 'Ingresos Acumulados', value: 'ingresosAcumulados', align: 'right' },
          { text: 'Gastos', value: 'gastos', sortable: false, align: 'right' },
          { text: 'Gastos Acumulados', value: 'gastosAcumulados', sortable: false, align: 'right' },
          { text: 'PFEA', value: 'pfea', sortable: false, align: 'right' },

          { text: 'Base Pago Provisional para ISR', value: 'basePpIsr', sortable: false, align: 'right' },
          { text: 'Pago Provisional por ISR', value: 'ppIsr', sortable: false, align: 'right' },
          { text: 'Impuesto Cargo', value: 'impuestoCargo', sortable: false, align: 'right' },
          { text: 'Impuesto Registrado', value: 'impuestoRegistrado', color: '', sortable: false, align: 'right' },
          { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
          { text: 'Actions', value: 'actions', sortable: false },
        ];
        let headersDetalles = [
          { text: 'Version', value: 'version' },
          { text: 'Serie', value: 'serie' },
          { text: 'Folio', value: 'folio' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Descuento', value: 'descuento', align: 'right' },
          { text: 'Total', value: 'total', align: 'right' },
          { text: 'SubTotal', value: 'subTotal', align: 'right' },
          { text: 'SubTotalPesos', value: 'subTotalPesosCobrados', align: 'right' },
          { text: 'Tipo', value: 'tipoComprobante' },
          { text: 'Metodo Pago', value: 'metodoPago' },
          { text: 'RFC', value: 'rfc' },
          { text: 'Nombre Receptor', value: 'nombre' },
          { text: 'FolioFiscal', value: 'folioFiscal' },
          { text: 'Tipo', value: 'tipo' },
        ]
        this.headerIG = headers;
        this.headerConsulta = headersDetalles;

        try{
          let response = await axios.get('Ingresos/GetResicoMoral/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha)
          // console.log(response.data);
          this.itemsPPIsr =  response.data;

          let ingresoA = 0;
          let gastoA = 0;
          //HACEMOS EL CALCULO
          for(var pp of this.itemsPPIsr){
            ingresoA = ingresoA + pp.ingresos;
            gastoA = gastoA + pp.gastos
            pp.ingresosAcumulados = ingresoA
            pp.gastosAcumulados = gastoA

            const indexPfea = this.itemsPerdida.findIndex(x => x.mes === pp.mes);
            let pfea = 0
            if(indexPfea != -1){
              pfea = this.itemsPerdida[indexPfea].importe
            }
            pp.pfea = pfea;

            pp.basePpIsr = pp.ingresosAcumulados - pp.gastosAcumulados - pp.pfea
            pp.ppIsr = 0;
            if(pp.basePpIsr > 0){
              pp.ppIsr = pp.basePpIsr
            }
            pp.impuestoCargo = pp.ppIsr *.3

            //COMPARATIVA
            const indexComparativa = this.itemsComparativaBd.findIndex(x => x.mes === pp.mes);
            let registrado = 0;
            if(indexComparativa != -1){
              registrado = this.itemsComparativaBd[indexComparativa].importe
            }
            pp.impuestoRegistrado = registrado
            pp.comparativa = pp.impuestoCargo - pp.impuestoRegistrado
          }
          this.dialog = false;
        }catch(error){
          console.log(error)
          this.dialog = false;
        }
      },
      
      async GetFisicasActividadEmpresarial(){
        let tabla = await this.GetTablaIsrMensualPFAE();
        let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
        let headers = [
          { text: 'Mes', value: 'mes' },
          { text: 'Ingresos', value: 'ingresos', align: 'right' },
          { text: 'Ingresos Acumulados', value: 'ingresosAcumulados', sortable: false, align: 'right' },
          { text: 'Gastos', value: 'gastos', sortable: false, align: 'right' },
          { text: 'Gastos Acumulados', value: 'gastosAcumulados', sortable: false, align: 'right' },
          { text: 'Base Gravable', value: 'baseGravable', sortable: false, align: 'right' },
          { text: 'Límite Inferior', value: 'limiteInferior', sortable: false, align: 'right' },
          { text: 'Excedente', value: 'excedente', sortable: false, align: 'right' },
          { text: 'Tasa', value: 'tasa', sortable: false, align: 'right' },
          { text: 'Impuesto Marginal', value: 'impuestoMarginal', sortable: false, align: 'right' },
          { text: 'Cuota Fija', value: 'cuotaFija', sortable: false, align: 'right' },
          { text: 'ISR Causado', value: 'isrCausado', sortable: false, align: 'right' },
          { text: 'Pagos anteriores de ISR', value: 'pagosAnterioresIsr', sortable: false, align: 'right' },
          { text: 'ISR a Cargo', value: 'isrCargo', sortable: false, align: 'right' },
        ];
        let headersDetalles = [
          { text: 'Version', value: 'version' },
          { text: 'Serie', value: 'serie' },
          { text: 'Folio', value: 'folio' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Descuento', value: 'descuento', align: 'right' },
          { text: 'Total', value: 'total', align: 'right' },
          { text: 'SubTotal', value: 'subTotal', align: 'right' },
          { text: 'SubTotalPesos', value: 'subTotalPesosCobrados', align: 'right' },
          { text: 'Tipo', value: 'tipoComprobante' },
          { text: 'Metodo Pago', value: 'metodoPago' },
          { text: 'RFC', value: 'rfc' },
          { text: 'Nombre Receptor', value: 'nombre' },
          { text: 'FolioFiscal', value: 'folioFiscal' },
          { text: 'Tipo', value: 'tipo' },
        ]
        this.headerIG = headers;
        this.headerConsulta = headersDetalles;
        // console.log(tabla);
        try{
          let response = await axios.get('Ingresos/GetPersonaFisicaAE/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha)
          // console.log(response.data);
          this.itemsPPIsr =  response.data;

          let ingresosAcumulados = 0;
          let gastosAcumulados = 0;
          let pagoIsrAcumulado = 0;
          //RECORREMOS PARA HACER EL CALCULO
          for(let pp of this.itemsPPIsr){
            pp.ingresosAcumulados = ingresosAcumulados + pp.ingresos;
            pp.gastosAcumulados = gastosAcumulados + pp.gastos;

            pp.baseGravable = pp.ingresosAcumulados - pp.gastosAcumulados;
            ingresosAcumulados += pp.ingresos
            gastosAcumulados += pp.gastos
            //BUSCAMOS EL OBJETO EN LISTA DE ISR
            let objIsr = tabla.find(x => x.mes === pp.mes && pp.baseGravable >= x.limiteInferior && pp.baseGravable <= x.limiteSuperior )
            console.log(objIsr)
            if(!objIsr){
              pp.limiteInferior = 0;
              pp.excedente = 0;
              pp.tasa = 0;
              pp.impuestoMarginal;
              pp.cuotaFija = 0;
              pp.isrCausado = 0;
            }else{
              pp.limiteInferior = objIsr.limiteInferior
              pp.excedente = pp.baseGravable - pp.limiteInferior
              pp.tasa = objIsr.porcentaje;
              pp.impuestoMarginal = pp.excedente * (pp.tasa /100)
              pp.cuotaFija = objIsr.cuotaFija
              pp.isrCausado = pp.impuestoMarginal + pp.cuotaFija
            }
            
            pp.pagosAnterioresIsr = pagoIsrAcumulado;
            pp.isrCargo = pp.isrCausado - pp.pagosAnterioresIsr;
            pagoIsrAcumulado += pp.isrCausado
          }

        }catch(error){
          console.log(error)
        }

        this.dialog = false;
      },

      async GetConfiguracionRegimen(){
        try{
          let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
          let response = await axios.get('Configuracion/GetConfigRegimenAsync/' + this.datosEmpresa.empresaBase + '/' + this.fechaHome.anio)
          // console.log(response.data)
          return response.data;
        }catch(error){
          console.log(error)
        }
      },
      
      async GetTablaIsrMensualPFAE(){
        this.dialogMessage = 'Consultando tabla...';
        try{
          let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
          let response = await axios.get('Configuracion/GetTablaIsrMensualPFAE/' + this.datosEmpresa.empresaBase + '/' + this.fechaHome.anio)
          // console.log(response.data)
          return response.data;
        }catch(error){
          console.log(error)
        }
      },

      async GetComparativa(){
        try{
          let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/PPISR/' + this.fechaHome.anio)
          this.itemsComparativaBd = response.data

          for(var item of this.itemsComparativa){
            var indice = this.itemsComparativaBd.findIndex(x => x.mes === item.mes);
            let registrado = 0;
            if(indice != -1){
              registrado = this.itemsComparativaBd[indice].importe
            }
            item.anio = this.fechaHome.anio
            item.importe = registrado;
          }
        }catch(error){
          console.log(error)
        }

        try{
          let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/CU/' + this.fechaHome.anio)
          this.itemsCoeficienteBd = response.data

          for(var item of this.itemsCoeficiente){
            var indice = this.itemsCoeficienteBd.findIndex(x => x.mes === item.mes);
            let registrado = 0;
            if(indice != -1){
              registrado = this.itemsCoeficienteBd[indice].importe
            }
            item.anio = this.fechaHome.anio
            item.importe = registrado;
          }
        }catch(error){
          console.log(error)
        }

        try{
          let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/PERDIDA/' + this.fechaHome.anio)
          this.itemsPerdidaBd = response.data

          for(var item of this.itemsPerdida){
            var indice = this.itemsPerdidaBd.findIndex(x => x.mes === item.mes);
            let registrado = 0;
            if(indice != -1){
              registrado = this.itemsPerdidaBd[indice].importe
            }
            item.anio = this.fechaHome.anio
            item.importe = registrado;
          }
        }catch(error){
          console.log(error)
        }
      },

      sum(array, key) {
        return array.reduce((a, b) => a + (b[key] || 0), 0);
      },

      async openDialogComparativa(){
        let guardado = await this.GetComparativa();
        for(var item of this.itemsComparativa){
          var indice = this.itemsComparativaBd.findIndex(x => x.mes === item.mes);
          let registrado = 0;
          if(indice != -1){
            registrado = this.itemsComparativaBd[indice].importe
          }
          item.anio = this.fechaHome.anio
          item.importe = registrado;
        }
        this.dialogComparativa = true;
      },

      async openDialogCu(){
        let guardado = await this.GetComparativa();
        for(var item of this.itemsCoeficiente){
          var indice = this.itemsCoeficienteBd.findIndex(x => x.mes === item.mes);
          let registrado = 0;
          if(indice != -1){
            registrado = this.itemsCoeficienteBd[indice].importe
          }
          item.anio = this.fechaHome.anio
          item.importe = registrado;
        }
        this.dialogCU = true;
      },

      async openDialogPFEA(){
        let guardado = await this.GetComparativa();
        for(var item of this.itemsPerdida){
          var indice = this.itemsPerdidaBd.findIndex(x => x.mes === item.mes);
          let registrado = 0;
          if(indice != -1){
            registrado = this.itemsPerdidaBd[indice].importe
          }
          item.anio = this.fechaHome.anio
          item.importe = registrado;
        }
        this.dialogPFEA = true;
      },

      detalles(item) {
        this.itesmDestalles = item.detalles
        this.dialogDetalle = true
        this.titulo = 'Pagos Provisionales ISR (' + item.mes + ')'
        this.$store.state.listaExportarExcel = item.detalles
        this.$store.state.datosExcel.mes = item.mes
        this.$store.state.datosExcel.mes = item.mes

      },
      //FUNCIÓN PARA DAR FORMATO DECIMAL
      functionFormatPrice(value) {
        if (value != '-') {
          let val = (value / 1).toFixed(2)
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          return '';
        }
      },
      GeneraExcel() {
        this.nombreReporte = 'PagosProvisionalesISR_' + this.$store.state.datosExcel.mes + '_' + this.$store.state.datosExcel.año + '.xlsx'
        let listaExcel = [];
        listaExcel = this.$store.state.listaExportarExcel
        this.listaExcel = listaExcel;
        this.collection = listaExcel;
      },
      GeneraExcelPagosProvisionales() {
        this.nombreReporte = 'PagosProvisionalesISR_' + this.fechaHome.anio + '.xlsx'
        let listaExcel = [];
        listaExcel = this.itemsPPIsr
        this.listaExcel = listaExcel;
        this.collection = listaExcel;
      },
      row_classes(item) {
        if (item.mes == "TOTAL:") {
          return "primary lighten-5"; 
        }
      },
      
      async PostComparativa(){
        try{
          let response = await axios.post('Comparativa/PostComparativa/' + this.datosEmpresa.empresaBase , this.itemsComparativa)
          this.dialogComparativa = false
          this.itemsComparativaBd = response.data
        }catch(error){
          console.log(error)
        }
      },

      async PostCU(){
        for(var a of this.itemsCoeficiente){
          if(a.mes === 'enero' || a.mes === 'febrero'){
            a.importe = this.itemsCoeficiente[0].importe
          }else{
            a.importe = this.itemsCoeficiente[2].importe
          }
        }
        try{
          let response = await axios.post('Comparativa/PostComparativa/' + this.datosEmpresa.empresaBase , this.itemsCoeficiente)
          this.dialogCU = false
          this.itemsCoeficienteBd = response.data
        }catch(error){
          console.log(error)
        }
      },
    
      async PostPFEA(){
        for(var a of this.itemsPerdida){
          if(a.mes === 'enero' || a.mes === 'febrero'){
            a.importe = this.itemsPerdida[0].importe
          }else{
            a.importe = this.itemsPerdida[2].importe
          }
        }
        try{
          let response = await axios.post('Comparativa/PostComparativa/' + this.datosEmpresa.empresaBase , this.itemsPerdida)
          this.dialogPFEA = false
          this.itemsPerdidaBd = response.data
        }catch(error){
          console.log(error)
        }
      },
    
      recalcula(){
        //CALCULOS
        this.itemsPPIsr = [];
        let acumulado = 0
        let ppIsrAnterior = 0;

        for (let resultado of this.final) {
          //ANTICIPO A CUENTAS
          const indexAnticipo = this.itemsAnticipoCuentaSocios.findIndex(x => x.mes === resultado.mes);
          var anticipo = 0;
          if(indexAnticipo != -1){
            anticipo = this.itemsAnticipoCuentaSocios[indexAnticipo].importe
          }
          acumulado += resultado.importe - anticipo

          // anticipoCuentas = anticipoCuentas + resultado.data.anticipoCuentas;
          //OBTENEMOS EL COEFICIENTE POR MES
          const indexCoeficiente = this.itemsCoeficiente.findIndex(x => x.mes === resultado.mes);
          let coeficiente = 0;
          if(indexCoeficiente != -1){
            coeficiente = this.itemsCoeficiente[indexCoeficiente].importe
          }

          const indexPfea = this.itemsPerdida.findIndex(x => x.mes === resultado.mes);
          let pfea = 0
          if(indexPfea != -1){
            pfea = this.itemsPerdida[indexPfea].importe
          }

          let baseImpuestoPpIsr = acumulado * coeficiente
          let basePpIsr = (acumulado * coeficiente) - pfea
          if (basePpIsr < 0) {
            basePpIsr = 0;
          }
          let ppIsr = (basePpIsr * 30) / 100;

          let impuestoCargo = ppIsr - ppIsrAnterior
          if (impuestoCargo < 0) {
            impuestoCargo = 0;
          }
          ppIsrAnterior = ppIsr;

          const indexComparativa = this.itemsComparativaBd.findIndex(x => x.mes === resultado.mes);
          let registrado = 0;
          if(indexComparativa != -1){
            registrado = this.itemsComparativaBd[indexComparativa].importe
          }
          var concepto = {
            mes: resultado.mes,
            detalles: resultado.detalles,
            ingresoMes: resultado.importe,
            anticipoCuentas: anticipo,
            ingresoAcumulado: acumulado,
            baseImpuestoPpIsr: baseImpuestoPpIsr,
            basePpIsr: basePpIsr,
            ppIsr: ppIsr,
            impuestoCargo: impuestoCargo,
            impuestoRegistrado: registrado,
            comparativa : impuestoCargo - registrado,
          };
          this.itemsPPIsr.push(concepto);
          concepto = {};
        }

        // CALCULAR SUMATORIA PAGOS PROVISIONALES
        let pagosProvi = {
          mes: 'TOTAL:',
          ingresoMes: this.sum(this.itemsPPIsr, 'ingresoMes'),
          anticipoCuentas: this.sum(this.itemsPPIsr, 'anticipoCuentas'),
          ingresoAcumulado: this.sum(this.itemsPPIsr, 'ingresoAcumulado'),
          baseImpuestoPpIsr: this.sum(this.itemsPPIsr, 'baseImpuestoPpIsr'),
          basePpIsr: this.sum(this.itemsPPIsr, 'basePpIsr'),
          ppIsr: this.sum(this.itemsPPIsr, 'ppIsr'),
          impuestoCargo: this.sum(this.itemsPPIsr, 'impuestoCargo'),
          impuestoRegistrado: this.sum(this.itemsPPIsr, 'impuestoRegistrado'),
          comparativa: this.sum(this.itemsPPIsr, 'comparativa'),
        }

        this.itemsPPIsr.push(pagosProvi);
        this.dialog = false;
      },
    }
  };
</script>