<template>
    <div class="app pt-5">
        <!-- DIALOG PARA INFORMACION -->
        <v-dialog v-model="dialogInformacion" width="800">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>INFORMACIÓN</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-alert border="right" colored-border type="info">
                        <h3 class="mb-3">¿Cómo generar tu búsqueda?</h3>
                        <p>Para generar tu búsqueda, puedes hacerlo de dos formas, escribiendo la Clave SAT o bien su
                            descripcion y solo debes dar click en la lupa.</p>
                    </v-alert>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- DIALOGO DE INFORMACION -->
        <v-card elevation="4" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Conceptos</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <!-- MENU DE COMPONENTES -->
        <v-card class="justify-center pb-5" flat color="transparent">
            <v-item-group mandatory>
                <v-container>
                    <v-row dense>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }"
                                    :color="active ? 'primary' : ''" @click="step=1"
                                    class="mx-auto justify-center mr-5 rounded-lg" width="160" height="140" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Clave SAT</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Clave SAT</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=2"
                                    :color="active ? 'primary' : ''" class="mx-auto justify-center ml-5 rounded-lg"
                                    width="160" height="140" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-2 mb-1">Descripcion</h3>
                                        <h3 v-if="active" class="mt-2 mb-1" style="color: white;">Descripcion</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                    </v-row>
                </v-container>
            </v-item-group>
        </v-card>
        <v-card elevation="4" class="mt-5 pt-10 pb-10 pa-5 ma-5">
            <v-row class="justify-center">
                <!-- SELECCION DE DATOS -->
                <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                    <v-select v-model="selectTipo" :items="itemsSelect" label="Selecciona un tipo"></v-select>
                </v-col>
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaI = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- FECHA FINAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaF = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- BOTON DE AYUDA  -->
                <v-col class="text-right" cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary accent-4" dark fab @click="dialogInformacion = true" v-bind="attrs"
                                v-on="on">
                                <v-icon>mdi-help-circle-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Ayuda</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card>
        <!-- VENTANAS DINAMICAS -->
        <v-window v-model="step" class="pt-0 mt-0">
            <!-- CLAVE SAT -->
            <v-window-item class="pt-0 mt-0" :value="1">
                <v-card elevation="4" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Clave SAT</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="3" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row>
                        <v-col cols="12" xl="4" md="4">
                            <v-text-field v-model="busquedaCatalogoSat.clave" label="Clave SAT"
                                append-outer-icon="mdi-magnify" @keydown.enter="GetCatalogoSatClave()"
                                @click:append-outer="GetCatalogoSatClave()">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xl="4" md="4" sm="4" xs="12">
                            <v-text-field v-model="busquedaCatalogoSat.descripcion" label="Descripción"
                                append-outer-icon="mdi-magnify" @keydown.enter="GetCatalogoSatDescripcion()"
                                @click:append-outer="GetCatalogoSatDescripcion()"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerSat" :items="itemsSat" :search="searchClave" class="elevation-0">
                        <!-- BOTON DE CONSULTA POR CLAVE DEL SAT -->
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dense class="mr-2" v-bind="attrs" v-on="on" dark
                                        @click="ConsultaClaveSat(item)">mdi-magnify</v-icon>
                                </template>
                                <span>Buscar por clave del SAT</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
            <!-- DESCRIPCION -->
            <v-window-item class="pt-0 mt-0" :value="2">
                <v-card elevation="4" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Descripción</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="4" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row>
                        <v-col cols="12" xl="4" md="4">
                            <v-text-field v-model="descripcion" label="Descripcion" append-outer-icon="mdi-magnify"
                                @keydown.enter="ConsultarDescripcion()" @click:append-outer="ConsultarDescripcion()">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-window-item>
        </v-window>
        <!-- LISTA DE CONCEPTOS CONSULTADOS -->
        <v-card elevation="4" class="justify-center  ma-5 ">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Lista de Conceptos</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <v-card elevation="4" class="mt-5 pt-10 pb-10 pa-5 ma-5">
            <v-row>
                <v-col class="text-center" cols="12" xl="9" md="9" sm="9" xs="9">
                    <v-text-field append-icon="mdi-magnify" v-model="search" label="Filtro" class="mx-4">
                    </v-text-field>
                </v-col>
                <v-col class="text-center" cols="12" xl="2" md="2" sm="2" xs="2">
                    <p class="ma-0" style="font-size: 12px;">Total Importe</p>
                    <v-chip class="white--text" style="width: 350px; height: 40px; font-size: 20px;" color="primary"
                        outlined>
                        <v-icon>mdi-currency-usd</v-icon>
                        <span>{{ functionFormatPrice(this.sumaTotal) }}</span>
                    </v-chip>
                </v-col>
                <v-col class="text-right">
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                        @click="GeneraExcelGeneral()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table :headers="headerConceptos" :search="search" :items="itemsConceptos"
                        :items-per-page="10" class="elevation-0">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.valorUnitario`]="{ item }">
                            <span>{{ functionFormatPrice(item.valorUnitario) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.importe`]="{ item }">
                            <span>{{ functionFormatPrice(item.importe) }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>
<script>
    import axios from 'axios';
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    export default {
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },
        data: () => ({
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',
            step: 1,
            search: '',
            searchClave: '',
            dialogInformacion: false,
            loading: false,
            itemsSelect: [
                { text: 'Emitidos', value: 'Emitidos' },
                { text: 'Recibidos', value: 'Recibidos' }
            ],
            selectTipo: null,
            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,
            //CLAVE SAT 
            claveSat: '',
            descripcionSat: '',
            headerSat: [
                { text: 'Acciones', value: 'actions', sortable: false },
                { text: 'Clave Sat', value: 'clave' },
                { text: 'Descripción', value: 'descripcion' },
                { text: 'Similares', value: 'similares' }
            ],
            itemsSat: [],
            //DESCRIPCIÓN
            headerConceptos: [
                { text: 'Acciones', value: 'actions', sortable: false },
                { text: 'Número de Identificación', value: 'noIdentificacion' },
                { text: 'Clave del Producto o Servicio', value: 'claveProdServ' },
                { text: 'Descripción', value: 'descripcion' },
                { text: 'Clave Unidad', value: 'claveUnidad' },
                { text: 'Unidad', value: 'unidad' },
                { text: 'Cantidad', value: 'cantidad' },
                { text: 'Valor Unitario', value: 'valorUnitario', align: 'right' },
                { text: 'Importe', value: 'importe', align: 'right' },
                // { text: 'Descripción del Producto o Servicio', value: 'descripcionProdServ' },
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
                { text: 'Versión', value: 'version' },
                { text: 'Tipo de Comprobante', value: 'tipoComprobante' },
            ],
            itemsConceptos: [],
            descripcion: '',

            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            //BUSCA CLAVES DEL SAT
            busquedaCatalogoSat: {
                clave: '',
                descripcion: '',
                similares: '',
            },

            sumaTotal: 0
        }),
        computed: {
            // METODOS COMPUTADOS PARA LAS FECHAS
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        methods: {
            async ConsultarDescripcion() {
                if (!this.selectTipo) {
                    this.snackbar = true
                    this.snackbarMessage = 'Seleccione un tipo'
                    return
                }
                this.dialog = true;
                this.dialogMessage = 'Consultando...'

                let busqueda = {
                    tipo: "descripcion",
                    valor: this.descripcion
                }
                if (this.selectTipo === 'Emitidos') {
                    try {
                        let response = await axios.post('Ingresos/GetConceptos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF, busqueda);
                        console.log(response.data)
                        this.itemsConceptos = response.data
                        this.sumaTotal = this.sum(this.itemsConceptos, 'importe')

                    } catch (error) {
                        this.snackbarMessage = 'Error al consultar'
                        this.snackbar = true
                    }
                    this.dialog = false;
                }
                if (this.selectTipo === 'Recibidos') {
                    try {
                        let response = await axios.post('Gastos/GetConceptos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF, busqueda);
                        console.log(response.data)
                        this.itemsConceptos = response.data
                        this.sumaTotal = this.sum(this.itemsConceptos, 'importe')

                    } catch (error) {
                        this.snackbarMessage = 'Error al consultar'
                        this.snackbar = true
                    }
                    this.dialog = false;
                }
            },
            async ConsultaClaveSat(item) {
                if (!this.selectTipo) {
                    this.snackbar = true
                    this.snackbarMessage = 'Seleccione un tipo'
                    return
                }
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                let busqueda = {
                    tipo: "claveSat",
                    valor: item.clave
                }
                if (this.selectTipo === 'Emitidos') {
                    try {
                        let response = await axios.post('Ingresos/GetConceptos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF, busqueda);
                        this.itemsConceptos = response.data
                        this.sumaTotal = this.sum(this.itemsConceptos, 'importe')

                    } catch (error) {
                        this.snackbarMessage = 'Error al consultar'
                        this.snackbar = true
                    }
                    this.dialog = false;
                }
                if (this.selectTipo === 'Recibidos') {
                    try {
                        let response = await axios.post('Gastos/GetConceptos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF, busqueda);
                        this.itemsConceptos = response.data
                        this.sumaTotal = this.sum(this.itemsConceptos, 'importe')
                    } catch (error) {
                        this.snackbarMessage = 'Error al consultar'
                        this.snackbar = true
                    }
                    this.dialog = false;
                }
            },
            async GetCatalogoSatClave() {
                this.dialog = true;
                this.dialogMessage = 'Consultando catálogo'
                console.log(this.busquedaCatalogoSat)
                try {
                    let response = await axios.post('PeticionesSat/PostCatalogoSat/claveProducto/', this.busquedaCatalogoSat);
                    this.itemsSat = response.data
                    this.dialog = false;
                } catch (error) {
                    console.log(error);
                    this.dialog = false;
                }
            },
            async GetCatalogoSatDescripcion() {
                this.dialog = true;
                this.dialogMessage = 'Consultando catálogo'
                console.log(this.busquedaCatalogoSat)
                try {
                    let response = await axios.post('PeticionesSat/PostCatalogoSat/descripcion/', this.busquedaCatalogoSat);
                    this.itemsSat = response.data
                    this.dialog = false;
                } catch (error) {
                    console.log(error);
                    this.dialog = false;
                }
            },

            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            GeneraExcelGeneral() {
                this.nombreReporte = 'Conceptos.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsConceptos
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            }
        }
    }
</script>