var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app pt-5"},[_c('v-snackbar',{attrs:{"timeout":10000,"color":"primary darken-2","elevation":"30"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" mdi-close-circle ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage)+" ")]),_c('div',[_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1600"},model:{value:(_vm.dialogDetalle),callback:function ($$v) {_vm.dialogDetalle=$$v},expression:"dialogDetalle"}},[_c('v-card',[_c('v-toolbar',[_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.nombreProveedor))]),_c('v-spacer'),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.GenerarExcelDetalles()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-table-outline")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)],2)],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('br'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"filtrar"},model:{value:(_vm.searchComprobantes),callback:function ($$v) {_vm.searchComprobantes=$$v},expression:"searchComprobantes"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headerComprobantes,"items":_vm.itemsComprobantes,"search":_vm.searchComprobantes,"item-key":"id","fixed-header":"","height":"60vh"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"primary darken-4"},on:{"click":function($event){return _vm.muestraConceptos(item,'Compras/Gastos')}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-document ")])]}}],null,true)},[_c('span',[_vm._v("Detalles")])])]}},{key:"item.subTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.subTotal)))])]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.descuento)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1600"},model:{value:(_vm.dialogConceptos),callback:function ($$v) {_vm.dialogConceptos=$$v},expression:"dialogConceptos"}},[_c('v-card',[_c('v-toolbar',[_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.nombreProveedor))]),_c('v-spacer'),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.GenerarExcelDetalles()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-table-outline")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)],2)],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('br'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"filtrar"},model:{value:(_vm.searchConceptos),callback:function ($$v) {_vm.searchConceptos=$$v},expression:"searchConceptos"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headerConceptos,"items":_vm.itemsConceptos,"search":_vm.searchConceptos,"item-key":"id"},scopedSlots:_vm._u([{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.cantidad)))])]}},{key:"item.valorUnitario",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.valorUnitario)))])]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.descuento)))])]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.importe)))])]}}],null,true)})],1)],1)],1),_c('v-card',{staticClass:"justify-center mb-5 mr-5 ml-5",attrs:{"elevation":"6"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('h2',{staticClass:"justify-center"},[_vm._v("Compras/Gastos")])]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"mt-5 pt-10 pb-10 pa-5 ma-5",attrs:{"elevation":"6"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","xl":"3","md":"3","sm":"12","xs":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsI,"label":"Fecha","readonly":"","locale":"es"},on:{"click:clear":function($event){_vm.dateBuscaI = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateBuscaI),callback:function ($$v) {_vm.menuDateBuscaI=$$v},expression:"menuDateBuscaI"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaI = false}},model:{value:(_vm.dateBuscaI),callback:function ($$v) {_vm.dateBuscaI=$$v},expression:"dateBuscaI"}})],1)],1),_c('v-col',{attrs:{"cols":"10","xl":"3","md":"3","sm":"10","xs":"10"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsF,"label":"Fecha","readonly":"","locale":"es"},on:{"click:clear":function($event){_vm.dateBuscaF = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateBuscaF),callback:function ($$v) {_vm.menuDateBuscaF=$$v},expression:"menuDateBuscaF"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaF = false}},model:{value:(_vm.dateBuscaF),callback:function ($$v) {_vm.dateBuscaF=$$v},expression:"dateBuscaF"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"3","md":"3","sm":"3","xs":"3"}},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4 mt-2 mb-4",attrs:{"color":"primary darken-1","dark":"","fab":""},on:{"click":function($event){return _vm.buscarCompras()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Consultar compras")])]),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4 mt-2",attrs:{"color":"primary darken-1","dark":"","fab":""},on:{"click":function($event){return _vm.generarExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-table-outline")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)],2)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-5 pb-10 pa-5 ma-5",attrs:{"elevation":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headerGeneral,"items":_vm.itemsGeneral,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"primary darken-4"},on:{"click":function($event){return _vm.detalles(item,'Compras/Gastos')}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-document ")])]}}],null,true)},[_c('span',[_vm._v("Detalles")])])]}},{key:"item.subTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.subTotal)))])]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.descuento)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }