<template>
    <div class="app pt-5">
        <!-- DIALOG PARA VER DETALLES -->
        <v-dialog v-model="dialogDocumentos" max-width="1600">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchDocumentos"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerDocumentos" :items="itemsDocumentos" :search="searchDocumentos">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>

                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- titulo -->
        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Anticipos</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <!-- MENU PARA ATICIPOS -->
        <v-card class="justify-center pb-5" flat color="transparent">
            <v-item-group mandatory>
                <v-container>
                    <v-row dense>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=1"
                                    class="mx-auto justify-center mr-3 rounded-lg" width="170" height="150" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-1 mb-1">Anticipos Emitidos</h3>
                                        <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Anticipos Emitidos
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=2"
                                    class="mx-auto justify-center ml-3 rounded-lg" width="170" height="150" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-1 mb-1">Anticipos Recibidos</h3>
                                        <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Anticipos Recibidos
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                    </v-row>
                </v-container>
            </v-item-group>
        </v-card>
        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
            <v-row class="justify-center">
                <!-- SELECCION DE DATOS -->
                <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                    <v-select prepend-icon="mdi-calendar" v-model="SelectAño" :items="itemsAño" label="Año"></v-select>
                </v-col>
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-select prepend-icon="mdi-calendar" v-model="SelectMes" :items="itemsMes" label="Mes"></v-select>
                </v-col>
            </v-row>
        </v-card>
        <!-- VENTANAS DINAMICAS -->
        <v-window v-model="step" class="pt-0 mt-0">
            <!-- LISTA DE COMPROBANTES -->
            <v-window-item class="pt-0 mt-0" :value="1">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Anticipos Emitidos</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 ma-5 pa-5 mt-10">
                    <v-col class="text-right" cols="12" xl="12" md="12" sm="12" xs="12">
                        <v-row>
                            <v-spacer></v-spacer>
                            <!-- CONSULTAR -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mr-2 mt-2" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                        @click="ConsultarAnticiposEmitidos()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                <span>Consultar Anticipos Emitidos</span>
                            </v-tooltip>
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab v-bind="attrs"
                                                v-on="on" @click="GeneraExcelEmitidos()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </v-row>
                    </v-col>
                    <v-data-table :headers="headersEmitidos" :items="itemsEmitidos">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                        @click="verFacturas(item, 'Facturas Emitidos')">
                                        mdi-file-document-plus
                                    </v-icon>
                                </template>
                                <span>Facturas</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                        @click="verNotasCredito(item, 'Notas de Credito Emitidos')">
                                        mdi-file-document-minus
                                    </v-icon>
                                </template>
                                <span>Notas de credito</span>
                            </v-tooltip>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalAnticipo`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalAnticipo) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalFacturado`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalFacturado) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalNotaCredito`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalNotaCredito) }}</span>
                        </template>
                    </v-data-table>
                    <v-row>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Anticipos</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalAnticipos) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Facturado</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalFacturado) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Notas de Credito</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalNC) }}</span>
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-card>
            </v-window-item>
            <v-window-item class="pt-0 mt-0" :value="2">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Anticipos Recibidos</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="" class="mt-5 pb-10 ma-5 pa-5 mt-10">
                    <v-col class="text-right" cols="12" xl="12" md="12" sm="12" xs="12">
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                        @click="ConsultarAnticiposRecibidos()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                <span>Consultar Anticipos</span>
                            </v-tooltip>
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab v-bind="attrs"
                                                v-on="on" @click="GeneraExcelRecibidos()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </v-row>
                    </v-col>
                    <v-data-table :headers="headersRecibidos" :items="itemsRecibidos">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="green darken-4"
                                        @click="verFacturas(item, 'Facturas Recibidos')">
                                        mdi-file-document-plus
                                    </v-icon>
                                </template>
                                <span>Facturas</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                        @click="verNotasCredito(item, 'Notas de Credito Recibidos')">
                                        mdi-file-document-minus
                                    </v-icon>
                                </template>
                                <span>Notas de credito</span>
                            </v-tooltip>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalAnticipo`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalAnticipo) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalFacturado`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalFacturado) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalNotaCredito`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalNotaCredito) }}</span>
                        </template>
                    </v-data-table>
                    <v-row>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Anticipos</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalAnticiposR) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Facturado</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalFacturadoR) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Notas de Credito</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalNCR) }}</span>
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-card>
            </v-window-item>
        </v-window>
    </div>
</template>
<script>
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import axios from 'axios';

    export default {
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },
        data: () => ({
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',
            step: 1,
            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,

            headersEmitidos: [
                { text: 'Serie', value: 'serie', },
                { text: 'Folio', value: 'folio', },
                { text: 'Fecha', value: 'fecha', },
                { text: 'Total Anticipos', value: 'totalAnticipo', align: 'right' },
                { text: 'Moneda', value: 'moneda' },
                { text: 'Total Facturado', value: 'totalFacturado', align: 'right' },
                { text: 'Total Notas de Crédito', value: 'totalNotaCredito', align: 'right' },
                { text: 'Forma de pago', value: 'formaPago', },
                { text: 'RFC', value: 'rfc', },
                { text: 'Nombre', value: 'nombre', },
                { text: 'Folio Fiscal', value: 'folioFiscal', },
                { text: 'Acción', value: 'actions' },
            ],
            itemsEmitidos: [],
            headersRecibidos: [
                { text: 'Serie', value: 'serie', },
                { text: 'Folio', value: 'folio', },
                { text: 'Fecha', value: 'fecha', },
                { text: 'Total Anticipos', value: 'totalAnticipo', align: 'right' },
                { text: 'Moneda', value: 'moneda' },
                { text: 'Total Facturado', value: 'totalFacturado', align: 'right' },
                { text: 'Total Notas de Crédito', value: 'totalNotaCredito', align: 'right' },
                { text: 'Forma de pago', value: 'formaPago', },
                { text: 'RFC', value: 'rfc', },
                { text: 'Nombre', value: 'nombre', },
                { text: 'Folio Fiscal', value: 'folioFiscal', },
                { text: 'Acción', value: 'actions' },
            ],
            itemsRecibidos: [],
            SelectAño: '',
            SelectMes: '',
            itemsAño: [
                '2022',
                '2021',
                '2020',
                '2019',
                '2018'
            ],
            itemsMes: [
                { text: 'Enero', value: '01' },
                { text: 'Febrero', value: '02' },
                { text: 'Marzo', value: '03' },
                { text: 'Abril', value: '04' },
                { text: 'Mayo', value: '05' },
                { text: 'Junio', value: '06' },
                { text: 'Julio', value: '07' },
                { text: 'Agosto', value: '08' },
                { text: 'Septiembre', value: '09' },
                { text: 'Octubre', value: '10' },
                { text: 'Noviembre', value: '11' },
                { text: 'Diciembre', value: '12' },
            ],
            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            // DIALOGOS
            dialogDocumentos: false,

            titulo: '',

            headerDocumentos: [
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'Moneda', value: 'moneda' },
                { text: 'Forma de Pago', value: 'formaPago' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
            ],
            itemsDocumentos: [],
            searchDocumentos: '',
            totalAnticipos: 0,
            totalFacturado: 0,
            totalNC: 0,
            totalAnticiposR: 0,
            totalFacturadoR: 0,
            totalNCR: 0,
        }
        ),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        methods: {
            async ConsultarAnticiposEmitidos() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'

                let fechaInicial = this.SelectAño + '-' + this.SelectMes + '-01'
                try {
                    let response = await axios.get('Anticipos/GetAnticiposEmitidos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/');
                    console.log(response.data)
                    this.itemsEmitidos = response.data
                    this.totalAnticipos = this.sum(this.itemsEmitidos, 'totalAnticipo')
                    this.totalFacturado = this.sum(this.itemsEmitidos, 'totalFacturado')
                    this.totalNC = this.sum(this.itemsEmitidos, 'totalNotaCredito')

                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async ConsultarAnticiposRecibidos() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'

                let fechaInicial = this.SelectAño + '-' + this.SelectMes + '-01'
                try {
                    let response = await axios.get('Anticipos/GetAnticiposRecibidos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/');
                    console.log(response.data)
                    this.itemsRecibidos = response.data
                    this.totalAnticiposR = this.sum(this.itemsRecibidos, 'totalAnticipo')
                    this.totalFacturadoR = this.sum(this.itemsRecibidos, 'totalFacturado')
                    this.totalNCR = this.sum(this.itemsRecibidos, 'totalNotaCredito')
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            verNotasCredito(item, value) {
                console.log(item)
                this.itemsDocumentos = []
                this.itemsDocumentos = item.notasCredito
                this.$store.state.listaExportarExcel = item.notasCredito
                this.$store.state.datosExcel.titulo = value
                this.$store.state.datosExcel.mes = item.folioFiscal
                this.titulo = value + ' (Folio: ' + item.folio + ')'
                this.dialogDocumentos = true
            },
            verFacturas(item, value) {
                console.log(item)
                this.itemsDocumentos = []
                this.itemsDocumentos = item.facturas
                this.$store.state.listaExportarExcel = item.facturas
                this.$store.state.datosExcel.titulo = value
                this.$store.state.datosExcel.mes = item.folioFiscal
                this.titulo = value + ' (Folio: ' + item.folio + ')'
                this.dialogDocumentos = true
            },
            GeneraExcelRecibidos() {
                this.nombreReporte = 'AnticiposRecibidos_' + this.SelectMes + '_' + this.SelectAño + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsRecibidos
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelEmitidos() {
                this.nombreReporte = 'AnticiposEmitidos_' + this.SelectMes + '_' + this.SelectAño + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsEmitidos
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcel() {
                this.nombreReporte = this.$store.state.datosExcel.titulo + '_' + this.$store.state.datosExcel.mes + '.xlsx'
                let listaExcel = [];
                listaExcel = this.$store.state.listaExportarExcel
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            }
        },
    };
</script>