<template>
  <div>
    <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ dialogMessage }}
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG PARA VER DETALLES -->
    <v-dialog v-model="dialogDetalle" max-width="1700">
      <v-card>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ titulo }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <xlsx-workbook>
            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" />
            <xlsx-download>
            </xlsx-download>
            <xlsx-download :filename="nombreReporte">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="GeneraExcel()">
                    <v-icon>mdi-file-table-outline</v-icon>
                  </v-btn>
                </template>
                <span>Generar Excel</span>
              </v-tooltip>
            </xlsx-download>
          </xlsx-workbook>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <br>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="filtrar" v-model="searchConsulta"></v-text-field>
            </v-col>
          </v-row>
          <v-data-table :headers="headerConsulta" :items="itemsConsulta" :search="searchConsulta" fixed-header height="65vh">
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.descuento`]="{ item }">
              <span>{{ functionFormatPrice(item.descuento) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.total`]="{ item }">
              <span>{{ functionFormatPrice(item.total) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotal`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotal) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotalPesos`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotalPesos) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- REPORTE -->
    <v-card width="1400" elevation="0" class="mx-auto pb-10 ma-5" color="transparent">
      <v-card elevation="3" class="justify-center">
        <v-card-title class="dark justify-center">
          <h3 class="justify-center">GENERAL</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class=" ml-10 mr-5" color="primary darken-1 " dark fab small v-bind="attrs" v-on="on"
                @click="GetReporte()">
                <v-icon>mdi-autorenew</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </v-card-title>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <v-card-text>
          <!-- FACTURADOS -->
          <v-row pt-3 dense class="justify-center pt-4">
            <!-- INGRESOS FACTURADOS -->
            <v-col cols="12" md="3" sm="12" dense>
              <v-text-field prefix="$" label="Total de Ingresos Facturados" v-model="ingresosFacturadosImporte"
                readonly outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Total de Ingresos Facturados',ingresosFacturados.detalles, headersFacturados)">
              </v-text-field>
            </v-col>
            <!-- GASTOS FACTURADOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de Gastos Facturados" v-model="gastosFacturadosImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Total de Gastos Facturados', gastosFacturados.detalles, headersFacturados)">
              </v-text-field>

            </v-col>
            <!-- RESULTADO INGRESOS MESNO GASTOS FACTURADOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Resultado" v-model="totalFacturados" readonly outlined></v-text-field>

            </v-col>
          </v-row>
          <!-- ANTICIPOS -->
          <v-row pt-3 dense class="justify-center">
            <!-- ANTICIPOS EMITIDOS -->
            <v-col cols="12" md="3" sm="12" dense>
              <v-text-field prefix="$" label="Notas de Credito de Anticipo" v-model="ingresosNcAnticiposImporte"
                readonly outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Notas de Credito de Anticipo',ingresosNcAnticipos.detalles, headersFacturados)">
              </v-text-field>
            </v-col>
            <!-- ANTICIPOS RECIBIDOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Notas de Credito de Anticipo" v-model="gastosNcAnticiposImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Notas de Credito de Anticipo',gastosNcAnticipos.detalles, headersFacturados )">
              </v-text-field>
            </v-col>
            <!-- NO HAY RESULTADO -->
            <v-col cols="12" sm="3"></v-col>
          </v-row>
          <!-- COBRADOS -->
          <v-row dense class="justify-center">
            <!-- INGRESOS COBRADOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de Ingresos Cobrados" v-model="ingresosCobradosImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Total de Ingresos Cobrados',ingresosCobrados.detalles, headersCobrados)">
              </v-text-field>
            </v-col>
            <!-- GASTOS PAGADOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de Gastos Pagados" v-model="gastosPagadosImporte" readonly outlined
                append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Total de Gastos Pagados',gastosPagados.detalles, headersCobrados)">
              </v-text-field>
            </v-col>
            <!-- RESULTADO DE INGRESOS MENOS GASTOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Resultado" v-model="totalCobrado" readonly outlined></v-text-field>
            </v-col>
          </v-row>
          <!-- IVA -->
          <v-row dense class="justify-center">
            <!-- IVA TRASLADADO -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de IVA Trasladado Cobrado" v-model="ivaTrasladadoImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Total de IVA Trasladado Cobrado',ivaTrasladado.detalles, headersIva)">
              </v-text-field>
            </v-col>
            <!-- IVA ACREDITADO -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de IVA Acreditado Cobrado" v-model="ivaAcreditadoImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Total de IVA Acreditado Cobrado',ivaAcreditado.detalles, headersIva)">
              </v-text-field>
            </v-col>
            <!-- TOTAL IVA -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Resultado" v-model="totalIva" readonly outlined></v-text-field>
            </v-col>
          </v-row>
          <!-- BASE IVA -->
          <v-row dense class="justify-center">
            <!-- BASE IVA TRASLADADO -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Base IVA" v-model="ivaTrasladadoBase" readonly outlined
                append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Base IVA',ivaTrasladado.detalles, headersIva)"></v-text-field>
            </v-col>
            <!-- BASE IVA ACREDITADO -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Base IVA" v-model="ivaAcreditadoBase" readonly outlined
                append-outer-icon="mdi-file-document"
                @click:append-outer="verDetalles('Base IVA',ivaAcreditado.detalles, headersIva)"></v-text-field>
            </v-col>
            <!-- RESULTADO BASE IVA -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Resultado" v-model="totalBaseIva" readonly outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>
<script>
  import axios from 'axios'
  import VuetifyMoney from "vuetify-money";
  import Vue from 'vue'
  import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"

  Vue.use(VuetifyMoney);

  export default {
    name: 'Home',
    components: {
      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
    },
    data: () => ({
      //OPCIONES PARA FOMRTAOD DECIMAL
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,
        precision: 2,
      },

      ingresosFacturados: { importe: 0, detalles: [] },
      gastosFacturados: { importe: 0, detalles: [] },

      ingresosNcAnticipos: { importe: 0, detalles: [] },
      gastosNcAnticipos: { importe: 0, detalles: [] },

      ingresosCobrados: { importe: 0, detalles: [] },
      gastosPagados: { importe: 0, detalles: [] },

      ivaTrasladado: { importe: 0, base: 0, detalles: [] },
      ivaAcreditado: { importe: 0, base: 0, detalles: [] },

      //DIALOG
      dialogMessage: '',
      dialog: false,

      //LISTA PARA EXPORTAR
      listaExcel: [],
      sheets: [{ name: "Hoja 1", data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
      nombreReporte: '',

      //HEADERS
      itemsConsulta: [],
      searchConsulta: '',
      dialogDetalle: false,
      titulo: '',
      headerConsulta: [],
      headersFacturados: [
        { text: 'Version', value: 'version' },
        { text: 'Serie', value: 'serie' },
        { text: 'Folio', value: 'folio' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'SubTotal', value: 'subTotal', align: 'right' },
        { text: 'Descuento', value: 'descuento', align: 'right' },
        { text: 'Total', value: 'total', align: 'right' },
        { text: 'SupTotalPesos', value: 'subTotalPesos', align: 'right' },
        { text: 'Moneda', value: 'moneda' },
        { text: 'Tipo de Cambio', value: 'tipoCambio' },
        { text: 'Tipo', value: 'tipoComprobante' },
        { text: 'Forma de Pago', value: 'formaPago' },
        { text: 'Metodo Pago', value: 'metodoPago' },
        { text: 'RFC', value: 'rfc' },
        { text: 'Nombre Receptor', value: 'nombre' },
        { text: 'FolioFiscal', value: 'folioFiscal' },
      ],
      headersCobrados: [
        { text: 'version', value: 'version' },
        { text: 'Serie', value: 'serie' },
        { text: 'Serie Pago', value: 'seriePago' },
        { text: 'Folio', value: 'folio' },
        { text: 'Folio Pago', value: 'folioPago' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Fecha de Pago', value: 'fechaPago' },
        { text: 'SubTotal', value: 'subTotal', align: 'right' },
        { text: 'SubTotal Pago', value: 'subTotalPago', align: 'right' },
        { text: 'SubTotal Pesos Cobrados', value: 'subTotalPesosCobrados', align: 'right' },
        { text: 'Descuento', value: 'descuento', align: 'right' },
        { text: 'Total', value: 'total', align: 'right' },
        { text: 'Total Pago', value: 'totalPago', align: 'right' },
        { text: 'Moneda', value: 'moneda' },
        { text: 'Moneda Pago', value: 'monedaPago' },
        { text: 'Tipo Cambio', value: 'tipoCambio', align: 'right' },
        { text: 'Tipo de Cambio Pago', value: 'tipoCambioPago', align: 'right' },
        { text: 'Tipo Comprobante', value: 'tipoComprobante' },
        { text: 'Forma de Pago', value: 'formaPago' },
        { text: 'Metodo de Pago', value: 'metodoPago' },
        { text: 'RFC', value: 'rfc' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Folio Fiscal', value: 'folioFiscal' },
        { text: 'Folio Fiscal Pago', value: 'folioFiscalPago' },
        { text: 'Porcentaje de Pago', value: 'porcentajePago', align: 'right' },
      ],
      headersIva: [
        { text: 'version', value: 'version' },
        { text: 'Serie', value: 'serie' },
        { text: 'Serie Pago', value: 'seriePago' },
        { text: 'Folio', value: 'folio' },
        { text: 'Folio Pago', value: 'folioPago' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Fecha de Pago', value: 'fechaPago' },
        { text: 'Total', value: 'total', align: 'right' },
        { text: 'Total Pago', value: 'totalPago', align: 'right' },
        { text: 'IVA', value: 'iva', align: 'right' },
        { text: 'IVA Pagado', value: 'ivaPagado', align: 'right' },
        { text: 'IVA Pesos Pagados', value: 'ivaPesosPagado', align: 'right' },
        { text: 'Tasa O Cuota', value: 'tasaOCuota', align: 'right' },
        { text: 'Tipo Factor', value: 'tipoFactor' },
        { text: 'Base IVA', value: 'baseIva', align: 'right' },
        { text: 'Moneda', value: 'moneda' },
        { text: 'Moneda Pago', value: 'monedaPago' },
        { text: 'Tipo Cambio', value: 'tipoCambio', align: 'right' },
        { text: 'Tipo de Cambio Pago', value: 'tipoCambioPago', align: 'right' },
        { text: 'Tipo Comprobante', value: 'tipoComprobante' },
        { text: 'Forma de Pago', value: 'formaPago' },
        { text: 'Metodo de Pago', value: 'metodoPago' },
        { text: 'RFC', value: 'rfc' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Folio Fiscal', value: 'folioFiscal' },
        { text: 'Folio Fiscal Pago', value: 'folioFiscalPago' },
        { text: 'Porcentaje de Pago', value: 'porcentajePago', align: 'right' },
      ],
      ingresosFacturadosImporte : 0,
      ingresosNcAnticiposImporte : 0,
      ingresosCobradosImporte : 0,
      ivaTrasladadoImporte : 0,
      ivaTrasladadoBase : 0,

      gastosFacturadosImporte : 0,
      gastosNcAnticiposImporte : 0,
      gastosPagadosImporte : 0,
      ivaAcreditadoImporte : 0,
      ivaAcreditadoBase : 0,

    }),
    computed: {
      fechaHome() {
        return this.$store.state.fechaHome;
      },
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      totalFacturados() {
        let b = this.ingresosFacturados.importe - this.gastosFacturados.importe
        let resta = this.functionFormatPrice(b)
        return resta;
      },
      totalCobrado() {
        let b = this.ingresosCobrados.importe - this.gastosPagados.importe
        let resta = this.functionFormatPrice(b)

        return resta;
      },
      totalIva() {
        let b = this.ivaTrasladado.importe - this.ivaAcreditado.importe
        let resta = this.functionFormatPrice(b)

        return resta;
      },
      totalBaseIva() {
        let b = this.ivaTrasladado.base - this.ivaAcreditado.base
        let resta = this.functionFormatPrice(b)

        return resta;
      },
    },

    methods: {
      //FUNCIÓN PARA DAR FORMATO DECIMAL
      functionFormatPrice(value) {
        if (value != '-') {
          let val = (value / 1).toFixed(2)
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          return '';
        }
      },
      GeneraExcel() {
        this.nombreReporte = 'PagosProvisionalesISR_' + this.$store.state.datosExcel.mes + '_' + this.$store.state.datosExcel.año + '.xlsx'
        let listaExcel = [];
        listaExcel = this.$store.state.listaExportarExcel
        console.log(listaExcel)
        this.listaExcel = listaExcel;
        this.collection = listaExcel;
      },

      async GetReporte() {

        this.dialogMessage = 'Consultando información...'
        this.dialog = true;

        // var promises = [
        await this.GetGastos()
        await this.GetIngresos()
        // ];

        // Promise.all(promises).then(results => {
        //     this.dialog = false;
        // })
      },

      async GetIngresos() {
        try {
          this.dialogMessage = 'Consultando Ingresos...'

          let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
          let response = await axios.get('Ingresos/GetIngresos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
          console.log(response.data)
          let r = response.data
          this.ingresosFacturados.importe = r.ingresosFacturados.importe;
          this.ingresosFacturados.detalles = r.ingresosFacturados.detalles;

          this.ingresosNcAnticipos.importe = r.anticipos.importe;
          this.ingresosNcAnticipos.detalles = r.anticipos.detalles;

          this.ingresosCobrados.importe = r.cobrados.importe;
          this.ingresosCobrados.detalles = r.cobrados.detalles;

          this.ivaTrasladado.importe = r.iva.importeIva;
          this.ivaTrasladado.base = r.iva.importeBaseIva;
          this.ivaTrasladado.detalles = r.iva.detalles;

          this.ingresosFacturadosImporte = this.functionFormatPrice(this.ingresosFacturados.importe);
          this.ingresosNcAnticiposImporte = this.functionFormatPrice(this.ingresosNcAnticipos.importe);
          this.ingresosCobradosImporte = this.functionFormatPrice(this.ingresosCobrados.importe);
          this.ivaTrasladadoImporte = this.functionFormatPrice(this.ivaTrasladado.importe);
          this.ivaTrasladadoBase = this.functionFormatPrice(this.ivaTrasladado.base);

          this.dialog = false;

        } catch (error) {
          console.log(error)
          this.dialog = false;
        }
      },

      async GetGastos() {
        try {
          this.dialogMessage = 'Consultando Gastos...'
          let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
          let response = await axios.get('Gastos/GetGastos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
          console.log(response.data)
          let r = response.data
          this.gastosFacturados.importe = r.gastosFacturados.importe;
          this.gastosFacturados.detalles = r.gastosFacturados.detalles;

          this.gastosNcAnticipos.importe = r.anticipos.importe;
          this.gastosNcAnticipos.detalles = r.anticipos.detalles;

          this.gastosPagados.importe = r.pagados.importe;
          this.gastosPagados.detalles = r.pagados.detalles;

          this.ivaAcreditado.importe = r.iva.importeIva;
          this.ivaAcreditado.base = r.iva.importeBaseIva;
          this.ivaAcreditado.detalles = r.iva.detalles;

          this.gastosFacturadosImporte = this.functionFormatPrice(this.gastosFacturados.importe);
          this.gastosNcAnticiposImporte = this.functionFormatPrice(this.gastosNcAnticipos.importe);
          this.gastosPagadosImporte = this.functionFormatPrice(this.gastosPagados.importe);
          this.ivaAcreditadoImporte = this.functionFormatPrice(this.ivaAcreditado.importe);
          this.ivaAcreditadoBase = this.functionFormatPrice(this.ivaAcreditado.base);

        } catch (error) {
          console.log(error)

        }
      },

      verDetalles(value, item, head) {
        this.dialogDetalle = true
        this.headerConsulta = head
        this.itemsConsulta = item
        this.titulo = value
      },

      // GENERAR EXCEL
      GeneraExcel() {
        this.nombreReporte = this.titulo + '_' + this.fechaHome.mes.descripcion + '_' + this.fechaHome.anio + '.xlsx'
        let listaExcel = this.itemsConsulta;
        this.listaExcel = listaExcel;
        this.collection = listaExcel;
      },
    }
  };
</script>
<style>
.v-data-table .v-data-table-header tr th {
  font-size: 13px !important;
  color: black !important;
  text-align: center !important;
}

</style>