<template>
    <div class="app pt-5">
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <v-card elevation="4" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Solicitudes de Cancelación</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <v-card elevation="4" class="pb-10 ma-5 pa-5">
            <v-row>
                <v-col class="text-right" cols="12" md="12" sm="12">
                    <v-row class="mt-3 mb-3">
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2 mr-1 ml-0" fab dark color="primary darken-1" v-bind="attrs" v-on="on"
                                    @click="buscarSolicitudes()">
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar</span>
                        </v-tooltip>
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                            @click="GeneraExcel()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                    </v-row>
                </v-col>
            </v-row>
            <v-data-table :headers="headersSolicitud" :items="itemsSolicitud" :loading="loadingSolicitud">
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
    import axios from 'axios'
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    export default {

        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },
        data: () => ({
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "Hoja 1", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            headersSolicitud: [
                { text: 'Acciones', sortable: false, value: 'actions' },
                { text: 'foliofiscal', value: 'foliofiscal' },
                { text: 'serie', value: 'serie' },
                { text: 'folio', value: 'folio' },
                { text: 'subTotal', value: 'subTotal', align: 'right' },
                { text: 'total', value: 'total', align: 'right' },
                { text: 'fecha', value: 'fecha' },
                { text: 'rfc', value: 'rfc' },
                { text: 'nombre', value: 'nombre' },
                { text: 'estatus', value: 'estatus' },
            ],
            itemsSolicitud: [],
            loadingSolicitud: true,

            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

        }
        ),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        methods: {
            async buscarSolicitudes() {
                this.dialog = true;
                this.dialogMessage = 'Consultando solicitudes...'

                try {
                    let response = await axios.get('PeticionesSat/GetPeticiones/'+this.datosEmpresa.empresaBase+'/'+ this.datosEmpresa.rfc);
                    this.itemsSolicitud = response.data
                    console.log(response)
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar sus solicitudes'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            // GENERAR EXCEL
            GeneraExcel() {
                this.nombreReporte = 'SolicitudCancelacion.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsSolicitud
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
        },
    };
</script>
<style>
.app {
  background: url('https://img.freepik.com/vector-gratis/fondo-blanco-minimo_1393-354.jpg?w=2000')
    no-repeat center center fixed !important;
  background-size: cover;
  height: 205%;
}

</style>