var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app pt-5"},[_c('v-snackbar',{attrs:{"timeout":10000,"color":"primary darken-2","elevation":"30"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" mdi-close-circle ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage)+" ")]),_c('div',[_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1),_c('v-card',{staticClass:"justify-center mb-5 mr-5 ml-5",attrs:{"elevation":"4"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('h2',{staticClass:"justify-center"},[_vm._v("Solicitudes de Cancelación")])]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"pb-10 ma-5 pa-5",attrs:{"elevation":"4"}},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-row',{staticClass:"mt-3 mb-3"},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 mr-1 ml-0",attrs:{"fab":"","dark":"","color":"primary darken-1"},on:{"click":function($event){return _vm.buscarSolicitudes()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify ")])],1)]}}])},[_c('span',[_vm._v("Consultar")])]),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary darken-1","dark":"","fab":""},on:{"click":function($event){return _vm.GeneraExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-table-outline")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)],2)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headersSolicitud,"items":_vm.itemsSolicitud,"loading":_vm.loadingSolicitud}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }