<template>
  <div class="app">
    <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
    <div>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{ dialogMessage }}
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
    <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
      <template v-slot:actions="{ attrs }">
        <v-icon v-bind="attrs" dark @click="snackbar = false">
          mdi-close-circle
        </v-icon>
      </template>
    </v-snackbar>

    <!-- DIALOG PARA INFORMACION -->
    <v-dialog v-model="dialogInformacion" width="800">
      <v-card>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>INFORMACIÓN</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-system-bar color="info" height="6"></v-system-bar>
        <br>
        <v-card-text>
          <v-alert border="right" colored-border type="info">
            <h3 class="mb-3">¿Cómo generar tu reporte mensual?</h3>
            <p>Para consultar los datos de tu reporte mensual debes seleccionar primero el año, seguidamente del mes y
              dar click en el botón de consultar.</p>
          </v-alert>
          <br>
          <v-alert border="right" colored-border type="info">
            <h3 class="mb-3">¿Cómo agregar datos a tus tablas comparativas?</h3>
            <p>Primero debes seleccionar el año y después debes dar click en el botón de comparativa para que puedas
              agregar tus datos.</p>
          </v-alert>
          <br>
          <v-alert border="right" colored-border type="info">
            <h3 class="mb-3">Coeficiente de Utilidad</h3>
            <p>Se calculará el coeficiente de utilidad correspondiente al último ejercicio de doce meses por el que se
              hubiera o debió haberse presentado la declaración.</p>
          </v-alert>
          <br>
          <v-alert border="right" colored-border type="info">
            <h3 class="mb-3">Perdidas Fiscales de Ejercicios Anteriores</h3>
            <p>La pérdida fiscal se obtendrá de la diferencia entre los ingresos acumulables del ejercicio y las
              deducciones, cuando el monto de estas últimas sea mayor que los ingresos.</p>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG PARA LOS COMPROBANTES -->
    <v-dialog v-model="dialogComprobantes">
      <v-card>
        <v-toolbar>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary darken-1" dark fab small @click="dialogComprobantes = false" v-bind="attrs"
                v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-toolbar-title>COMPROBANTES</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <br>
        <v-card-text>
          <div class="text-center">
            <h1>Comprobantes Emitidos</h1>
          </div>
          <v-data-table :headers="headerEmitidos" :items="itemsEmitidos"></v-data-table>
          <div class="text-center">
            <h1>Comprobantes Recibidos</h1>
          </div>
          <v-data-table :headers="headerRecibidos" :items="itemsRecibidos"></v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG PARA VER DETALLES -->
    <v-dialog v-model="dialogDetalle" max-width="1600">
      <v-card>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <xlsx-workbook>
            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" />
            <xlsx-download>
            </xlsx-download>
            <xlsx-download :filename="nombreReporte">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="GeneraExcel()">
                    <v-icon>mdi-file-table-outline</v-icon>
                  </v-btn>
                </template>
                <span>Generar Excel</span>
              </v-tooltip>
            </xlsx-download>
          </xlsx-workbook>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <br>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="filtrar" v-model="searchConsulta"></v-text-field>
            </v-col>
          </v-row>
          <v-data-table :headers="headerConsulta" :items="itemsConsulta" :search="searchConsulta">
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.descuento`]="{ item }">
              <span>{{ functionFormatPrice(item.descuento) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.total`]="{ item }">
              <span>{{ functionFormatPrice(item.total) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotal`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotal) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotalPesos`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotalPesos) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG PARA VER DETALLES COBRADOS Y PAGADOS -->
    <v-dialog v-model="dialogDetalleCobradoPagados" max-width="1600">
      <v-card>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <xlsx-workbook>
            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" />
            <xlsx-download>
            </xlsx-download>
            <xlsx-download :filename="nombreReporte">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="GeneraExcel()">
                    <v-icon>mdi-file-table-outline</v-icon>
                  </v-btn>
                </template>
                <span>Generar Excel</span>
              </v-tooltip>
            </xlsx-download>
          </xlsx-workbook>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <br>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="filtrar" v-model="searchCobradosPagados"></v-text-field>
            </v-col>
          </v-row>
          <v-data-table :headers="detallesCobradosPagados" :items="itemsCobradosPagados"
            :search="searchCobradosPagados">
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.descuento`]="{ item }">
              <span>{{ functionFormatPrice(item.descuento) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.porcentajePago`]="{ item }">
              <span>{{ functionFormatPrice(item.porcentajePago) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotal`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotal) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotalPago`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotalPago) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotalPesosCobrados`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotalPesosCobrados) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.total`]="{ item }">
              <span>{{ functionFormatPrice(item.total) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.totalPago`]="{ item }">
              <span>{{ functionFormatPrice(item.totalPago) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DETALLES PARA IVA TRASLADO COBRADO, IVA ACREDITADO COBRADO, BASE IVA, BASE IVA -->
    <v-dialog v-model="dialogDetalleIVA" max-width="1600">
      <v-card>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <xlsx-workbook>
            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" />
            <xlsx-download>
            </xlsx-download>
            <xlsx-download :filename="nombreReporte">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="GeneraExcel()">
                    <v-icon>mdi-file-table-outline</v-icon>
                  </v-btn>
                </template>
                <span>Generar Excel</span>
              </v-tooltip>
            </xlsx-download>
          </xlsx-workbook>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <br>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="filtrar" v-model="searchIVA"></v-text-field>
            </v-col>
          </v-row>
          <v-data-table :headers="detallesIva" :items="itemsIVA" :search="searchIVA">
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.descuento`]="{ item }">
              <span>{{ functionFormatPrice(item.descuento) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.baseIva`]="{ item }">
              <span>{{ functionFormatPrice(item.baseIva) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.iva`]="{ item }">
              <span>{{ functionFormatPrice(item.iva) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.ivaPagado`]="{ item }">
              <span>{{ functionFormatPrice(item.ivaPagado) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.ivaPesosPagado`]="{ item }">
              <span>{{ functionFormatPrice(item.ivaPesosPagado) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotal`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotal) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotalPago`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotalPago) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.subTotalPesosCobrados`]="{ item }">
              <span>{{ functionFormatPrice(item.subTotalPesosCobrados) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.tasaOCuota`]="{ item }">
              <span>{{ functionFormatPrice(item.tasaOCuota) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.total`]="{ item }">
              <span>{{ functionFormatPrice(item.total) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.totalPago`]="{ item }">
              <span>{{ functionFormatPrice(item.totalPago) }}</span>
            </template>
            <!-- FORMATO CON DECIMALES -->
            <template v-slot:[`item.porcentajePago`]="{ item }">
              <span>{{ functionFormatPrice(item.porcentajePago) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- LOGO CONTAGO -->
    <template v-if="datosEmpresa.rol == 'Administrador' || datosEmpresa.rol == 'Nomina' || datosEmpresa.rol == 'CuentasPagar' || datosEmpresa.rol == 'CuentasCobrar'">
      <v-card class="justify-center pt-5 pb-5" flat color="transparent">
        <div class="text-center mt-4">
          <img src="../assets/contago-mi-auditor.png" height="200">
        </div>
      </v-card>
    </template>

    <template v-if="datosEmpresa.rol == 'Gasolinero'">
      <v-card class="justify-center pt-5 pb-5" flat color="transparent">
        <div class="text-center mt-4">
          <img src="../assets/gas_mi_auditor_sin_fondo.png" height="200">
        </div>
      </v-card>
    </template>

    <!-- MENU DE COMPONENTES -->
    <v-card class="justify-center pt-5 pb-5" flat color="transparent">
      <v-item-group mandatory>
        <v-container >
          <v-row dense>

            <v-item v-slot="{ active, toggle }">
              <v-hover v-slot="{ hover }" open-delay="10">
                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=1"
                  :color="active ? 'primary' : ''" class=" mx-auto justify-center mr-2 rounded-lg mb-4" width="170"
                  height="150" link>
                  <v-card-text class="text-center" @click="toggle">
                    <v-icon v-if="!active" size="80" color="primary">mdi-file-document-multiple-outline</v-icon>
                    <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline</v-icon>
                    <h3 v-if="!active" class="mt-6 mb-1">INICIO</h3>
                    <h3 v-if="active" class="mt-6 mb-1" style="color: white;">INICIO</h3>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-item>

            <v-item v-slot="{ active, toggle }">
              <v-hover v-slot="{ hover }" open-delay="10">
                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=2"
                  :color="active ? 'primary' : ''" class=" mx-auto justify-center ml-2 mr-2 rounded-lg mb-4" width="170"
                  height="150" link>
                  <v-card-text class="text-center" @click="toggle">
                    <v-icon v-if="!active" color="primary" size="80">mdi-file-document-multiple-outline</v-icon>
                    <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline</v-icon>
                    <h3 v-if="!active" class="mt-1 mb-1">PAGOS PROVISIONALES</h3>
                    <h3 v-if="active" class="mt-1 mb-1" style="color: white;">PAGOS PROVISIONALES</h3>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-item>

            <v-item v-slot="{ active, toggle }">
              <v-hover v-slot="{ hover }" open-delay="10">
                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=3"
                  :color="active ? 'primary' : ''" class="mx-auto justify-center ml-2 mr-2 rounded-lg mb-4" width="170"
                  height="150" link>
                  <v-card-text class="text-center" @click="toggle">
                    <v-icon v-if="!active" size="80" color="primary">mdi-file-document-multiple-outline</v-icon>
                    <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline</v-icon>
                    <h3 v-if="!active" class="mt-6 mb-1">ISR RETENIDO</h3>
                    <h3 v-if="active" class="mt-6 mb-1" style="color: white;">ISR RETENIDO</h3>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-item>

            <v-item v-slot="{ active, toggle }">
              <v-hover v-slot="{ hover }" open-delay="10">
                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=4"
                  :color="active ? 'primary' : ''" class="mx-auto justify-center ml-2 mr-2 rounded-lg mb-4" width="170"
                  height="150" link>
                  <v-card-text class="text-center" @click="toggle">
                    <v-icon v-if="!active" size="80" color="primary">mdi-file-document-multiple-outline</v-icon>
                    <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline</v-icon>
                    <h3 v-if="!active" class="mt-6 mb-1">PAGOS DE IVA</h3>
                    <h3 v-if="active" class="mt-6 mb-1" style="color: white;">PAGOS DE IVA</h3>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-item>

            <v-item v-slot="{ active, toggle }">
              <v-hover v-slot="{ hover }" open-delay="10">
                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=5"
                  :color="active ? 'primary' : ''" class="mx-auto justify-center ml-2 mr-2 rounded-lg mb-4" width="170"
                  height="150" link>
                  <v-card-text class="text-center" @click="toggle">
                    <v-icon v-if="!active" size="80" color="primary">mdi-file-document-multiple-outline</v-icon>
                    <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline</v-icon>
                    <h3 v-if="!active" class="mt-6 mb-1">IVA RETENIDO</h3>
                    <h3 v-if="active" class="mt-6 mb-1" style="color: white;">IVA RETENIDO</h3>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-item>

            <v-item v-slot="{ active, toggle }">
              <v-hover v-slot="{ hover }" open-delay="10">
                <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=6"
                  :color="active ? 'primary' : ''" class="mx-auto justify-center ml-2 rounded-lg mb-4" width="170"
                  height="150" link>
                  <v-card-text class="text-center" @click="toggle">
                    <v-icon v-if="!active" size="80" color="primary">mdi-file-document-multiple-outline</v-icon>
                    <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline</v-icon>
                    <h3 v-if="!active" class="mt-6 mb-1">IEPS</h3>
                    <h3 v-if="active" class="mt-6 mb-1" style="color: white;">IEPS</h3>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-item>

          </v-row>
        </v-container>
      </v-item-group>
    </v-card>

    <!-- SELECCION DE AÑO Y MES -->
    <v-card width="1400" elevation="3" class="mx-auto pt-10 pb-5 ma-5 mt-5">
      <v-row dense class="justify-center">
        <!-- SELECT DEL AÑO -->
        <v-col class="d-flex" cols="12" sm="3">
          <v-select label="Año" :items="itemsAño" v-model="fechaHome.anio" outlined></v-select>
        </v-col>
        <!-- SELECT DEL MES -->
        <v-col class="d-flex" cols="12" sm="3">
          <v-select label="Mes" :items="itemsMes" v-model="fechaHome.mes" outlined return-object
            item-text="descripcion"></v-select>
        </v-col>
        <!-- LISTA DE BOTONES -->
        <v-col class="text-center" cols="12" sm="3">
          <div class="mt-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2  mr-1 ml-0" fab dark small color="primary" v-bind="attrs" v-on="on"
                  @click="dialogInformacion = true">
                  <v-icon dark>
                    mdi-help-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Ayuda</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-window v-model="step" class="pt-0 mt-0">
      <!-- DATOS GENERALES -->
      <!-- <v-window-item class="pt-0 mt-0" :value="1">
        <v-card width="1400" elevation="3" class="mx-auto pt-10 pb-10 ma-5">
          <v-row pt-3 dense class="justify-center pt-4">
            <v-col cols="12" sm="3" dense>
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Total de Ingresos Facturados"
                  :options="options" v-model="ingresosFacturados.importe"></vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetalles('Total de Ingresos Facturados',ingresosFacturados.detalles)">
                    mdi-file-document</v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Total de Gastos Facturados"
                  :options="options" v-model="gastos.importe"></vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetalles('Total de Gastos Facturados', gastos.detalles)">mdi-file-document</v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" v-model="totalFacturados" readonly outlined
                  label="Resultado" :options="options">
                </vuetify-money>
              </v-row>
            </v-col>

          </v-row>
          <v-row pt-3 dense class="justify-center">

            <v-col cols="12" sm="3" dense>
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Notas de Credito de Anticipo"
                  v-model="IngresosNcAnticipos.importe" :options="options"></vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetalles('Notas de Credito de Anticipo',IngresosNcAnticipos.detalles)">
                    mdi-file-document</v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Notas de Credito de Anticipo"
                  v-model="gastosNcAnticipos.importe" :options="options"></vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetalles('Notas de Credito de Anticipo',gastosNcAnticipos.detalles )">
                    mdi-file-document</v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" sm="3">

            </v-col>

          </v-row>
          <v-row dense class="justify-center">

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Total de Ingresos Cobrados"
                  :options="options" v-model="ingresosCobrados.importe"></vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetallesCobradosPagados('Total de Ingresos Cobrados',ingresosCobrados.detalles)">
                    mdi-file-document
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Total de Gastos Pagados"
                  :options="options" v-model="gastosPagados.importe">
                </vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetallesCobradosPagados('Total de Gastos Pagados',gastosPagados.detalles)">
                    mdi-file-document
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" v-model="totalPagados" readonly outlined label="Resultado"
                  :options="options">
                </vuetify-money>
              </v-row>
            </v-col>

          </v-row>
          <v-row dense class="justify-center">

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Total de IVA Trasladado Cobrado"
                  :options="options" v-model="ivaTrasladado.importeIva"></vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetallesIVA('Total de IVA Trasladado Cobrado',ivaTrasladado.detalles)">
                    mdi-file-document</v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Total de IVA Acreditado Cobrado"
                  :options="options" v-model="ivaAcreditado.importeIva">
                </vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetallesIVA('Total de IVA Acreditado Cobrado',ivaAcreditado.detalles)">
                    mdi-file-document</v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" v-model="totalIva" readonly outlined label="Resultado"
                  :options="options">
                </vuetify-money>
              </v-row>
            </v-col>

          </v-row>
          <v-row dense class="justify-center">

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Base IVA" :options="options"
                  v-model="ivaTrasladado.importeBaseIva">
                </vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetallesIVA('Base IVA',ivaTrasladado.detalles)">mdi-file-document</v-icon>
                </v-btn>
              </v-row>

            </v-col>

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" readonly outlined label="Base IVA"
                  v-model="ivaAcreditado.importeBaseIva" :options="options"></vuetify-money>
                <v-btn class="mt-2" text icon color="primary">
                  <v-icon @click="verDetallesIVA('Base IVA',ivaAcreditado.detalles)">mdi-file-document</v-icon>
                </v-btn>
              </v-row>

            </v-col>

            <v-col cols="12" sm="3">
              <v-row>
                <vuetify-money style="width:300px" prefix="$" v-model="totalBase" readonly outlined label="Resultado"
                  :options="options">
                </vuetify-money>
              </v-row>
            </v-col>

          </v-row>
        </v-card>
      </v-window-item> -->

      <!-- GENERAL -->
      <v-window-item class="pt-0 mt-0" :value="1">
        <general></general>
      </v-window-item>

      <!-- Pagos Provisionales de ISR -->
      <v-window-item class="pt-0 mt-0" :value="2">
        <provisionales></provisionales>
      </v-window-item>

      <!-- ISR Retenido -->
      <v-window-item class="pt-0 mt-0" :value="3">
        <ISRRetenido></ISRRetenido>
      </v-window-item>

      <!-- PAGOS DE IVA -->
      <v-window-item class="pt-0 mt-0" :value="4">
        <PagosIVA></PagosIVA>
      </v-window-item>

      <!-- IVA Retenido -->
      <v-window-item class="pt-0 mt-0" :value="5">
        <IVARetenido></IVARetenido>
      </v-window-item>

      <!-- IEPS -->
      <v-window-item class="pt-0 mt-0" :value="6">
        <IEPS></IEPS>
      </v-window-item>
    </v-window>
    <span hidden>{{datosEmpresa}}</span>
  </div>
</template>

<script>
  import HelloWorld from '@/components/HelloWorld.vue'
  import axios from 'axios'
  import VuetifyMoney from "vuetify-money";
  import Vue from 'vue'
  import IEPS from '../components/ComponentesPantallaPrincipal/IEPS/IEPS.vue'
  import ISRRetenido from '../components/ComponentesPantallaPrincipal/ISRRetenido/ISRRetenido.vue'
  import IVARetenido from '../components/ComponentesPantallaPrincipal/IVARetenido/IVARetenido.vue'
  import PagosIVA from '../components/ComponentesPantallaPrincipal/PagosIVA/PagosIVA.vue'
  import Provisionales from '../components/ComponentesPantallaPrincipal/PagosProvisionales/PagosProvisionales.vue'
  import General from '../components/ComponentesPantallaPrincipal/General/General.vue'
  import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"

  Vue.use(VuetifyMoney);

  export default {
    name: 'Home',
    components: {
      IEPS,
      ISRRetenido,
      IVARetenido,
      PagosIVA,
      Provisionales,
      General,
      HelloWorld,

      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,

    },
    data: () => ({
      //OPCIONES
      options: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      //LISTA PARA EXPORTAR
      listaExcel: [],
      sheets: [{ name: "Hoja 1", data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
      nombreReporte: '',
      // SNACKBAR
      snackbar: false,
      snackbarMessage: '',
      //DIALOG
      dialog: false,
      dialogMessage: '',
      e1: 1,
      step: 1,
      itemsAño: [
        '2022',
        '2021',
        '2020',
        '2019',
        '2018',
      ],
      itemsMes: [
        { descripcion: 'Enero', valor: '01' },
        { descripcion: 'Febrero', valor: '02' },
        { descripcion: 'Marzo', valor: '03' },
        { descripcion: 'Abril', valor: '04' },
        { descripcion: 'Mayo', valor: '05' },
        { descripcion: 'Junio', valor: '06' },
        { descripcion: 'Julio', valor: '07' },
        { descripcion: 'Agosto', valor: '08' },
        { descripcion: 'Septiembre', valor: '09' },
        { descripcion: 'Octubre', valor: '10' },
        { descripcion: 'Noviembre', valor: '11' },
        { descripcion: 'Diciembre', valor: '12' },
      ],
      // DIALOGOS
      dialogDetalle: false,
      dialogComprobantes: false,
      dialogInformacion: false,

      inicio: true,
      // DETALLES PARA INGRESOS COBRADOS, GASTOS PAGADOS
      dialogDetalleCobradoPagados: false,
      detallesCobradosPagados: [
        { text: 'version', value: 'version' },
        { text: 'Serie', value: 'serie' },
        { text: 'Serie Pago', value: 'seriePago' },
        { text: 'Folio', value: 'folio' },
        { text: 'Folio Pago', value: 'folioPago' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Fecha de Pago', value: 'fechaPago' },
        { text: 'Total', value: 'total', align: 'right' },
        { text: 'Total Pago', value: 'totalPago', align: 'right' },
        { text: 'Moneda', value: 'moneda' },
        { text: 'Moneda Pago', value: 'monedaPago' },
        { text: 'Tipo Cambio', value: 'tipoCambio', align: 'right' },
        { text: 'Tipo de Cambio Pago', value: 'tipoCambioPago', align: 'right' },
        { text: 'Tipo Comprobante', value: 'tipoComprobante' },
        { text: 'Forma de Pago', value: 'formaPago' },
        { text: 'Metodo de Pago', value: 'metodoPago' },
        { text: 'RFC', value: 'rfc' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Folio Fiscal', value: 'folioFiscal' },
        { text: 'Folio Fiscal Pago', value: 'folioFiscalPago' },
        { text: 'Porcentaje de Pago', value: 'porcentajePago', align: 'right' },
      ],
      searchCobradosPagados: '',
      itemsCobradosPagados: [],
      // DETALLES PARA IVA TRASLADO COBRADO, IVA ACREDITADO COBRADO, BASE IVA, BASE IVA
      dialogDetalleIVA: false,
      itemsIVA: [],
      searchIVA: '',
      detallesIva: [
        { text: 'version', value: 'version' },
        { text: 'Serie', value: 'serie' },
        { text: 'Serie Pago', value: 'seriePago' },
        { text: 'Folio', value: 'folio' },
        { text: 'Folio Pago', value: 'folioPago' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Fecha de Pago', value: 'fechaPago' },
        { text: 'Total', value: 'total', align: 'right' },
        { text: 'Total Pago', value: 'totalPago', align: 'right' },
        { text: 'IVA', value: 'iva', align: 'right' },
        { text: 'IVA Pagado', value: 'ivaPagado', align: 'right' },
        { text: 'IVA Pesos Pagados', value: 'ivaPesosPagado', align: 'right' },
        { text: 'Tasa O Cuota', value: 'tasaOCuota', align: 'right' },
        { text: 'Tipo Factor', value: 'tipoFactor' },
        { text: 'Base IVA', value: 'baseIva', align: 'right' },
        { text: 'Moneda', value: 'moneda' },
        { text: 'Moneda Pago', value: 'monedaPago' },
        { text: 'Tipo Cambio', value: 'tipoCambio', align: 'right' },
        { text: 'Tipo de Cambio Pago', value: 'tipoCambioPago', align: 'right' },
        { text: 'Tipo Comprobante', value: 'tipoComprobante' },
        { text: 'Forma de Pago', value: 'formaPago' },
        { text: 'Metodo de Pago', value: 'metodoPago' },
        { text: 'RFC', value: 'rfc' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Folio Fiscal', value: 'folioFiscal' },
        { text: 'Folio Fiscal Pago', value: 'folioFiscalPago' },
        { text: 'Porcentaje de Pago', value: 'porcentajePago', align: 'right' },
      ],
      headerEmitidos: [
        { text: 'Año', value: 'año' },
        { text: 'Mes', value: 'mes' },
        { text: 'Ingresos Vigentes', value: 'ingresosVigentes', sortable: false },
        { text: 'Ingresos Cancelados', value: 'ingresosCancelados', sortable: false },
        { text: 'Egresos Vigentes', value: 'egresosVigente', sortable: false },
        { text: 'Egresos Cancelados', value: 'egresosCancelados', sortable: false },
        { text: 'Pagos Vigentes', value: 'pagosVigente', sortable: false },
        { text: 'Pagos Cancelados', value: 'pagosCancelados', sortable: false },
        { text: 'Nóminas Vigentes', value: 'nominaVigente', sortable: false },
        { text: 'Nóminas Cancelados', value: 'nominaCancelados', sortable: false },
        { text: 'Acumulados', value: 'acumulado', sortable: false },
      ],
      itemsEmitidos: [],
      headerRecibidos: [
        { text: 'Año', value: 'año' },
        { text: 'Mes', value: 'mes' },
        { text: 'Ingresos Vigentes', value: 'ingresosVigentes', sortable: false },
        { text: 'Ingresos Cancelados', value: 'ingresosCancelados', sortable: false },
        { text: 'Egresos Vigentes', value: 'egresosVigente', sortable: false },
        { text: 'Egresos Cancelados', value: 'egresosCancelados' },
        { text: 'Pagos Vigentes', value: 'pagosVigente', sortable: false },
        { text: 'Pagos Cancelados', value: 'pagosCancelados', sortable: false },
        { text: 'Nóminas Vigentes', value: 'nominaVigente', sortable: false },
        { text: 'Nóminas Cancelados', value: 'nominaCancelados', sortable: false },
        { text: 'Acumulados', value: 'acumulado', sortable: false },
      ],
      itemsRecibidos: [],

      titulo: '',

      headerConsulta: [
        { text: 'Version', value: 'version' },
        { text: 'Serie', value: 'serie' },
        { text: 'Folio', value: 'folio' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'SupTotal', value: 'subTotal' },
        { text: 'Descuento', value: 'descuento' },
        { text: 'Total', value: 'total' },
        { text: 'SupTotalPesos', value: 'subTotalPesos' },
        { text: 'Moneda', value: 'moneda' },
        { text: 'Tipo de Cambio', value: 'tipoCambio' },
        { text: 'Tipo', value: 'tipoComprobante' },
        { text: 'Forma de Pago', value: 'formaPago' },
        { text: 'Metodo Pago', value: 'metodoPago' },
        { text: 'RFC', value: 'rfc' },
        { text: 'Nombre Receptor', value: 'nombre' },
        { text: 'FolioFiscal', value: 'folioFiscal' },
      ],
      searchConsulta: '',

      itemsConsulta: [],

      // TABLA COMPARATIVA
      dialogComparativa: false,

      headerComparativa: [
        { text: 'Mes', value: 'nombreMes' },
        { text: 'Importe', value: 'cargo' }
      ],

      itemsComparativa: [
        { id: 0, nombreMes: 'Enero', año: '', mes: '01', cargo: '' },
        { id: 1, nombreMes: 'Febrero', año: '', mes: '02', cargo: '' },
        { id: 2, nombreMes: 'Marzo', año: '', mes: '03', cargo: '' },
        { id: 3, nombreMes: 'Abril', año: '', mes: '04', cargo: '' },
        { id: 4, nombreMes: 'Mayo', año: '', mes: '05', cargo: '' },
        { id: 5, nombreMes: 'Junio', año: '', mes: '06', cargo: '' },
        { id: 6, nombreMes: 'Julio', año: '', mes: '07', cargo: '' },
        { id: 7, nombreMes: 'Agosto', año: '', mes: '08', cargo: '' },
        { id: 8, nombreMes: 'Septiembre', año: '', mes: '09', cargo: '' },
        { id: 9, nombreMes: 'Octubre', año: '', mes: '10', cargo: '' },
        { id: 10, nombreMes: 'Noviembre', año: '', mes: '11', cargo: '' },
        { id: 11, nombreMes: 'Diciembre', año: '', mes: '12', cargo: '' },
      ],
      editedItemConsulta: {
        año: '',
        mes: '',
      },
      final: {},

      //BLABLA BLA
      gastos: {},
      gastosPagados: {},
      ivaAcreditado: {},
      gastosNcAnticipos: {},

      ingresosFacturados: {},
      ingresosCobrados: {},
      ivaTrasladado: {},
      IngresosNcAnticipos: {},

      //RESULTADOS
      totalFacturados: 0,
      totalPagados: 0,
      totalIva: 0,
      totalBase: 0,

    }),
    computed: {
      nomina() {
        return this.$store.state.storeNomina
      },
      ingresos() {
        return this.$store.state.storeIngresos
      },
      gasto() {
        return this.$store.state.storeGastos
      },
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      fechaHome() {
        return this.$store.state.fechaHome;
      },
    },
    methods: {
      verDetalles(value, item) {
        this.titulo = value
        this.itemsConsulta = item
        this.dialogDetalle = true;
        this.$store.state.listaExportarExcel = item
        console.log(item)
        this.$store.state.datosExcel.titulo = value
        this.$store.state.datosExcel.mes = this.editedItemConsulta.mes
        this.$store.state.datosExcel.año = this.editedItemConsulta.año
      },
      verDetallesCobradosPagados(value, item) {
        this.titulo = value
        this.itemsCobradosPagados = item
        this.dialogDetalleCobradoPagados = true;
        this.$store.state.listaExportarExcel = item
        console.log(item)
        this.$store.state.datosExcel.titulo = value
        this.$store.state.datosExcel.mes = this.editedItemConsulta.mes
        this.$store.state.datosExcel.año = this.editedItemConsulta.año
      },
      verDetallesIVA(value, item) {
        this.titulo = value
        this.itemsIVA = item
        this.dialogDetalleIVA = true;
        this.$store.state.listaExportarExcel = item
        console.log(item)
        this.$store.state.datosExcel.titulo = value
        this.$store.state.datosExcel.mes = this.editedItemConsulta.mes
        this.$store.state.datosExcel.año = this.editedItemConsulta.año
      },
      openComprobantes() {
        this.dialogComprobantes = true
      },

      openDialog(value) {
        this.header = value;
        this.dialogComparativaISR = true
      },
      openDialogIVA(value) {
        this.headerIVA = value;
        this.dialogComparativaIVAR = true
      },
      async actualizar() {
        if (this.editedItemConsulta.año == '' || !this.editedItemConsulta.año) {
          this.snackbarMessage = 'Seleccione un año'
          this.snackbar = true
          return
        }

        if (this.editedItemConsulta.mes == '' || !this.editedItemConsulta.mes) {
          this.snackbarMessage = 'Seleccione un mes'
          this.snackbar = true
          return
        }
        this.dialogMessage = 'Consultando datos...';
        this.dialog = true;
        this.$store.state.consulta.mes = this.editedItemConsulta.mes
        this.$store.state.consulta.año = this.editedItemConsulta.año
        this.$store.state.datosExcel.mes = this.editedItemConsulta.mes
        this.$store.state.datosExcel.año = this.editedItemConsulta.año

        var promises = [
          this.ConsultaIngresos(),
          this.ConsultaGastos(),
          this.ConsultaNomina(),
        ];

        Promise.all(promises).then(results => {
          this.dialog = false;
          this.calcularPagosIva()
          this.RestaResultados()
          this.calculaPagosProvisionalesIsr();
        })
      },

      async ConsultaGastos() {
        this.$store.state.listStoreHonorarios = []
        this.$store.state.listStoreArrendamientos = []
        this.$store.state.listSotreIVARetenidoEmitido = []
        this.$store.state.listStoreIEPSEmitidos = []
        let listaRespuestas = [];

        let fechaInicial = this.editedItemConsulta.año + '-' + this.editedItemConsulta.mes + '-01'
        let contador = Number(this.editedItemConsulta.mes) + 1

        for (let i = 1; i < contador; i++) {
          let fechaInicial = this.editedItemConsulta.año + '-' + i + '-01'
          let response = await axios.get('Gastos/GetGastos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
          listaRespuestas.push(response.data);
        }

        let final = await Promise.all(listaRespuestas);
        //this.$store.state.listaGastos.push(final)
        let ultimo = final.length - 1;
        for (let resultado of final) {
          console.log(resultado)
          this.$store.state.listStoreHonorarios.push(resultado.honorarios)
          this.$store.state.listStoreArrendamientos.push(resultado.arrendamiento)
          this.$store.state.listSotreIVARetenidoEmitido.push(resultado.ivaRetenido)
          this.$store.state.listStoreIEPSEmitidos.push(resultado.iepsTrasladado)
          this.$store.state.listaGastos.push(resultado.ivaAcreditado)
        }
        this.gastos = Object.assign({}, final[ultimo].gastosFacturados);
        this.gastosPagados = Object.assign({}, final[ultimo].gastosPagados);
        this.ivaAcreditado = Object.assign({}, final[ultimo].ivaAcreditado);
        this.gastosNcAnticipos = Object.assign({}, final[ultimo].ncAnticipos);

        //CALCULAR SUMATORIA ISR RETENIDO (HONORARIOS Y ARRENDAMIENTOS)
        let honora = {
          mes: 'TOTAL:',
          comparativa: this.sum(this.$store.state.listStoreHonorarios, 'comparativa'),
          importeRegistrado: this.sum(this.$store.state.listStoreHonorarios, 'importeRegistrado'),
          importe: this.sum(this.$store.state.listStoreHonorarios, 'importe'),
        }

        let arrend = {
          mes: 'TOTAL:',
          comparativa: this.sum(this.$store.state.listStoreArrendamientos, 'comparativa'),
          importeRegistrado: this.sum(this.$store.state.listStoreArrendamientos, 'importeRegistrado'),
          importe: this.sum(this.$store.state.listStoreArrendamientos, 'importe'),
        }

        this.$store.state.listStoreHonorarios.push(honora);
        this.$store.state.listStoreArrendamientos.push(arrend);

        //CALCULAR SUMATORIA IVA RETENIDO EMITIDO
        let ivaREmitido = {
          mes: 'TOTAL:',
          importeBaseIva: this.sum(this.$store.state.listSotreIVARetenidoEmitido, 'importeBaseIva'),
          importeIva: this.sum(this.$store.state.listSotreIVARetenidoEmitido, 'importeIva'),
          importeRegistrado: this.sum(this.$store.state.listSotreIVARetenidoEmitido, 'importeRegistrado'),
          comparativa: this.sum(this.$store.state.listSotreIVARetenidoEmitido, 'comparativa'),
        }
        this.$store.state.listSotreIVARetenidoEmitido.push(ivaREmitido);

        //CALCULAR SUMATORIA IEPS RECIBIDO
        let iepsEmitido = {
          mes: 'TOTAL:',
          importeBaseIva: this.sum(this.$store.state.listStoreIEPSEmitidos, 'importeBaseIva'),
          importeIva: this.sum(this.$store.state.listStoreIEPSEmitidos, 'importeIva'),
          importeRegistrado: this.sum(this.$store.state.listStoreIEPSEmitidos, 'importeRegistrado'),
          comparativa: this.sum(this.$store.state.listStoreIEPSEmitidos, 'comparativa'),
        }
        this.$store.state.listStoreIEPSEmitidos.push(iepsEmitido);
      },
      async ConsultaIngresos() {

        this.$store.state.listStoreIVARetenidoRecibido = []
        this.$store.state.listStoreIEPSRecibidos = []
        this.$store.state.listaIngresosFacturados = []

        let listaRespuestas = [];

        let fechaInicial = this.editedItemConsulta.año + '-' + this.editedItemConsulta.mes + '-01'
        let contador = Number(this.editedItemConsulta.mes) + 1

        for (let i = 1; i < contador; i++) {
          let fechaInicial = this.editedItemConsulta.año + '-' + i + '-01'
          let response = await axios.get('Ingresos/GetIngresos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
          listaRespuestas.push(response.data);
        }

        let final = await Promise.all(listaRespuestas);
        //this.$store.state.listaIngresos.push(final)
        let ultimo = final.length - 1;
        for (let resultado of final) {
          console.log(resultado)

          this.$store.state.listStoreIVARetenidoRecibido.push(resultado.ivaRetenido)
          this.$store.state.listStoreIEPSRecibidos.push(resultado.iepsTrasladado)
          this.$store.state.listaIngresos.push(resultado.ivaTrasladado)
          this.$store.state.listaIngresosFacturados.push(resultado.ingresosFacturados)
        }

        this.ingresosFacturados = Object.assign({}, final[ultimo].ingresosFacturados);
        this.ingresosCobrados = Object.assign({}, final[ultimo].ingresosCobrados);
        this.ivaTrasladado = Object.assign({}, final[ultimo].ivaTrasladado);
        this.IngresosNcAnticipos = Object.assign({}, final[ultimo].ncAnticipos);

        //CALCULAR SUMATORIA IVA RETENIDO RECIBIDO
        let ivaRRecibido = {
          mes: 'TOTAL:',
          importeBaseIva: this.sum(this.$store.state.listStoreIVARetenidoRecibido, 'importeBaseIva'),
          importeIva: this.sum(this.$store.state.listStoreIVARetenidoRecibido, 'importeIva'),
          importeRegistrado: this.sum(this.$store.state.listStoreIVARetenidoRecibido, 'importeRegistrado'),
          comparativa: this.sum(this.$store.state.listStoreIVARetenidoRecibido, 'comparativa'),
        }
        this.$store.state.listStoreIVARetenidoRecibido.push(ivaRRecibido);

        //CALCULAR SUMATORIA IEPS RECIBIDO
        let iepsRecibido = {
          mes: 'TOTAL:',
          importeBaseIva: this.sum(this.$store.state.listStoreIEPSRecibidos, 'importeBaseIva'),
          importeIva: this.sum(this.$store.state.listStoreIEPSRecibidos, 'importeIva'),
          importeRegistrado: this.sum(this.$store.state.listStoreIEPSRecibidos, 'importeRegistrado'),
          comparativa: this.sum(this.$store.state.listStoreIEPSRecibidos, 'comparativa'),
        }
        this.$store.state.listStoreIEPSRecibidos.push(iepsRecibido);
      },

      async ConsultaNomina() {
        this.$store.state.listStoreSueldos = []
        this.$store.state.listStoreSueldosOtros = []
        this.$store.state.listStoreAsimilados = []
        let listaRespuestas = [];

        let fechaInicial = this.editedItemConsulta.año + '-' + this.editedItemConsulta.mes + '-01'
        let contador = Number(this.editedItemConsulta.mes) + 1

        for (let i = 1; i < contador; i++) {
          let fechaInicial = this.editedItemConsulta.año + '-' + i + '-01'
          let response = await axios.get('Nomina/GetRetencionIsr/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
          listaRespuestas.push(response.data);
        }

        let final = await Promise.all(listaRespuestas);

        for (let resultado of final) {
          console.log(resultado)
          this.$store.state.listStoreSueldos.push(resultado.sueldos)
          this.$store.state.listStoreSueldosOtros.push(resultado.otros)
          this.$store.state.listStoreAsimilados.push(resultado.asimilados)
        }

        let sueldos = {
          mes: 'TOTAL:',
          comparativa: this.sum(this.$store.state.listStoreSueldos, 'comparativa'),
          importeRegistrado: this.sum(this.$store.state.listStoreSueldos, 'importeRegistrado'),
          importe: this.sum(this.$store.state.listStoreSueldos, 'importe'),
        }
        let sueldosOtros = {
          mes: 'TOTAL:',
          comparativa: this.sum(this.$store.state.listStoreSueldosOtros, 'comparativa'),
          importeRegistrado: this.sum(this.$store.state.listStoreSueldosOtros, 'importeRegistrado'),
          importe: this.sum(this.$store.state.listStoreSueldosOtros, 'importe'),
        }
        let asimilados = {
          mes: 'TOTAL:',
          comparativa: this.sum(this.$store.state.listStoreAsimilados, 'comparativa'),
          importeRegistrado: this.sum(this.$store.state.listStoreAsimilados, 'importeRegistrado'),
          importe: this.sum(this.$store.state.listStoreAsimilados, 'importe'),
        }
        this.$store.state.listStoreSueldos.push(sueldos);
        this.$store.state.listStoreSueldosOtros.push(sueldosOtros);
        this.$store.state.listStoreAsimilados.push(asimilados);

      },
      calcularPagosIva() {
        this.$store.state.listPagosIva = []
        let contador = Number(this.$store.state.consulta.mes)

        for (let i = 0; i < contador; i++) {
          var resta = this.$store.state.listaIngresos[i].importeBaseIva - this.$store.state.listaGastos[i].importeBaseIva
          var cargo = resta
          var abono = 0
          if (resta < 0) {
            cargo = 0
            abono = resta * -1
          }
          let objetoIva = {
            mes: '',
            baseIvaTrasladado: '',
            importeIvaTrasladado: '',
            detallesTraslado: [],
            baseIvaAcreditado: '',
            importeIvaAcreditado: '',
            detallesAcreditado: [],
            cargo: '',
            abono: '',
          }
          objetoIva.mes = this.$store.state.listaGastos[i].mes
          objetoIva.baseIvaTrasladado = this.$store.state.listaGastos[i].importeBaseIva
          objetoIva.importeIvaTrasladado = this.$store.state.listaGastos[i].importeIva
          objetoIva.detallesTraslado = this.$store.state.listaGastos[i].detalles
          objetoIva.baseIvaAcreditado = this.$store.state.listaIngresos[i].importeBaseIva
          objetoIva.importeIvaAcreditado = this.$store.state.listaIngresos[i].importeIva
          objetoIva.detallesAcreditado = this.$store.state.listaIngresos[i].detalles
          objetoIva.cargo = cargo
          objetoIva.abono = abono

          this.$store.state.listPagosIva.push(objetoIva)
        }
        // CALCULAR SUMATORIAS PAGOS DE IVA
        let gastosIva = {
          mes: 'TOTAL:',
          baseIvaTrasladado: this.sum(this.$store.state.listPagosIva, 'baseIvaTrasladado'),
          importeIvaTrasladado: this.sum(this.$store.state.listPagosIva, 'importeIvaTrasladado'),
          baseIvaAcreditado: this.sum(this.$store.state.listPagosIva, 'baseIvaAcreditado'),
          importeIvaAcreditado: this.sum(this.$store.state.listPagosIva, 'importeIvaAcreditado'),
          cargo: this.sum(this.$store.state.listPagosIva, 'cargo'),
          abono: this.sum(this.$store.state.listPagosIva, 'abono'),
        }
        this.$store.state.listPagosIva.push(gastosIva);

      },
      calculaPagosProvisionalesIsr() {
        this.$store.state.listaPPIsr = []
        let ingresos = this.$store.state.listaIngresosFacturados;
        let listaPPIsr = this.$store.state.listaPPIsr
        // listaPPIsr = [];
        let config = Object.assign({}, this.$store.state.configuracion)
        let acumulado = 0
        let anticipoCuentas = 0
        let ppIsrAnterior = 0;

        for (let resultado of ingresos) {
          acumulado = acumulado + resultado.importe
          // anticipoCuentas = anticipoCuentas + resultado.data.anticipoCuentas;
          let baseImpuestoPpIsr = acumulado * config.cu
          let basePpIsr = (acumulado * config.cu) - config.pfea - anticipoCuentas
          if (basePpIsr < 0) {
            basePpIsr = 0;
          }
          let ppIsr = (basePpIsr * 30) / 100;

          let impuestoCargo = ppIsr - ppIsrAnterior
          if (impuestoCargo < 0) {
            impuestoCargo = 0;
          }
          ppIsrAnterior = ppIsr;

          var concepto = {
            mes: resultado.mes,
            detalles: resultado.detalles,
            ingresoMes: resultado.importe,
            ingresoAcumulado: acumulado,
            baseImpuestoPpIsr: baseImpuestoPpIsr,
            basePpIsr: basePpIsr,
            ppIsr: ppIsr,
            impuestoCargo: impuestoCargo
          };
          listaPPIsr.push(concepto);
          concepto = {};
        }
        // CALCULAR SUMATORIA PAGOS PROVISIONALES
        let pagosProvi = {
          mes: 'TOTAL:',
          ingresoMes: this.sum(this.$store.state.listaPPIsr, 'ingresoMes'),
          ingresoAcumulado: this.sum(this.$store.state.listaPPIsr, 'ingresoAcumulado'),
          baseImpuestoPpIsr: this.sum(this.$store.state.listaPPIsr, 'baseImpuestoPpIsr'),
          basePpIsr: this.sum(this.$store.state.listaPPIsr, 'basePpIsr'),
          ppIsr: this.sum(this.$store.state.listaPPIsr, 'ppIsr'),
          impuestoCargo: this.sum(this.$store.state.listaPPIsr, 'impuestoCargo'),
        }
        this.$store.state.listaPPIsr.push(pagosProvi);

      },
      async RestaResultados() {
        this.totalFacturados = this.ingresosFacturados.importe - this.gastos.importe
        this.totalPagados = this.ingresosCobrados.importe - this.gastosPagados.importe
        this.totalIva = this.ivaTrasladado.importeIva - this.ivaAcreditado.importeIva
        this.totalBase = this.ivaTrasladado.importeBaseIva - this.ivaAcreditado.importeBaseIva
      },
      //FUNCIÓN PARA DAR FORMATO DECIMAL
      functionFormatPrice(value) {
        if (value != '-') {
          let val = (value / 1).toFixed(2)
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          return '';
        }
      },

      // GENERAR EXCEL
      GeneraExcel() {
        this.nombreReporte = this.$store.state.datosExcel.titulo + '_' + this.$store.state.datosExcel.mes + '_' + this.$store.state.datosExcel.año + '.xlsx'
        let listaExcel = [];
        listaExcel = this.$store.state.listaExportarExcel
        this.listaExcel = listaExcel;
        this.collection = listaExcel;

      },

      sum(array, key) {
        return array.reduce((a, b) => a + (b[key] || 0), 0);
      }

    },
  };
</script>
<style>
  .app {
    background: url('https://img.freepik.com/vector-gratis/fondo-blanco-minimo_1393-354.jpg?w=2000') no-repeat center center fixed !important;
    background-size: cover;
  }
</style>