<template>
    <div class="app pt-5">
        <!-- DIALOG PARA VER DETALLES -->
        <v-dialog v-model="dialogDocumentos" max-width="1600">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcel()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook> -->
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <!-- COMPLEMENTOS DE PAGO -->
                    <v-row>
                        <h1>Complementos de Pago</h1>
                        <v-spacer></v-spacer>
                        <div v-if="itemsDocumentos.length != 0">
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                                @click="GeneraExcelComPago()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </div>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchDocumentos"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerDocumentos" :items="itemsDocumentos" :search="searchDocumentos">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.saldoInsoluto`]="{ item }">
                            <span>{{ functionFormatPrice(item.saldoInsoluto) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.saldoAnterior`]="{ item }">
                            <span>{{ functionFormatPrice(item.saldoAnterior) }}</span>
                        </template><!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.importePagado`]="{ item }">
                            <span>{{ functionFormatPrice(item.importePagado) }}</span>
                        </template><!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                    </v-data-table>
                    <!-- NOTAS DE CREDITO -->
                    <v-row>
                        <h1>Notas de Crédito</h1>
                        <v-spacer></v-spacer>
                        <div v-if="itemsNc.length != 0">
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                                @click="GeneraExcelNC()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </div>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchDocumentos"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerNc" :items="itemsNc" :search="searchDocumentos">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalPesos`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalPesos) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- titulo -->
        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Cuentas por Cobrar y por Pagar</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <!-- MENU DE CUENTAS POR PAGAR -->
        <template v-if="datosEmpresa.rol != 'CuentasPagar' && datosEmpresa.rol != 'CuentasCobrar'">

            <v-card class="justify-center pb-5" flat color="transparent">
                <v-item-group mandatory>
                    <v-container>
                        <v-row dense>
                            <v-item v-slot="{ active, toggle }">
                                <v-hover v-slot="{ hover }" open-delay="10">
                                    <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                        :class="{ 'on-hover': hover }" @click="step=1"
                                        class="mx-auto justify-center mr-5 rounded-lg" width="170" height="150" link>
                                        <v-card-text class="text-center" @click="toggle">
                                            <v-icon v-if="!active" size="80" color="primary">
                                                mdi-file-document-multiple-outline</v-icon>
                                            <v-icon v-if="active" size="80" color="white">
                                                mdi-file-document-multiple-outline
                                            </v-icon>
                                            <h3 v-if="!active" class="mt-1 mb-1">Cuentas por Cobrar</h3>
                                            <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Cuentas por Cobrar
                                            </h3>
                                        </v-card-text>
                                    </v-card>
                                </v-hover>
                            </v-item>

                            <v-item v-slot="{ active, toggle }">
                                <v-hover v-slot="{ hover }" open-delay="10">
                                    <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                        :class="{ 'on-hover': hover }" @click="step=2"
                                        class="mx-auto justify-center ml-5 rounded-lg" width="170" height="150" link>
                                        <v-card-text class="text-center" @click="toggle">
                                            <v-icon v-if="!active" size="80" color="primary">
                                                mdi-file-document-multiple-outline</v-icon>
                                            <v-icon v-if="active" size="80" color="white">
                                                mdi-file-document-multiple-outline
                                            </v-icon>
                                            <h3 v-if="!active" class="mt-1 mb-1">Cuentas por Pagar</h3>
                                            <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Cuentas por Pagar
                                            </h3>
                                        </v-card-text>
                                    </v-card>
                                </v-hover>
                            </v-item>
                        </v-row>
                    </v-container>
                </v-item-group>
            </v-card>
        </template>

        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
            <v-row class="justify-center">
                <!-- SELECCION DE DATOS -->
                <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                    <v-select prepend-icon="mdi-calendar" v-model="SelectAño" :items="itemsAño" label="Año"></v-select>
                </v-col>
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-select prepend-icon="mdi-calendar" v-model="SelectMes" :items="itemsMes" label="Mes"></v-select>
                </v-col>
            </v-row>
        </v-card>
        <!-- VENTANAS DINAMICAS -->
        <v-window v-model="step" class="pt-0 mt-0">
            <!-- LISTA DE COMPROBANTES -->
            <v-window-item :value="0"></v-window-item>
            <v-window-item class="pt-0 mt-0" :value="1">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Cuentas por Cobrar</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-row justify="space-around">
                        <!-- BOTÓN PARA CONSULTAR -->
                        <v-col class="text-right" cols="12" xl="12" md="12" sm="12" xs="12">
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="ConsultarCuentasCobrar()">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Consultar Cuentas Por Cobrar</span>
                                </v-tooltip>
                                <xlsx-workbook>
                                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                        :sheet-name="sheet.name" />
                                    <xlsx-download>
                                    </xlsx-download>
                                    <xlsx-download :filename="nombreReporte">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab
                                                    v-bind="attrs" v-on="on" @click="GeneraExcelCobrar()">
                                                    <v-icon>mdi-file-table-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar Excel</span>
                                        </v-tooltip>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!-- PRIMEVUE -->
                    <v-card elevation="6" class="mt-5 pb-10 ma-5 pa-5">

                        <DataTable :value="datosGen" :paginator="true" class="p-datatable-datosGen" showGridlines
                            :rows="10" dataKey="folio" :filters.sync="filters" filterDisplay="menu" :loading="loading"
                            responsiveLayout="scroll"
                            :globalFilterFields="['version', 'serie', 'folio', 'rfc', 'nombre', 'porCobrar', 'cobrado', 'totalFacturado', 'totalNC', 'moneda', 'totalCobradoPesos', 'totalNCPesos', 'folioFiscal']">

                            <template #header>
                                <div class="flex justify-content-between">
                                    <Button type="button" icon="pi pi-filter-slash" label="Clear"
                                        class="p-button-outlined" @click="clearFilter()" />
                                    <!-- <span class="p-input-icon-left">
                                        <i class="pi pi-search" />
                                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                    </span> -->
                                </div>
                            </template>

                            <template #empty>
                                No se encontraron datos.
                            </template>
                            <template #loading>
                                Cargando datos.
                            </template>

                            <Column field="version" header="Version" :sortable="true" :styles="{'min-width':'12rem'}">
                                <template #body="{data}">
                                    {{data.version}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Buscar por version" />
                                </template>
                            </Column>

                            <Column field="serie" header="Serie" :sortable="true" :styles="{'min-width':'12rem'}">
                                <template #body="{data}">
                                    {{data.serie}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Buscar por serie" />
                                </template>
                            </Column>

                            <Column field="folio" header="Folio" :sortable="true" :styles="{'min-width':'12rem'}">
                                <template #body="{data}">
                                    {{data.folio}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Buscar por folio" />
                                </template>
                            </Column>

                            <Column field="rfc" header="RFC" :sortable="true" :styles="{'min-width':'12rem'}">
                                <template #body="{data}">
                                    {{data.rfc}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Buscar por RFC" />
                                </template>
                            </Column>

                            <Column field="nombre" header="Nombre" :sortable="true" :styles="{'min-width':'12rem'}">
                                <template #body="{data}">
                                    {{data.nombre}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Buscar por nombre" />
                                </template>
                            </Column>

                            <Column header="Por Cobrar" filterField="porCobrar" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.porCobrar)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>

                            <Column header="Cobrado" filterField="cobrado" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.cobrado)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>

                            <Column header="Total Facturado" filterField="totalFacturado" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.totalFacturado)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>

                            <Column header="Total NC" filterField="totalNC" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.totalNC)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>

                            <Column field="moneda" header="Moneda" :sortable="true" :styles="{'min-width':'12rem'}">
                                <template #body="{data}">
                                    {{data.moneda}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Buscar por moneda" />
                                </template>
                            </Column>

                            <Column header="Total Facturado (pesos)" filterField="totalFacturado" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.totalFacturado)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>

                            <Column header="Total Pesos cobrados" filterField="totalCobradoPesos" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.totalCobradoPesos)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>

                            <Column header="Total NC (pesos)" filterField="totalNCPesos" :sortable="true" dataType="numeric"
                                :styles="{'min-width':'10rem'}">
                                <template #body="{data}">
                                    <!-- {{data.totPercepciones}} -->
                                    {{formatCurrency(data.totalNCPesos)}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                        locale="es-MX" />
                                </template>
                            </Column>

                            <Column field="folioFiscal" header="Folio Fiscal" :sortable="true" :styles="{'min-width':'12rem'}">
                                <template #body="{data}">
                                    {{data.folioFiscal}}
                                </template>
                                <template #filter="{filterModel}">
                                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                        placeholder="Buscar por Folio Fiscal" />
                                </template>
                            </Column>

                            <Column header="Acción" :headerStyle="{'text-align': 'center'}"
                                :bodyStyle="{'text-align': 'center', overflow: 'visible'}">
                                <template #body="{data}">
                                    <Button @click="verDocumentosCobrar(data, 'Documentos por cobrar')" type="button"
                                        class="p-button-sm" icon="pi pi-bars"></Button>
                                </template>
                            </Column>

                            <ColumnGroup type="footer">
                                <Row>
                                    <Column footer="Total:" :colspan="5" :footerStyle="{'text-align':'right'}" />
                                    <Column :footer="totalPorC" />
                                    <Column :footer="totalC" />
                                    <Column :footer="totalFac" />
                                    <Column :footer="totalNCo" />
                                    <Column />
                                    <Column :footer="totalFactP" />
                                    <Column :footer="totalPesosCo" />
                                    <Column :footer="totalNCoP" />

                                    <!-- <Column :footer="thisYearTotal" /> -->
                                </Row>
                            </ColumnGroup>

                        </DataTable>

                    </v-card>

                    <!--TABLA ANTERIOR

                    LO DE ERIKSONDO
                    <v-data-table :headers="headerCobrar" :items="itemsCobrar" class="elevation-0">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                        @click="verDocumentosCobrar(item, 'Documentos por cobrar')">
                                        mdi-file-document
                                    </v-icon>
                                </template>
                                <span>Documentos</span>
                            </v-tooltip>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.porCobrar`]="{ item }">
                            <span>{{ functionFormatPrice(item.porCobrar) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.cobrado`]="{ item }">
                            <span>{{ functionFormatPrice(item.cobrado) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalFacturado`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalFacturado) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalNC`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalNC) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalFacturadoPesos`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalFacturadoPesos) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalCobradoPesos`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalCobradoPesos) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalNCPesos`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalNCPesos) }}</span>
                        </template>
                    </v-data-table>
                    <v-row>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total por Cobrar</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalCobrar) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Cobrado</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalCobrado) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Facturado</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalFacturado) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Notas de Credito</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalNC) }}</span>
                            </v-chip>
                        </v-col>
                    </v-row> -->
                    
                </v-card>
            </v-window-item>

            <!-- LISTA DE COMPROBANTES VERIFICADOS -->
            <v-window-item class="pt-0 mt-0" :value="2">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Cuentas por Pagar</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-row justify="space-around">
                        <!-- BOTÓN PARA CONSULTAR -->
                        <v-col class="text-right" cols="12" xl="12" md="12" sm="12" xs="12">
                            <v-row>
                                <!-- BUSCADOR -->
                                <!-- <v-col class="text-center" cols="12" xl="6" md="6" sm="6" xs="6">
                                    <v-text-field append-icon="mdi-magnify" v-model="search" label="Filtro por RFC"
                                        class="mx-4">
                                    </v-text-field>
                                </v-col> -->
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="ConsultarCuentasPagar()">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Consultar Cuentas Por Pagar</span>
                                </v-tooltip>
                                <xlsx-workbook>
                                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                        :sheet-name="sheet.name" />
                                    <xlsx-download>
                                    </xlsx-download>
                                    <xlsx-download :filename="nombreReporte">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab
                                                    v-bind="attrs" v-on="on" @click="GeneraExcelPagar()">
                                                    <v-icon>mdi-file-table-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar Excel</span>
                                        </v-tooltip>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!-- PRIMEVUE PAGOS-->
                    <DataTable :value="datosGenPagos" :paginator="true" class="p-datatable-datosGenPagos" showGridlines
                        :rows="10" dataKey="folio" :filters.sync="filtersPagos" filterDisplay="menu" :loading="loading"
                        responsiveLayout="scroll"
                        :globalFilterFields="['version', 'serie', 'folio', 'rfc', 'nombre', 'porPagar', 'pagado', 'totalFacturado', 'totalNC', 'moneda', 'totalPagadoPesos', 'totalNCPesos', 'folioFiscal']">

                        <template #header>
                            <div class="flex justify-content-between">
                                <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined"
                                    @click="clearFilterPago()" />
                                <!-- <span class="p-input-icon-left">
                                        <i class="pi pi-search" />
                                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                    </span> -->
                            </div>
                        </template>

                        <template #empty>
                            No se encontraron datos.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>

                        <Column field="version" header="Version" :sortable="true" :styles="{'min-width':'12rem'}">
                            <template #body="{data}">
                                {{data.version}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por version" />
                            </template>
                        </Column>

                        <Column field="serie" header="Serie" :sortable="true" :styles="{'min-width':'12rem'}">
                            <template #body="{data}">
                                {{data.serie}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por serie" />
                            </template>
                        </Column>

                        <Column field="folio" header="Folio" :sortable="true" :styles="{'min-width':'12rem'}">
                            <template #body="{data}">
                                {{data.folio}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por folio" />
                            </template>
                        </Column>

                        <Column field="rfc" header="RFC" :sortable="true" :styles="{'min-width':'12rem'}">
                            <template #body="{data}">
                                {{data.rfc}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por RFC" />
                            </template>
                        </Column>

                        <Column field="nombre" header="Nombre" :sortable="true" :styles="{'min-width':'12rem'}">
                            <template #body="{data}">
                                {{data.nombre}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por nombre" />
                            </template>
                        </Column>

                        <Column header="Por Pagar" filterField="porPagar" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'10rem'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.porPagar)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column header="Pagado" filterField="pagado" dataType="numeric" :sortable="true" :styles="{'min-width':'10rem'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.pagado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column header="Total Facturado" filterField="totalFacturado" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'10rem'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalFacturado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column header="Total NC" filterField="totalNC" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'10rem'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalNC)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column field="moneda" header="Moneda" :sortable="true" :styles="{'min-width':'12rem'}">
                            <template #body="{data}">
                                {{data.moneda}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por moneda" />
                            </template>
                        </Column>

                        <Column header="Total Facturado (pesos)" filterField="totalFacturadoPesos" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'10rem'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalFacturadoPesos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column header="Total Pesos pagado" filterField="totalPagadoPesos" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'10rem'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalPagadoPesos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column header="Total NC (pesos)" filterField="totalNCPesos" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'10rem'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalNCPesos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column field="folioFiscal" header="Folio Fiscal" :sortable="true" :styles="{'min-width':'12rem'}">
                            <template #body="{data}">
                                {{data.folioFiscal}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por Folio Fiscal" />
                            </template>
                        </Column>

                        <Column header="Acción" :headerStyle="{'text-align': 'center'}"
                            :bodyStyle="{'text-align': 'center', overflow: 'visible'}">
                            <template #body="{data}">
                                <Button @click="verDocumentosPagar(data, 'Documentos por cobrar')" type="button"
                                    class="p-button-sm" icon="pi pi-bars"></Button>
                            </template>
                        </Column>

                        <ColumnGroup type="footer">
                            <Row>
                                <Column footer="Total:" :colspan="5" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalXPagar" />
                                <Column :footer="totalPago" />
                                <Column :footer="totalFacXPagar" />
                                <Column :footer="totalNCo" />
                                <Column />
                                <Column :footer="totalFactPagadaPesos" />
                                <Column :footer="totalPesosPag" />
                                <Column :footer="totalNCoPesosPag" />

                                <!-- <Column :footer="thisYearTotal" /> -->
                            </Row>
                        </ColumnGroup>

                    </DataTable>


                    <!--LO DE ERICKSONDO X2
                    <v-data-table :search="search" :headers="headerPagar" :items="filtrarPagar" class="elevation-0">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                        @click="verDocumentosPagar(item, 'Documentos por pagar')">
                                        mdi-file-document
                                    </v-icon>
                                </template>
                                <span>Documentos</span>
                            </v-tooltip>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.porPagar`]="{ item }">
                            <span>{{ functionFormatPrice(item.porPagar) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.pagado`]="{ item }">
                            <span>{{ functionFormatPrice(item.pagado) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalFacturado`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalFacturado) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalNC`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalNC) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalFacturadoPesos`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalFacturadoPesos) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalPagadoPesos`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalPagadoPesos) }}</span>
                        </template>
                        FORMATO CON DECIMALES
                        <template v-slot:[`item.totalNCPesos`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalNCPesos) }}</span>
                        </template>
                    </v-data-table>

                    <v-row>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total por Pagar</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalPorPagar) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Pagado</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalPagado) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Facturado</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalFacturadoP) }}</span>
                            </v-chip>
                        </v-col>
                        <v-col class="text-center" cols="12" xl="3" md="3" sm="3">
                            <p class="ma-0" style="font-size: 12px;">Total Notas de Credito</p>
                            <v-chip class="white--text" style="width: 250px; height: 40px; font-size: 20px;"
                                color="primary" outlined>
                                <v-icon>mdi-currency-usd</v-icon>
                                <span>{{ functionFormatPrice(this.totalNCP) }}</span>
                            </v-chip>
                        </v-col>
                    </v-row> -->
                </v-card>

            </v-window-item>
        </v-window>
        <span hidden>{{datosEmpresa}}</span>
    </div>
</template>
<script>
    import axios from 'axios';
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import { FilterMatchMode, FilterOperator } from 'primevue/api/';
    export default {
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,

        },
        data: () => ({
            search: '',
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',
            step: 1,
            SelectAño: '',
            SelectMes: '',
            itemsAño: [
                '2022',
                '2021',
                '2020',
                '2019',
                '2018'
            ],
            itemsMes: [
                { text: 'Enero', value: '01' },
                { text: 'Febrero', value: '02' },
                { text: 'Marzo', value: '03' },
                { text: 'Abril', value: '04' },
                { text: 'Mayo', value: '05' },
                { text: 'Junio', value: '06' },
                { text: 'Julio', value: '07' },
                { text: 'Agosto', value: '08' },
                { text: 'Septiembre', value: '09' },
                { text: 'Octubre', value: '10' },
                { text: 'Noviembre', value: '11' },
                { text: 'Diciembre', value: '12' },
            ],

            headerCobrar: [
                { text: 'Versión', value: 'version' },
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Por Cobrar', value: 'porCobrar', align: 'right' },
                { text: 'Cobrado', value: 'cobrado', align: 'right' },
                { text: 'Total Facturado', value: 'totalFacturado', align: 'right' },
                { text: 'Total NC', value: 'totalNC', align: 'right' },
                { text: 'Moneda', value: 'moneda' },
                { text: 'Total Facturado (Pesos)', value: 'totalFacturadoPesos', align: 'right' },
                { text: 'Total Pesos cobrados', value: 'totalCobradoPesos', align: 'right' },
                { text: 'Total NC (Pesos)', value: 'totalNCPesos', align: 'right' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
                { text: 'Acción', value: 'actions' },
            ],
            itemsCobrar: [],
            headerPagar: [
                { text: 'Versión', value: 'version' },
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Por Pagar', value: 'porPagar', align: 'right' },
                { text: 'Pagado', value: 'pagado', align: 'right' },
                { text: 'Total Facturado', value: 'totalFacturado', align: 'right' },
                { text: 'Total NC', value: 'totalNC', align: 'right' },
                { text: 'Moneda', value: 'moneda' },
                { text: 'Total Facturado (Pesos)', value: 'totalFacturadoPesos', align: 'right' },
                { text: 'Total Pesos Pagado', value: 'totalPagadoPesos', align: 'right' },
                { text: 'Total NC (Pesos)', value: 'totalNCPesos', align: 'right' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
                { text: 'Acción', value: 'actions' },
            ],
            itemsPagar: [],
            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',
            // DIALOGOS
            dialogDocumentos: false,

            titulo: '',

            headerDocumentos: [
                { text: 'Version', value: 'version' },
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha Pago', value: 'fechaPago' },
                { text: 'Forma de Pago', value: 'formaDePago' },
                { text: 'Saldo Insoluto', value: 'saldoInsoluto', align: 'right' },
                { text: 'Saldo Anterior', value: 'saldoAnterior', align: 'right' },
                { text: 'Importe Pagado', value: 'importePagado', align: 'right' },
                { text: 'Moneda', value: 'monedaP' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
            ],
            itemsDocumentos: [
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha', value: 'fechaPago' },
                { text: 'Saldo Insoluto', value: 'saldoInsoluto', align: 'right' },
                { text: 'Saldo Anterior', value: 'saldoAnterior', align: 'right' },
                { text: 'Importe Pagado', value: 'importePagado', align: 'right' },
                { text: 'Moneda', value: 'monedaP' },
                { text: 'Tipo de Cambio', value: 'tipoCambioP' },
                { text: 'Forma de Pago', value: 'formaDePago' },
                { text: 'Version', value: 'version' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
            ],
            searchDocumentos: '',
            headerNc: [
                { text: 'Versión', value: 'version' },
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'Moneda', value: 'moneda' },
                { text: 'Tipo de Cambio', value: 'tipoCambio' },
                { text: 'Forma de Pago', value: 'formaDePago' },
                { text: 'Tipo de Ralción', value: 'tipoRelacion' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
            ],
            itemsNc: [],
            totalNCP: 0,
            totalFacturadoP: 0,
            totalPorPagar: 0,
            totalPagado: 0,
            totalNC: 0,
            totalFacturado: 0,
            totalCobrado: 0,
            totalCobrar: 0,

            // PRIMEVUE
            loading: true,
            datosGen: [],
            filters: {
                'version': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'serie': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'folio': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'rfc': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'nombre': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'porCobrar': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'cobrado': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalFacturado': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalNC': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'moneda': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'totalFacturado': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalCobradoPesos': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalNCPesos': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'folioFiscal': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
            },
            datosGenPagos: [],
            filtersPagos: {
                'version': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'serie': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'folio': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'rfc': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'nombre': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'porPagar': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'pagado': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalFacturadoPag': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalNC': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'moneda': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
                'totalFacturadoPesos': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalPagadoPesos': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'totalNCPesos': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.EQUALS },]
                },
                'folioFiscal': {
                    operator: FilterOperator.AND, constraints: [
                        { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                },
            }
        }),
        mounted() {
            this.loading = false;
        },
        computed: {

            datosEmpresa() {
                return this.$store.state.usuario;
            },
            // FUNCION PARA ACTULIZAR LA INFORMACION DE LA TABLA DE ACUERDO AL FILTRO
            filtrarPagar() {
                let conditions = [];
                if (this.search) {
                    conditions.push(this.filtroRFC)
                }

                if (conditions.length > 0) {
                    return this.itemsPagar.filter((dessert) => {
                        return conditions.every((condition) => {
                            return condition(dessert);
                        })
                    })
                }
                return this.itemsPagar;
            },

            // PRIMEVUE
            totalPorC() {
                let totalPC = 0;
                for (let sumaPer of this.datosGen) {
                    totalPC += sumaPer.porCobrar;
                }

                return this.formatCurrency(totalPC);
            },

            totalC() {
                let totalC = 0;
                for (let sumaPer of this.datosGen) {
                    totalC += sumaPer.cobrado;
                }

                return this.formatCurrency(totalC);
            },

            totalFac() {
                let totalFac = 0;
                for (let sumaPer of this.datosGen) {
                    totalFac += sumaPer.totalFacturado;
                }

                return this.formatCurrency(totalFac);
            },

            totalNCo() {
                let totalNCo = 0;
                for (let sumaPer of this.datosGen) {
                    totalNCo += sumaPer.totalNC;
                }

                return this.formatCurrency(totalNCo);
            },

            totalFactP() {
                let totalFactP = 0;
                for (let sumaPer of this.datosGen) {
                    totalFactP += sumaPer.totalFacturado;
                }

                return this.formatCurrency(totalFactP);
            },

            totalPesosCo() {
                let totalPesosCo = 0;
                for (let sumaPer of this.datosGen) {
                    totalPesosCo += sumaPer.totalCobradoPesos;
                }

                return this.formatCurrency(totalPesosCo);
            },

            totalNCoP() {
                let totalNCoP = 0;
                for (let sumaPer of this.datosGen) {
                    totalNCoP += sumaPer.totalNCPesos;
                }

                return this.formatCurrency(totalNCoP);
            },

            //PAGOS
            totalXPagar() {
                
                let totalPP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalPP += sumaPer.porPagar;
                }

                return this.formatCurrency(totalPP);
            },

            totalPago() {
                let totalP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalP += sumaPer.pagado;
                }

                return this.formatCurrency(totalP);
            },

            totalFacXPagar() {
                let totalFacXP = 0;
                for (let sumaFXP of this.datosGenPagos) {
                    totalFacXP += sumaFXP.totalFacturado;
                }

                return this.formatCurrency(totalFacXP);
            },

            totalNCoPagado() {
                let totalNCoP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalNCoP += sumaPer.totalNC;
                }

                return this.formatCurrency(totalNCoP);
            },

            totalFactPagadaPesos() {
                let totalFactPP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalFactPP += sumaPer.totalFacturadoPesos;
                }

                return this.formatCurrency(totalFactPP);
            },

            totalPesosPag() {
                let totalPePag = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalPePag += sumaPer.totalPagadoPesos;
                }

                return this.formatCurrency(totalPePag);
            },

            totalNCoPesosPag() {
                let totalNCoPP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalNCoPP += sumaPer.totalNCPesos;
                }

                return this.formatCurrency(totalNCoPP);
            },

        },
        created() {
            this.validarUsuario()
        },
        methods: {
            validarUsuario() {
                if (this.datosEmpresa.rol == 'CuentasCobrar') {
                    this.step = 1
                    return this.step
                }
                if (this.datosEmpresa.rol == 'CuentasPagar') {
                    this.step = 2
                    return this.step
                }
            },
            // FILTRO
            filtroRFC(item) {
                let valor = String(item.rfc)
                return valor.toLowerCase().includes(this.search.toLowerCase());
            },
            async ConsultarCuentasCobrar() {
                this.dialog = true;
                this.dialogMessage = 'Consultando cuentas por cobrar...'

                let fechaInicial = this.SelectAño + '-' + this.SelectMes + '-01'
                try {
                    let response = await axios.get('CuentasPorCobrarPagar/GetCxC/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/');
                    // console.log(response.data)
                    this.itemsCobrar = response.data
                    this.datosGen = response.data
                    this.totalCobrar = this.sum(this.itemsCobrar, 'porCobrar')
                    this.totalFacturado = this.sum(this.itemsCobrar, 'totalFacturadoPesos')
                    this.totalCobrado = this.sum(this.itemsCobrar, 'totalCobradoPesos')
                    this.totalNC = this.sum(this.itemsCobrar, 'totalNCPesos')
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            async ConsultarCuentasPagar() {
                this.dialog = true;
                this.dialogMessage = 'Consultando cuentas por pagar...'

                let fechaInicial = this.SelectAño + '-' + this.SelectMes + '-01'
                try {
                    let response = await axios.get('CuentasPorCobrarPagar/GetCxP/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/');
                    // console.log(response.data)
                    this.itemsPagar = response.data
                    this.datosGenPagos = response.data
                    this.totalPorPagar = this.sum(this.itemsPagar, 'porPagar')
                    this.totalFacturadoP = this.sum(this.itemsPagar, 'totalFacturadoPesos')
                    this.totalPagado = this.sum(this.itemsPagar, 'totalPagadoPesos')
                    this.totalNCP = this.sum(this.itemsPagar, 'totalNCPesos')
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            verDocumentosCobrar(item, value) {
                console.log(item)
                //COMPLEMENTO DE PAGOS
                this.itemsDocumentos = [];
                this.itemsDocumentos = item.pagos;

                //NOTAS DE CREDITO
                this.itemsNc = [];
                this.itemsNc = item.notaCredito;

                this.$store.state.listaExportarExcel = item.pagos
                this.titulo = value
                this.dialogDocumentos = true
            },
            verDocumentosPagar(item, value) {
                console.log(item)
                //COMPLEMENTO DE PAGOS
                this.itemsDocumentos = []
                this.itemsDocumentos = item.pagos

                //NOTAS DE CREDITO
                this.itemsNc = [];

                this.itemsNc = item.notaCredito;

                this.$store.state.listaExportarExcel = item.documentos
                this.titulo = value
                this.dialogDocumentos = true
            },
            // GENERAR EXCEL
            GeneraExcelNC() {
                this.nombreReporte = 'NotasCredito' + this.SelectMes + '_' + this.SelectAño + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsNc
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelComPago() {
                this.nombreReporte = 'ComplementosPago' + this.SelectMes + '_' + this.SelectAño + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsDocumentos
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelPagar() {
                this.nombreReporte = 'CuentasPagar' + this.SelectMes + '_' + this.SelectAño + '.xlsx'
                let listaExcel = [];
                listaExcel = this.filtrarPagar
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelCobrar() {
                this.nombreReporte = 'CuentasCobrar' + this.SelectMes + '_' + this.SelectAño + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsCobrar
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },
            // PRIMEVUE
            filterDate(value, filter) {
                if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                    return true;
                }

                if (value === undefined || value === null) {
                    return false;
                }

                return value === this.formatDate(filter);
            },
            formatDate(date) {
                let month = date.getMonth() + 1;
                let day = date.getDate();

                if (month < 10) {
                    month = '0' + month;
                }

                if (day < 10) {
                    day = '0' + day;
                }

                return date.getFullYear() + '-' + month + '-' + day;
            },
            formatCurrency(value) {
                return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
            },

            clearFilter() {
                this.filters = {
                    'version': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'serie': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'folio': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'rfc': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'nombre': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'porCobrar': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'cobrado': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'totalFacturado': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'totalNC': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'moneda': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'totalFacturado': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'totalCobradoPesos': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'totalNCPesos': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'folioFiscal': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                }
            },

            clearFilterPago() {

                this.filtersPagos = {
                    'version': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'serie': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'folio': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'rfc': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'nombre': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'porPagar': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'pagado': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'totalFacturadoPag': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'totalNC': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'moneda': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                    'totalFacturadoPagP': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'totalPagadoPesos': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'totalNCPesos': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.EQUALS },]
                    },
                    'folioFiscal': {
                        operator: FilterOperator.AND, constraints: [
                            { value: '', matchMode: FilterMatchMode.STARTS_WITH },]
                    },
                }

            },
        }
    }
</script>