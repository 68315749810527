<template>
    <div class="app ma-0 pt-5">
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- DIALOGO DE INFORMACION -->
        <v-dialog v-model="dialogInformacion" width="650">
            <v-card> 
                <v-toolbar>  
                    <v-spacer></v-spacer>
                    <v-toolbar-title>INFORMACIÓN</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-alert icon="mdi-information-outline" prominent text type="info">
                        - En la columna dónde se encuentre el icono, correspondrá la situación en la que se encuentra el
                        RFC.
                        <br>
                        - Para consultar los RFC únicamente debes de dar click en el botón de
                        Consultar RFC.
                    </v-alert>
                    <v-row class=" mt-5">
                        <v-chip class="mr-2 mb-5" color="primary darken-4" dark>
                            <v-icon>mdi-close-circle-outline</v-icon>
                        </v-chip>
                        <h3>Listado de contribuyentes (Artículo 69-B del Código Fiscal de la Federación) </h3>
                    </v-row>
                    <br>
                    <v-row>
                        <v-chip class="mr-2 mb-5" color="amber darken-4" dark> 
                            <v-icon>mdi-minus-circle-outline</v-icon>
                        </v-chip>
                        <h3>Listado de contribuyentes (No Localizados)</h3>
                    </v-row>
                    <br>
                    <v-row>
                        <v-chip class="mr-2 mb-5" color="blue-grey darken-1" dark>
                            <v-icon>mdi-cancel</v-icon>
                        </v-chip>
                        <h3>Listado de contribuyentes (Incumplidos)</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG PARA VER DETALLES DE CADA RFC -->
        <v-dialog v-model="dialogDetalle" max-width="1600">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcelComprobantes()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchDetalle"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headersComprobanteRFC" :items="itemsComprobantesXrfc" :search="searchDetalle" fixed-header height="60vh">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- CARD PARA VALIDAD -->
        <v-card elevation="4" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Validar RFC</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <v-card elevation="4" class="mt-5 pb-10 pa-5 ma-5">
            <v-row class="justify-center">
                <v-col cols="12" md="6" sm="6">
                    <v-combobox v-model="select" label="Datos a consultar" :items="items" hide-selected small-chips>
                    </v-combobox>
                </v-col>

                <v-col class="text-right mt-1" cols="12" md="6" sm="6">
                    <v-row class="text-right">
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-3" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="consultarListaSat()">
                                    <v-icon>mdi-account-search</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar RFC</span>
                        </v-tooltip>
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-3" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                            @click="GeneraExcel()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-3" color="primary darken-1" dark fab @click="dialogInformacion = true"
                                    v-bind="attrs" v-on="on">
                                    <v-icon>mdi-help-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Ayuda</span>
                        </v-tooltip>
                    </v-row>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" :items="itemsRFC" :search="search">
                <template v-slot:[`item.lista69B`]="{ item }">
                    <template v-if="item.lista69B != ''">
                        <v-chip :color="getColorListaNegra(item.lista69B)" fab dark>
                            <v-icon>mdi-close-circle-outline</v-icon>
                        </v-chip>
                    </template>
                </template>
                <template v-slot:[`item.noLocalizado`]="{ item }">
                    <template v-if="item.noLocalizado != ''">
                        <v-chip :color="getColornoListado(item.noLocalizado)" fab dark @click="consultarComprobantesXrfc(item)">
                            <v-icon>mdi-minus-circle-outline</v-icon>
                        </v-chip>
                    </template>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
    import axios from 'axios'
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    export default {

        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },
        data: () => ({
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            items: [
                'Emisor',
                'Receptor',
                'Todos',
            ], 
            select: '',
            search: '',

            itemsRFC: [],
            dialogInformacion: false,
            headers: [
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Lista del 69B', value: 'lista69B' },
                { text: 'No Localizado', value: 'noLocalizado' },
                { text: 'Tipo', value: 'tipo' },
            ],
            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            headersComprobanteRFC: [
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Folio Fiscal', value: 'foliofiscal' },
                { text: 'SubTotal', value: 'subTotal', align: 'right'  },
                { text: 'Total', value: 'total' , align: 'right' },
                { text: 'Moneda', value: 'moneda' , align: 'right' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Forma Pago', value: 'formaPago', align: 'center'  },
                { text: 'Metodo Pago', value: 'metodoPago' , align: 'center' },
                { text: 'Tipo de Comprobante', value: 'tipoComprobante', align: 'right'  },
                { text: 'Estatus', value: 'estatus' },
            ],
            itemsComprobantesXrfc:[],

            //dialog detalle
            searchDetalle: '',
            dialogDetalle: false,
            titulo: '',
        }
        ),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        methods: {
            async consultarListaSat() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                console.log(this.select)
                try {
                    let response = await axios.get('PeticionesSat/GetListasSat/'+this.datosEmpresa.empresaBase+'/' + this.select);
                    console.log(response.data)
                    this.itemsRFC = response.data
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async consultarComprobantesXrfc(item) {
                this.titulo = item.rfc + ' - ' + item.nombre
                this.itemsComprobantesXrfc = []
                console.log(item)
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                try {
                    let response = await axios.get('PeticionesSat/GetComprobantesXrfc/'+this.datosEmpresa.empresaBase + '/' + item.rfc + '/' + item.tipo);
                    console.log(response.data)
                    this.itemsComprobantesXrfc = response.data
                    this.dialogDetalle = true
                    this.nombreReporte = 'ListaComprobantes_' + item.rfc+ '.xlsx'
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            // GENERAR EXCEL
            GeneraExcel() {
                // console.log(this.empresa)
                this.nombreReporte = 'ListaRFC.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsRFC
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelComprobantes(){
                //this.nombreReporte = 'ListaRFC.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsComprobantesXrfc
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            getColorListaNegra(listaNegra) {
                if (!listaNegra || listaNegra != 'No encontrado') return 'red darken-4'
                else return 'white'
            },
            getColornoListado(noLocalizados) {
                if (!noLocalizados || noLocalizados != 'No encontrado') return 'amber darken-4'
                else return 'white'
            },
            getColorincumplido(incumplidos) {
                if (!incumplidos || incumplidos != '') return 'blue-grey darken-1'
                else return 'white'
            },
        },
    };
</script>