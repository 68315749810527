<template>
    <div class="app pt-5">
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- DIALOG PARA VER DETALLES -->
        <v-dialog v-model="dialogDetalle" max-width="1600">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{ nombreProveedor }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GenerarExcelDetalles()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchComprobantes"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerComprobantes" :items="itemsComprobantes" :search="searchComprobantes"
                        item-key="id" fixed-header height="60vh">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon @click="muestraConceptos(item,'Compras/Gastos')" v-bind="attrs" v-on="on"
                                        small class="mr-2" color="primary darken-4">mdi-file-document
                                    </v-icon>
                                </template>
                                <span>Detalles</span>
                            </v-tooltip>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG PARA VER DETALLES -->
        <v-dialog v-model="dialogConceptos" max-width="1600">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{ nombreProveedor }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GenerarExcelDetalles()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchConceptos"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerConceptos" :items="itemsConceptos" :search="searchConceptos"
                        item-key="id">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.valorUnitario`]="{ item }">
                            <span>{{ functionFormatPrice(item.valorUnitario) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.importe`]="{ item }">
                            <span>{{ functionFormatPrice(item.importe) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- TITULO -->
        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Compras/Gastos</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
            <v-row class="justify-center">
                <!-- SELECCION DE DATOS -->
                <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                    <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaI = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaF = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- BOTÓN PARA CONSULTAR -->
                <v-col cols="12" xl="3" md="3" sm="3" xs="3">
                    <v-row>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="buscarCompras()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar compras</span>
                        </v-tooltip>
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab v-bind="attrs"
                                            @click="generarExcel()" v-on="on">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <!-- TABLA -->
        <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
            </v-text-field>
            <v-data-table :headers="headerGeneral" :items="itemsGeneral" class="elevation-0" :search="search">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon @click="detalles(item,'Compras/Gastos')" v-bind="attrs" v-on="on" small class="mr-2"
                                color="primary darken-4">mdi-file-document
                            </v-icon>
                        </template>
                        <span>Detalles</span>
                    </v-tooltip>
                </template>
                <!-- FORMATO CON DECIMALES -->
                <template v-slot:[`item.subTotal`]="{ item }">
                    <span>{{ functionFormatPrice(item.subTotal) }}</span>
                </template>
                <!-- FORMATO CON DECIMALES -->
                <template v-slot:[`item.descuento`]="{ item }">
                    <span>{{ functionFormatPrice(item.descuento) }}</span>
                </template>
                <!-- FORMATO CON DECIMALES -->
                <template v-slot:[`item.total`]="{ item }">
                    <span>{{ functionFormatPrice(item.total) }}</span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
    import HelloWorld from '@/components/HelloWorld.vue'
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import VuetifyMoney from "vuetify-money";
    import Vue from 'vue'
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    // import XLSX from 'xlsx';

    Vue.use(VuetifyMoney);

    export default {
        name: 'Home',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
            HelloWorld,
        },
        data: () => ({
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            //DIALOG
            dialog: false,
            dialogMessage: '',
            nombreProveedor: '',
            headerGeneral: [
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'SubTotal', value: 'subTotal', align: 'right' },
                { text: 'Descuento', value: 'descuento', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'No. Compras', value: 'numCompras', align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsGeneral: [],

            headerComprobantes: [
                { text: 'Versión', value: 'version' },
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'SubTotal', value: 'subTotal', align: 'right' },
                { text: 'Descuento', value: 'descuento', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'Tipo de Comprobante', value: 'tipoComprobante' },
                { text: 'Método de Pago', value: 'metodoPago' },
                { text: 'Forma de Pago', value: 'formaPago' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsComprobantes: [],
            searchComprobantes: '',
            dialogDetalle: false,

            headerConceptos: [
                { text: 'Clave del Producto o Servicio', value: 'claveProdServicio' },
                { text: 'No de Indetificación', value: 'noIdentificacion' },
                { text: 'Descripción', value: 'descripcion' },
                { text: 'Clave de Unidad', value: 'claveUnidad' },
                { text: 'Unidad', value: 'unidad' },
                { text: 'Cantidad', value: 'cantidad' },
                { text: 'Valor Unitario', value: 'valorUnitario' },
                { text: 'Descuento', value: 'descuento' },
                { text: 'Importe', value: 'importe' },
            ],
            itemsConceptos: [],
            searchConceptos: '',
            dialogConceptos: false,

            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,

            search: '',
        }),
        computed: {
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        created() { },
        methods: {
            async buscarCompras() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...';
                try {
                    let response = await axios.get('Gastos/GetGastosCompras/' + this.datosEmpresa.empresaBase + '/' + this.dateBuscaI + '/' + this.dateBuscaF + '/' + this.datosEmpresa.rfc);
                    this.itemsGeneral = response.data
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            detalles(item, value) {
                this.itemsComprobantes = []
                this.itemsComprobantes = item.comprobantes
                this.nombreProveedor = item.rfc + ' | ' + item.nombre
                this.dialogDetalle = true
            },
            muestraConceptos(item, value) {
                console.log(item.conceptos)
                this.itemsConceptos = item.conceptos;
                this.dialogConceptos = true;
            },

            generarExcel() {
                this.nombreReporte = 'Compras/Gastos.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsGeneral
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },

            GenerarExcelDetalles() {
                this.nombreReporte = 'Compras/Gastos_' + this.$store.state.datosExcel.titulo + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsComprobantes
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },

            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
        },
    }
</script>