import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,
    //FECHA HOME
    fechaHome:{
      anio: '',
      mes: ''
    },

    //NOMBRE COMPONENTE
    nombreComponente:'Home',
    consulta:{
      mes:'',
      año:''
    },

    listaExportarExcel:[],
    datosExcel:{
      titulo:'',
      mes: '',
      año:''
    },

    listaNomina:[],
    storeNomina:{},

    listaIngresos:[],
    listaIngresosFacturados:[],
    listaPPIsr:[],
    storeIngresos:{},

    listaGastos:[],
    storeGastos:{},

    //ISR RETENIDO
    listStoreSueldos:[],
    listStoreSueldosOtros:[],
    listStoreAsimilados:[],
    listStoreHonorarios:[],
    listStoreArrendamientos:[],

    // IEPS 
    listStoreIEPSEmitidos:[],
    listStoreIEPSRecibidos:[],
    listSotreIVARetenidoEmitido:[],
    listStoreIVARetenidoRecibido:[],

    listPagosIva: [],

    listaVentasXML: [],
    totalesReporteXML:[],
    // PANTALLA PRINCIPAL
    // storeIngresosFacturados: {},
    // storeIngresosCobrados: {},
    // storeIvaTrasladado: {},
    // storeGasto:{},
    // storeGastosPagados: {},
    // storeIvaAcreditado: {},
    // storeResultados:{
    //   totalFacturados:'',
    //   totalPagados:'',
    //   totalIva:'',
    //   totalBase:'',
    // },

    configuracion:{
      cu: 0.010,
      pfea: 0,
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUsuario(state, usuario) {
      state.usuario = usuario
    }
  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token", token)
    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'Home' })
      }
    },
    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token")
      router.push({ name: 'Login' })
    }
  },
  modules: {
  }
})
