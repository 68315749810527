<template>
  <v-app>
    <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ dialogMessage }}
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card tile class="mx-auto overflow-hidden" height="100%" width="100%">

      <v-system-bar app color="primary darken-1"></v-system-bar>
      <v-app-bar class="mt-5" color="primary" dark v-if="logueado">
        <v-btn icon @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h4></h4>
          <h5>{{ $store.state.usuario.empresa}} | {{ $store.state.usuario.rfc}}</h5>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="salir">
          <v-icon>mdi-account-arrow-left-outline</v-icon>
        </v-btn>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary v-if="logueado">
        <v-list-item>
          <v-list-item-avatar>
            <img class="ma-0 pa-0" src="../src/assets/logoContaGoDos.jpeg">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title><span>{{ $store.state.usuario.nombre}}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list shaped>
          <template>
            <v-list-item color="primary" :to="{path: '/'}">
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Inicio</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template>
          <v-list-item color="primary darken-3" :to="{path: 'ReportePorFechas'}">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Reportes por fechas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> -->

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'SolicitudesDeCancelacion'}">
              <v-list-item-icon>
                <v-icon>mdi-thumb-up</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Solicitudes de Cancelación</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'ValidarRFC'}">
              <v-list-item-icon>
                <v-icon>mdi-format-list-checks</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Validar RFC y CURP</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Conceptos'}">
              <v-list-item-icon>
                <v-icon>mdi-file-document</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Conceptos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template>
          <v-list-item color="red darken-3" :to="{path: 'Comprobantes'}">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Comprobantes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> -->

          <template v-if="Usuario == 'Administrador' || Usuario == 'Gasolinero' ||  Usuario == 'Nomina'">
            <v-list-item color="primary" :to="{path: 'Nomina'}">
              <v-list-item-icon>
                <v-icon>mdi-account-cash</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Nómina</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero' || Usuario == 'CuentasCobrar' || Usuario == 'CuentasPagar'">
            <v-list-item color="primary" :to="{path: 'CuentasCobrarYPagar'}">
              <v-list-item-icon>
                <v-icon>mdi-cash-fast</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cuentas por cobrar y por pagar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Anticipos'}">
              <v-list-item-icon>
                <v-icon>mdi-cash-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Anticipos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template>
          <v-list-item color="primary darken-3" :to="{path: 'MovimientosBancarios'}">
            <v-list-item-icon>
              <v-icon>mdi-cash-refund</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Movimientos Bancarios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> -->
          <template v-if="Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Gasolinera'}">
              <v-list-item-icon>
                <v-icon>mdi-gas-station</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Gasolinera</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="Usuario == 'Administrador' || Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Compras'}">
              <v-list-item-icon>
                <v-icon>mdi-file-document-minus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Compras/Gastos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="Usuario == 'Administrador' || Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Ventas'}">
              <v-list-item-icon>
                <v-icon>mdi-account-cash-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ventas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template v-if="Usuario == 'Administrador' || Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'IngresosNomina'}">
              <v-list-item-icon>
                <v-icon>mdi-account-cash-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ingresos Nomina</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->
        </v-list>
      </v-navigation-drawer>
      <v-main class="pa-0 ma-0">
        <router-view />
      </v-main>

    </v-card>
    <v-footer dark padless>
      <v-card class="flex text-center" flat tile>
        <v-card-text class="primary darken-1 py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>contago</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'App',


    data: () => ({
      //
      drawer: false,
      nombreUsuario: '',

      //
      dialogMessage: '',
      dialog: false,
    }),
    computed: {
      logueado() {
        return this.$store.state.usuario;
      },
      obtieneEmpresa() {
        return this.$store.state.usuario.empresa;
      },
      obtieneUsuario() {
        return this.$store.state.usuario.nombre;
      },

      esAdministrador() {
        return (
          this.$store.state.usuario &&
          this.$store.state.usuario.rol == "Administrador"
        );
      },
      Usuario() {
        return this.$store.state.usuario.rol
      },
    },
    created() {
      this.$store.dispatch("autoLogin");
      this.cambiarColor();
    },
    methods: {

      cambiarColor() {
        if (this.$store.state.usuario.rol == 'Gasolinero') {
          this.$vuetify.theme.themes.light.primary = '#009341'
          return this.$vuetify.theme.themes.light.primary
        }
        else {
          this.$vuetify.theme.themes.light.primary = '#C62828'
          return this.$vuetify.theme.themes.light.primary
        }
      },

      async salir() {
        this.dialogMessage = 'Cerrando sesión ...'
        this.dialog = true;
        let respuesta;
        try {
          respuesta = await axios.put(
            "https://api-framework.contago.com.mx/api/Usuarios/actualizaSesion/" +
            this.$store.state.usuario.nombre +
            "/INACTIVA"
          );
          this.$store.dispatch("salir");
          this.$vuetify.theme.themes.light.primary = '#C62828'
          this.dialog = false;
        } catch (err) {
          console.log(err);
          this.dialog = false;
        }
      },
    },
  };
</script>