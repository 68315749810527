<template>
    <div>
        <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
        <v-dialog v-model="dialog" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                {{ dialogMessage }}
                <v-progress-linear indeterminate color="white"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG PARA VER DETALLES DE TABLAS ISR-->
        <v-dialog v-model="dialogDetalle" max-width="1600">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{ titulo }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <div v-if="itemsConsulta.length != 0">
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcel()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                    </div>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchConsulta"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerConsulta" :items="itemsConsulta" :search="searchConsulta">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.baseIsr`]="{ item }">
                            <span>{{ functionFormatPrice(item.baseIsr) }}</span>
                        </template>
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <template v-slot:[`item.isr`]="{ item }">
                            <span>{{ functionFormatPrice(item.isr) }}</span>
                        </template>
                        <template v-slot:[`item.isrPagado`]="{ item }">
                            <span>{{ functionFormatPrice(item.isrPagado) }}</span>
                        </template>
                        <template v-slot:[`item.isrPesosPagado`]="{ item }">
                            <span>{{ functionFormatPrice(item.isrPesosPagado) }}</span>
                        </template>
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                        <template v-slot:[`item.subTotalPago`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotalPago) }}</span>
                        </template>
                        <template v-slot:[`item.tasaOCuota`]="{ item }">
                            <span>{{ functionFormatPrice(item.tasaOCuota) }}</span>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <template v-slot:[`item.totalPago`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalPago) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG COMPARATIVA -->
        <v-dialog v-model="dialogComparativa" persistent max-width="500px">
            <v-card>
            <v-toolbar>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary darken-1" dark fab small @click="dialogComparativa = false" v-bind="attrs" v-on="on">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Cerrar</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-toolbar-title>Importes Registrados {{ fechaHome.anio }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="PostComparativa()">
                    <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </template>
                <span>Guardar</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <br>
            <v-card-text>
                    <v-data-table :headers="headersComparativa" :items="itemsComparativa" :items-per-page="-1" hide-default-footer>
                    <!-- PARA EDITAR -->
                    <template v-slot:item.importe="props">
                        <v-edit-dialog
                        :return-value.sync="props.item.importe"
                        >
                        {{ props.item.importe }}
                        <template v-slot:input>
                            <v-text-field
                            v-model="props.item.importe"
                            label="Editar"
                            single-line
                            counter
                            ></v-text-field>
                        </template>
                        </v-edit-dialog>
                    </template>
                    </v-data-table>
            </v-card-text>
            </v-card>
        </v-dialog>

        <v-card width="1400" elevation="0" class="mx-auto pb-10 ma-5"  color="transparent">
            <v-card elevation="3" class="justify-center">
                <v-card-title class="dark justify-center">
                    <h3 class="justify-center">IVA RETENIDO</h3>
                </v-card-title>
                <v-system-bar color="primary" height="6"></v-system-bar>
            </v-card>
            <v-card elevation="3" class="mt-5 pb-10">
                <!-- IVA RETENIDO EMITIDO -->
                <div class="text-center">
                    <h2>IVA RETENIDO EMITIDO</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES-->
                <v-row justify="space-around">
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcelEmitidos()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                outlined @click="openDialog('Emitidos')">
                                <v-icon>mdi-menu</v-icon>
                                COMPARATIVA
                            </v-chip>
                        </template>
                        <span>COMPARATIVA DE IVA RETENIDO EMITIDO</span>
                    </v-tooltip>
                    <!-- BOTÓN PARA CONSULTAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on" @click="GetReporte()">
                                <v-icon>mdi-autorenew</v-icon>
                            </v-btn>
                        </template>
                        <span>Actualizar</span>
                    </v-tooltip>
                </v-row>
                <br>
                <v-data-table :item-class="row_classes" class="elevation -1" :headers="headerIVAEmitidos"
                    :items="itemsEmitidos" :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" dense dark class="mr-2" color="primary"
                                            @click="detallesPorMes(item, 'IVA retenido Emitido')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.importeBaseIva`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeBaseIva) }}</span>
                    </template>
                    <template v-slot:[`item.importeIva`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeIva) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>
            <v-card elevation="3" class="mt-5 pb-10 mt-10">
                <!-- IVA RETENIDO RECIBIDO -->
                <div class="text-center">
                    <h2>IVA RETENIDO RECIBIDO</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES-->
                <v-row justify="space-around">
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcelRecibidos()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                outlined @click="openDialog('Recibidos')">
                                <v-icon>mdi-menu</v-icon>
                                COMPARATIVA
                            </v-chip>
                        </template>
                        <span>COMPARATIVA DE IVA RETENIDO RECIBIDO</span>
                    </v-tooltip>
                    <!-- BOTÓN PARA CONSULTAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on" @click="GetReporte()">
                                <v-icon>mdi-autorenew</v-icon>
                            </v-btn>
                        </template>
                        <span>Actualizar</span>
                    </v-tooltip>
                </v-row>
                <br>
                <v-data-table :item-class="row_classes" class="elevation -1" :headers="headerIVARecibidos"
                    :items="itemsRecibidos" :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" dense v-on="on" dark class="mr-2" color="primary"
                                        @click="detallesPorMes(item, 'IVA Retenido Recibido')">mdi-file-document
                                    </v-icon>
                                </template>
                                <span>Comprobaciones</span>
                            </v-tooltip>
                        </template>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.importeBaseIva`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeBaseIva) }}</span>
                    </template>
                    <template v-slot:[`item.importeIva`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeIva) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>
        </v-card>
    </div>
</template>
<script>
    import HelloWorld from '@/components/HelloWorld.vue'
    import axios from 'axios'
    import VuetifyMoney from "vuetify-money";
    import Vue from 'vue'
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"

    // import XLSX from 'xlsx';

    Vue.use(VuetifyMoney);

    export default {
        name: 'Home',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
            HelloWorld,
        },
        data: () => ({
            //DIALOG
            dialog: false,
            dialogMessage: '',

            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            //DATOS DEL REPORTE
            headerIVAEmitidos: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe IVA', value: 'importeIva', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsEmitidos: [],
            headerIVARecibidos: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe IVA', value: 'importeIva', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsRecibidos: [],

            headerConsulta: [
                { text: 'version', value: 'version' },
                { text: 'serie', value: 'serie' },
                { text: 'seriePago', value: 'seriePago' },
                { text: 'folio', value: 'folio' },
                { text: 'folioPago', value: 'folioPago' },
                { text: 'fecha', value: 'fecha' },
                { text: 'fechaPago', value: 'fechaPago' },
                // { text: 'subTotal', value: 'subTotal', align: 'right' },
                // { text: 'subTotalPago', value: 'subTotalPago', align: 'right' },
                // { text: 'descuento', value: 'descuento', align: 'right' },
                // { text: 'total', value: 'total', align: 'right' },
                // { text: 'totalPago', value: 'totalPago', align: 'right' },
                // { text: 'subTotalPesosCobrados', value: 'subTotalPesosCobrados', align: 'right' },
                { text: 'isr', value: 'isr', align: 'right' },
                { text: 'isrPagado', value: 'isrPagado', align: 'right' },
                { text: 'isrPesosPagado', value: 'isrPesosPagado', align: 'right' },
                { text: 'tasaOCuota', value: 'tasaOCuota', align: 'right' },
                { text: 'tipoFactor', value: 'tipoFactor' },
                { text: 'baseIsr', value: 'baseIsr', align: 'right' },
                { text: 'moneda', value: 'moneda' },
                { text: 'monedaPago', value: 'monedaPago' },
                { text: 'tipoCambio', value: 'tipoCambio' },
                { text: 'tipoCambioPago', value: 'tipoCambioPago' },
                { text: 'tipoComprobante', value: 'tipoComprobante' },
                { text: 'formaPago', value: 'formaPago' },
                { text: 'metodoPago', value: 'metodoPago' },
                { text: 'rfc', value: 'rfc' },
                { text: 'nombre', value: 'nombre' },
                { text: 'folioFiscal', value: 'folioFiscal' },
                { text: 'folioFiscalPago', value: 'folioFiscalPago' },
                { text: 'porcentajePago', value: 'porcentajePago' },
            ],
            itemsConsulta: [],
            searchConsulta: '',
            dialogDetalle: false,
            titulo: '',


            heraderFaltantes: [
                { text: 'Folio Fiscal', value:'folioFiscal'}
                ],
            itemsFaltantes:[] ,
            searchFaltantes: '',
            dialogFaltantes: false,

            //COMPARATIVAS
            dialogComparativa: false,
            headersComparativa: [
                { text: 'Mes', value: 'mes' },
                { text: 'Importe', value: 'importe' },
            ],
            itemsComparativa:[],
            itemsComparativaEmitido: [
                { anio: '', mes: 'enero', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'IVARETE', importe: 0, cargo: 0, abono: 0 },
            ],
            itemsComparativaRecibido: [
                { anio: '', mes: 'enero', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'IVARETR', importe: 0, cargo: 0, abono: 0 },
            ],

        }),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            fechaHome(){
                return this.$store.state.fechaHome;
            },

        },
        methods: {
            //GENERA REPORTE
            async GetReporte(){
                try{
                    await this.GetEmitido();
                    await this.GetRecibidos();
                    this.dialog = false
                }catch(error){
                    console.log(error)
                    this.dialog=false;
                }
            },
            async GetEmitido(){
                this.dialogMessage = "Consultando Emitidos"
                this.dialog = true
                try{
                    let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    let response = await axios.get('Ingresos/GetIvaRetenido/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
                    let array = response.data
                    this.itemsEmitidos = array
                    this.itemsEmitidos.splice(this.editedIndex, 1)
                    await this.GetComparativaEmitidos()
                }catch(error){
                    console.log(error)
                    this.dialog = false;
                }
            },
            async GetRecibidos(){
                this.dialog = true;
                this.dialogMessage = 'Consultando Recibidos...'
                try{
                    let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    let response = await axios.get('Gastos/GetIvaRetenido/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
                    let array = response.data
                    this.itemsRecibidos = array
                    this.itemsRecibidos.splice(this.editedIndex, 1)
                    await this.GetComparativaRecibidos()
                }catch(error){
                    console.log(error)
                    this.dialog = false;
                }
                    
            },

            //GENERA DETALLES POR MES
            detallesPorMes(item, value) {
                console.log(item)
                this.itemsConsulta = []
                this.itemsConsulta = item.detalles
                this.titulo = value + ' (' + item.mes + ')'
                this.dialogDetalle = true
            },
            // GENERAR EXCEL
            GeneraExcel() {
                this.nombreReporte = this.titulo + '_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsConsulta
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelEmitidos(){
                this.nombreReporte = 'IvaRetenidoEmitido_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsEmitidos
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelRecibidos(){
                this.nombreReporte = 'IvaRetenidoRecibidos_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsRecibidos
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            row_classes(item) {
                if (item.mes == "TOTAL:") {
                    return "primary lighten-5";
                }
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },
            //COMPARATIVA
            async openDialog(item){
                if(item === 'Emitidos'){
                    await this.GetComparativaEmitidos();
                    this.itemsComparativa =  this.itemsComparativaEmitido;
                    this.dialogComparativa = true;
                }
                if(item === 'Recibidos'){
                    await this.GetComparativaRecibidos();
                    this.itemsComparativa =  this.itemsComparativaRecibido;
                    this.dialogComparativa = true;
                }
            },
            async GetComparativaEmitidos(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/IVARETE/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaEmitido){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                        registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }

                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsEmitidos){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importeIva - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async GetComparativaRecibidos(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/IVARETR/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaRecibido){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                        registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }

                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsRecibidos){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importeIva - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async PostComparativa(){
                try{
                    let response = await axios.post('Comparativa/PostComparativa/' + this.datosEmpresa.empresaBase , this.itemsComparativa)
                    this.dialogComparativa = false
                }catch(error){
                    console.log(error)
                }
            },
        
        },



    };
</script>