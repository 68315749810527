<template>
    <div>
        <v-card elevation="3" class=" pb-10 mb-5 mr-5 ml-5 pa-5">
            <v-row>
                <v-col cols="12" md="6" sm="6">
                    <v-select label="Banco"></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                    <v-select label="Banco"></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4" sm="4">
                    <v-select label="Periodo"></v-select>
                </v-col>
                <v-col cols="12" md="4" sm="4">
                    <v-select label="Tipo de movimiento"></v-select>
                </v-col>
                <v-col class="text-right" cols="12" md="4" sm="4">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="text-right mr-4" color="primary accent-4" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-text-box-search</v-icon>
                            </v-btn>
                        </template>
                        <span>Consultar estado de cuenta</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="text-right mr-4" color="primary accent-4" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-upload</v-icon>
                            </v-btn>
                        </template>
                        <span>Subir estado de cuenta</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="text-right mr-4" color="primary accent-4" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <span>Guardar conciliacion</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="text-right mr-4" color="primary accent-4" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-view-list-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar a Excel</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card>

        <v-card elevation="3" class="justify-center  ma-5 ">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Movimientos Bancarios</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <v-card elevation="3" class="mt-5 pb-10 ma-5 pa-5 mt-10">
            <v-row>
                <v-col class="text-center" cols="12" xl="12" md="12" sm="12" xs="12">
                    <v-text-field append-icon="mdi-magnify" v-model="search" label="Filtro" class="mx-4"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table :headers="headersMovimientos" :search="search" :items="itemsMovimientosBancos"
                        :items-per-page="10" class="elevation-0" show-select single-select item-key="id">
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

        <v-card elevation="3" class="justify-center  ma-5 ">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Facturas</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <v-card elevation="3" class="mt-5 pb-10 ma-5 pa-5">
            <v-row class="justify-center">
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaI = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- FECHA FINAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaF = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- BOTON DE AYUDA  -->
                <v-col class="text-right" cols="10" xl="6" md="6" sm="10" xs="10">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                @click="ConsultarNomina()">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <span>Consultar Comprobantes</span>
                    </v-tooltip>
                    <!-- BOTON DE CONCILIACION AUTOMATICA  -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-progress-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Conciliacion Automática</span>
                    </v-tooltip>
                    <!-- BOTON DE CONCILIACION MANUAL -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Conciliacion manual</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <vuetify-money style="width:100%" prefix="$" readonly outlined label="Total de Ingresos Facturados"
                    :options="options"></vuetify-money>
            </v-row>

        </v-card>
        <v-card elevation="3" class="mt-5 pb-10 ma-5 pa-5 mt-10">
            <v-row>
                <v-col class="text-right">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="text-right mr-4" color="primary accent-4" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-view-list-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Descargar Excel</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-data-table :headers="headersFacturas" :items="itemsFacturas">
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
    import moment from 'moment';
    import VuetifyMoney from "vuetify-money";
    import { format, parseISO } from 'date-fns';
    import Vue from 'vue'
    Vue.use(VuetifyMoney);

    export default {
        components: {

        },
        data: () => ({
            //OPCIONES
            options: {
                locale: "en-US",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2,
            },
            search: '',
            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,
            itemsEmitidos: [],
            headersEmitidos: [],
            itemsTipo: ['DEPOSITO', 'RETIRO'],
            itemsMovimientosBancos: [],
            itemsFacturas: [],

            selectBancos: '',
            selectNumeroCuenta: '',
            selectPeriodo: {},
            selectTipo: '',

            headersMovimientos: [
                { text: 'Fecha', value: 'fecha' },
                { text: 'Descripcion', value: 'descripcion' },
                { text: 'Importe', value: 'importe', align: 'right' },
                { text: 'Moneda', value: 'moneda' },
            ],
            headersFacturas: [
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Total', value: 'total' },
                { text: 'Forma de pago', value: 'formaPago' },
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
            ],
        }
        ),
        computed: {
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        methods: {
        },
    };
</script>